.custom-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: white;
  max-height: 90dvh;
  padding-bottom: 1rem;
  &.sm {
    width: 30%;
  }
  &.md {
    width: 50%;
  }
  &.lg {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90% !important;
  }
}
.custom-modal-content-scrollable {
  overflow: auto;
  max-height: 80vh;
}
.custom-modal-header {
  padding: 2rem;
  display: flex;
  position: relative;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  &-title {
    font-weight: 600;
    font-size: 2rem;
  }
  &-exit-btn {
    margin-left: auto;
    cursor: pointer;
    font-size: 1.5rem;
  }
}