@import '../../assets/styles/index.scss';

.reference {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-height: calc(100vh - 5rem);
      max-width: 1600px;
      width: 100%;
      padding-bottom: 2rem;
    }
  }
  &-header {
    &-link {
      font-family: 'DM Mono';
      font-size: 1.125rem !important;
      font-weight: 500;
      line-height: 1.3rem;
      color: rgb(107, 107, 107);
      width: calc(100% - 4rem);
      padding: 1rem 2rem;
      &>div {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &-image {
    // flex: 1 1 calc(50% - 1rem);
    &>img {
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: 1rem;
    }
    // @media (max-width: 806.9px) {
    //   flex: 1 1 calc(100% - 1rem);
    // }
    &-container {
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      width: calc(100% - 4rem);
      padding: 0rem 2rem;
      gap: 1.5rem;
      margin-top: 1rem;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
        padding: 0rem 1.25rem;
        gap: 1.25rem;
      }
    }
  }
}