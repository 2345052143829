@import '../../../assets/styles/index.scss';

.custom-navigation-dropdown {
  &-text {
    font-size: 1.0625rem !important;
    font-weight: 500 !important;
    font-family: 'Libre Franklin';
    cursor: pointer;
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
    position: relative;
    // &:hover {
    //   color: $app-purple-strong;
    // }
  }
  &-content {
    margin-right: 2.5rem;
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    &:hover {
      color: $app-purple-strong;
      & .custom-navigation-dropdown-expand-icon {
        &>svg {
          stroke:  $app-purple-strong !important;
        }
      }
    }
  }
  &-expand-icon {
    margin-left: 0.5rem;
    cursor: pointer;
    display: inline flex;
    align-items: center;
  }
  &-menu {
    &-container {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateY(105%) translateX(-50%);
      z-index: 10;
      overflow: hidden;
      background-color: white;
      border: 1px solid #c6c6c6;
      box-shadow: 0px 0.375rem 1.25rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      padding: 2rem 0;
      display: flex;
      width: 60vw;
      &.service {
        width: 400px;
      }
      &.in-home-page {
        color: black !important;
      }
    }
    &-item {
      padding: 0rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // cursor: pointer;
      width: 100%;
      &.with-image {
        position: relative;
        text-decoration: none;
        &:hover .hover-underline::after {
          opacity: 1; // Fade in underline when container is hovered
        }
      }
      &:hover {
        & img {
          transform: scale(1.05)
        }
      }
      &:not(:last-child) {
        border-right: 0.5px solid #999999;
      }
      &-title {
        font-size: 1.75rem;
        font-family: 'Inter Tight';
        font-weight: 800;
        line-height: 110%;
        margin-bottom: 1rem;
        &.purple {
          color: $app-purple-strong;
        }
        &.hover-underline {
          position: relative;
          text-decoration: none; // No underline by default
          color: #000; // Text color
          &.purple {
            color: $app-purple-strong;
            &.hover-underline::after {
              opacity: 1; // Fade in underline when container is hovered
            }
          }
        }
        &.hover-underline::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 2px;
          width: 100%;
          height: 2px;
          background-color: currentColor;
          opacity: 0;
          transition: opacity 0.5s ease;

        }
      }
      &-image {
        &-wrapper {
          display: flex;
          width: 100%;
          border-radius: 1rem;
          background-color: #e7e7e7;
          height: 70%;
          overflow: hidden;
          & img {
            width: 100%;
            height: auto;
            object-fit: cover;
            display: block;
            transition: transform 0.5s ease;
          }
        }
      }
      &-sub-link {
        &-wrapper {
          padding: 1rem 0;
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 1.0625rem !important;
          font-weight: 500 !important;
          font-family: 'Libre Franklin';
          text-decoration: none;
          color: $app-black;
          &:not(:last-child) {
            border-bottom: 0.5px solid #999999;
          }
          &:hover {
            color: $app-purple-strong !important;
            & .custom-navigation-dropdown-menu-item-sub-link-icon {
              opacity: 1;
              stroke:  $app-purple-strong !important;
              fill:  $app-purple-strong !important;
            }
          }
          &.purple {
            color: $app-purple-strong;
          }
        }
        &-icon {
          opacity: 0;
          margin-left: 0.5rem;
          color: $app-purple-strong !important;
        }

      }
    }
  }
}
// .hover-underline {
//   position: relative;
//   text-decoration: none; // No underline by default
//   color: #000; // Text color
// }

// .hover-underline::after {
//   content: '';
//   position: absolute;
//   left: 0;
//   bottom: 2px;
//   width: 100%;
//   height: 2px;
//   background-color: currentColor;
//   opacity: 0;
//   transition: opacity 0.5s ease; 
// }