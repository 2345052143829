@import '../../assets/styles/index.scss';

.reference-group {
  &-custom {
    &-carousel {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      & .react-multi-carousel-track {
        & .reference-group-card-entry-container {
          &:not(:last-child) {
            & .reference-group-card {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 2rem;
    background-color: #E8E2D5;
    width: calc(100% - 4rem);
    @media (max-width: 768px) {
      padding: 2rem 1.25rem;
      width: calc(100% - 2.5rem);
    }
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
    }
  }
  &-title {
    font-size: 3.625rem;
    font-weight: 800;
    font-family: 'Inter Tight';
    line-height: 3.9875rem;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
      font-size: calc(2.5rem + 2vw);
    }
  }
  &-card-entry {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    &-container {
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
  &-card {
    height: 100%;
    background-color: $app-white;
    border-radius: 1rem;
    cursor: pointer;
    &:hover {
      & .custom-card-reference-image {
        & > img {
          transform: scale(1.05)
        }
      }
      & .custom-card-reference-text-container {
        & .custom-card-reference-text-title {
          text-decoration: underline;
        }
      }
    }
  }
}