@import '../../assets/styles/index.scss';

.info-card {
  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(100% - 5rem);
    background-color: $app-white;
    border-radius: 0.5rem;
    padding: 3rem 2.5rem;
    flex: 1 1 calc(25% - 1rem);
    min-width: 300px;
    @media (max-width: 768px) {
      min-width: 250px;
    }
    @media (max-width: 380px) {
      min-width: 200px;
      padding: 2rem 1.5rem;
      width: calc(100% - 3rem);
    }
    &.align-left {
      align-items: flex-start;
    }
    &.yellow {
      background-color: $app-yellow;
    }
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-title {
    font-size: 2.75rem;
    font-family: 'Inter Tight';
    font-weight: 800;
    padding: 1.5rem 1.25rem;
    text-align: center;
    line-height: 3.025rem;
    &.no-padding-left-top {
      padding-left: unset;
      padding-top: unset;
    }
    @media (max-width: 768px) {
      font-size: 1.875rem;
      line-height: 110%;
    }
  }
  &-body {
    width: calc(100% - 2rem);
    font-size: 1.375rem;
    line-height: 1.925rem;
    padding: 1rem 1.25rem;
    border-top: 1px solid rgb(180, 180, 180);
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1.125rem;
      line-height: 140%;
    }
  }
  &-text {
    width: calc(100% - 2rem);
    font-size: 1.375rem;
    line-height: 1.925rem;
    padding: 1rem;
    text-align: left;
    font-family: 'Libre Franklin';
    font-weight: 600;
    &.text-center {
      padding-top: 2rem;
      text-align: center;
    }
    &.no-padding-left-bottom {
      padding-left: unset;
      padding-bottom: unset;
    }
  }
  &-link {
    color: #8E5CD9;
    text-decoration: none;
    font-family: 'Libre Franklin';
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.925rem;
    &-container {
      display: flex;
    }
    &-source {
      font-family: 'Libre Franklin';
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 1.925rem;
    }
  }
}
