@import '../../../../../assets/styles/index.scss';


.inquiries {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-tab {
    padding: 1rem 3rem;
    border-bottom: 2px solid $app-turquoise;
    background: #f0f0f0;
    cursor: pointer;
    font-family: 'Libre Franklin';
    font-weight: 500;
    &:first-child {
      border-top-left-radius: 5px;
      border-left: 1px solid black;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-right: 1px solid black;
      border-top: 1px solid black;
    }
    &:not(:last-child) {
      border-right: 1px solid black;
      border-top: 1px solid black;
    }
    &.active {
      background: $app-black;
      color: white;
      border-bottom: 2px solid $app-black;
    }
    &-container {
      display: flex;
      // margin-bottom: 20px;
      width: 100%;
    }
  }
  &-clipboard-copy{
    &:hover {
      cursor: pointer;
    }
  }
}

#search {
  padding: 0.5rem 0.5rem 0.5rem 0rem !important;
}
