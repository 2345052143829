@import '../../assets/styles/index.scss';

.custom-solution {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
      padding-bottom: 3rem;
    }
  }
  &-text-card {
    text-align: center;
    font-family: 'Inter Tight';
    font-weight: 800;
    line-height: 3.025rem;
    font-size: 2.75rem;
    padding: 2rem 2rem;
    @media (max-width: 768px) {
      font-size: 1.875rem;
      line-height: 110%;
      padding: 2rem 0.5rem;
    }
  }
}
