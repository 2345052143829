@import '../../assets/styles/index.scss';


.info-box {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 2rem;
    width: calc(100% - 4rem);
    @media (max-width: 768px) {
      padding: 2rem 1.25rem;
      width: calc(100% - 2.5rem);
    }
    &.pb-1 {
      padding-bottom: 1rem;
    }
    &.pb-2 {
      padding-bottom: 2rem;
    }
    &.pb-3 {
      padding-bottom: 3rem;
    }
    &.pt-1 {
      padding-top: 1rem;
    }
    &.pt-2 {
      padding-top: 2rem;
    }
    &.pt-3 {
      padding-top: 3rem;
    }
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      width: 100%;
      max-width: 1600px;
      gap: 2rem;
      &.type-2 {
        gap: 0rem;
        &.reversed {
          @media (max-width: 768px) {
            flex-direction: column;
          }
        }
      }
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
      &.reverse {
        flex-direction: row-reverse;
        @media (max-width: 768px) {
          flex-direction: column-reverse;
        }
      }
      & .info-box-left {
        width: calc(50% - 3rem);
        padding-right: 3rem;
        &>a {
          font-size: 1.375rem;
          line-height: 140%;
          text-decoration: none;
          font-family: "Libre Franklin";
          font-weight: 600;
          color: $app-purple-strong;
        }
        &.turquoise {
          background-color: $app-turquoise !important;
        }
        &.purple {
          background-color: $app-purple !important;
        }
        &.purple-strong {
          background-color: $app-purple-strong !important;
        }
        @media (max-width: 768px) {
          padding-right: unset;
          width: 100%;
        }
        &.reverse {
          padding-right: unset;
          width: calc(50%);
          @media (max-width: 768px) {
            width: calc(100%);
          }
        }
        &.type-2 {
          background-color: $app-turquoise;
          padding: 60px 2rem;
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
          font-weight: 600;
          @media (max-width: 768px) {
            border-radius: unset;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            padding: 2rem 1.25rem;
            width: calc(100% - 2.5rem);
            &.reversed {
              border-radius: unset;
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
            }
          }
        }
        & .info-box {
          &-title {
            font-size: 3.125rem;
            line-height: 110%;
            font-family: 'Inter Tight';
            font-weight: 800;
            padding-right: 3rem;
            margin-bottom: 2rem;
            max-width: 100%;
            @media (max-width: 1000px) {
              padding-right: 0rem;
            }
            @media (max-width: 768px) {
              padding-right: 0;
              font-size: 2.25rem;
              overflow-wrap: break-word;
              hyphens: auto;
            }
            &.sm {
              font-size: 1.5rem !important;
              line-height: 2.1rem !important;
              font-family: 'Libre Franklin' !important;
              font-weight: 600 !important;
              @media (max-width: 768px) {
                font-size: 1.25rem !important;
                line-height: 140% !important;
              }
            }
          }
          &-text {
            font-family: 'Libre Franklin';
            font-size: 1.25rem;
            line-height: 140% !important;
            margin-bottom: 0.5rem;
            padding-right: 3rem;
            @media (max-width: 768px) {
              padding-right: unset;
              font-size: 1.125rem;
            }
            &.type-2 {
              padding-right: 5rem;
              font-size: 1.5rem !important;
              line-height: 140% !important;
              @media (max-width: 768px) {
                padding-right: unset;
                font-size: 1.25rem !important;
              }
            }
          }
          &-btn {
            &-container {
              display: inline-block;
              padding: 26px 50px;
              border-radius: 3rem;
              background-color: $app-yellow;
              cursor: pointer;
              &:hover {
                background-color: rgba($app-yellow, 0.75);
              }
              @media (max-width: 768px) {
                margin-bottom: 1.5rem;
              }
            }
            &-text {
              font-size: 1.25rem;
              font-family: 'DM Mono';
              font-weight: 500;
              white-space: nowrap;
              text-align: center;
              display: flex;
              align-items: center;
              gap: 0.75rem;
              text-decoration: none;
              color: $app-black;
            }
          }
        }
      }
      & .info-box-right {
        width: 50%;
        display: inline-block;
        @media (max-width: 768px) {
          width: 100%;
        }
        &.reverse {
          padding-right: 3rem;
          width: calc(50% - 3rem);
          @media (max-width: 768px) {
            padding: 0rem;
            width: 100%;
          }
        }
        & img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.8rem;
          &.type-2 {
            border-radius: unset;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            @media (max-width: 768px) {
              border-radius: unset;
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
            }
          }
        }
      }
    }
  }
  &-text {
    &-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.75rem;
      @media (max-width: 768px) {
        flex-direction: column;
        &.custom {
          text-align: center;
        }
      }
      
    }
    &-no-image {
      background-color: $app-turquoise;
      padding: 3rem 3rem;
      padding-left: 0rem;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      font-family: 'Libre Franklin';
      font-size: 1.25rem;
      line-height: 1.75rem;
      @media (max-width: 768px) {
        font-size: 1.125rem;
        line-height: 140%;
        padding: 2rem 1.25rem;
        padding-top: unset;
        border-radius: unset;
        border-bottom-left-radius: 1rem;
        &.reversed {
          border-radius: unset;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }
  &-numbered {
    width: 2rem;
    height: 2rem;
    line-height: 110%;
    text-align: center;
    padding: 0.75rem 0.75rem;
    background-color: $app-purple-strong;
    font-size: 1.875rem;
    font-family: 'Inter Tight';
    color: $app-white;
    font-weight: 600;
    border-radius: 50%;
    margin-right: 1rem;
    @media (max-width: 768px) {
      margin-right: unset;
      padding: 5px 5px;
      width: 39px;
      height: 39px;
      line-height: normal;
    }
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-title {
      font-size: 1.25rem;
      line-height: 140%;
      font-family: 'Libre Franklin';
      font-weight: 600;
      text-align: left;
      &-container {
        display: flex;
        flex-direction: column;
      }
    }
    &-text {
      font-family: 'Libre Franklin';
      font-size: 1.25rem;
      line-height: 140%;
      padding-right: 4rem;
      @media (max-width: 1000px) {
        padding-right: 2rem;
      }
      @media (max-width: 768px) {
        text-align: center !important;
        padding-right: 0rem;
        font-size: 1.125rem;
      }
    }
  }
}
