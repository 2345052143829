@import '../../assets/styles/index.scss';

.contact-card-input {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(60% + 2rem + 6px);
    border-radius: 1rem;
    background-color: #edefee;
    padding: 2rem;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.55);
    padding: 1rem 0rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  &-left {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    &-select {
      position: absolute;
      top: -36px;
      left: -12px;
    }
  }
  &-image {
    &-input {
      display: none;
    }
    &-wrapper {
      margin-right: 2rem;
    }
    &-preview {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #ccc;
      cursor: pointer;
    }
    &-placeholder {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background-color: #f0f0f0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #aaa;
      border: 2px solid #ccc;
      cursor: pointer;
      text-align: center;
    }
  }
  &-text {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      justify-content: flex-start;
      & > .en {
        display: none;
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
