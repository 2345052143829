@import '../../assets/styles/index.scss';

.info-card-group-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 4rem);
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  padding: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: unset;
    padding: 2rem 1.25rem;
    width: calc(100% - 2.5rem);
  }
}
