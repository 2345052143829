@import '../../assets/styles/index.scss';


.footer-link-text {
  color: $app-white;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}