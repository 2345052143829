@import '../../../../assets/styles/index.scss';

.inquiry {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-back {
    &-link {
      font-family: 'DM Mono';
      font-size: 1.125rem !important;
      font-weight: 500;
      line-height: 1.3rem;
      color: rgb(107, 107, 107);
      width: calc(100% - 4rem);
      padding: 1rem 0rem;
      padding-bottom: 0.5rem;
      &>div {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &-header {
    font-family: 'Inter Tight';
    font-size: 2.75rem !important;
    font-weight: 800;
    line-height: 110%;
    margin-bottom: 1rem;
  }
  &-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
    margin-bottom: 2rem;
    &-assigned {
      margin-left: 0.5rem;
    }
    &-date {
      margin-left: 0.25rem;
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
    }
    &-text {
      font-family: 'Libre Franklin';
      font-size: 1.25rem;
      line-height: 100%;
      display: inline flex;
      align-items: center;
      &.deadline {
        align-items: flex-end;
      }
    }
    &-date-input {
      opacity: 0;
      width: 0px !important;
      height: 0px !important;
    }
    &-status {
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
      &.yellow {
        color: #f0b74e;
      }
      font-family: 'DM Mono' !important;
      font-weight: 500 !important;
      font-size: 1.25rem !important;
      line-height: 100% !important;
    }
    &-deadline {
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
    }
  }
  &-project {
    &-show {
      display: block;
    }
    &-hide {
      display: none;
    }
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    padding-bottom: 1rem;
    min-height: 300px;
    &-header {
      font-size: 3rem;
      font-family: 'Inter Tight';
      font-weight: 800;
      line-height: 110%;
      padding: 1rem;
    }
    &-product {
      padding: 1rem;
      display: flex;
      flex-direction: row;
      width: calc(100% - 2rem);
      gap: 2rem;
      &-left {
        width: 60%;
      }
      &-right {
        width: 40%;
        padding-top: 1rem;
        &>.product-page-material-container {
          width: 100%;
          &>.product-page-material-card {
            &>.product-page-material-entries {
              background-color: $app-beige;
            }
          }
        }
      }
    }
    &.column {
      flex-direction: column;
    }
    &.no-gap {
      gap: 0;
    }
    &.mb-2 {
      margin-bottom: 2rem;
    }
    &.background {
      background-color: $app-white;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
    &-left {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: calc(50% - 2rem);
      background-color: $app-white;
      padding: 1rem;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      &-text {
        font-family: 'Libre Franklin';
        line-height: 140%;
        display: inline flex;
        &-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr; /* 2 equal columns */
        }
        &-label {
          color: #7f7f7f;
          font-family: 'Libre Franklin';
          line-height: 100%;
          margin-bottom: 0.25rem;
          font-weight: 500;
        }
        &-item {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50%;
      height: 100%;
    }
  }
}

.state-history-table-container {
  flex-grow: 1;
  overflow: auto;
}

.assign-user-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.assign-user-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 60%;
  max-height: 90dvh;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    width: 85%;
  }
}

.assign-user-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &-title {
    font-family: 'Inter Tight';
    font-size: 2.75rem !important;
    font-weight: 800;
    line-height: 110%;
    &.small {
      font-size: 2rem !important;
      line-height: 110%;
      text-align: center;
      padding-bottom: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }

  &-back-btn {
    cursor: pointer;
    font-weight: 600;
    margin-left: -4rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
}

.assign-user-modal {
  &-saved {
    color: green;
    font-size: 1.5rem;
    font-family: 'Libre Franklin';
    font-style: italic;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  &-content {
    padding: 2rem 2rem;
    padding-top: 1rem;
    // overflow: auto;
    display: flex;
    flex-direction: column;
  }
  &-btn-group {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    & .custom-button-container {
      margin-right: 1rem;
    }
  }
  &-selected-user {
    color: $app-purple-strong;
  }
}

.assign-user-modal-error {
  margin-left: 2rem;
  &-entry {
    font-style: italic;
    color: red;
  }
}

.add-note-modal-error {
  margin-left: 2rem;
  &-entry {
    font-style: italic;
    color: red;
  }
}

.inquiry {
  &-filter {
    &-container {
      display: flex;
      align-items: center;
      padding-top: 1rem;
    }
    &-groups {
      display: flex;
      flex-direction: column;
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &-wrapper {
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
        @media (max-width: 768px) {
          width: calc(100% - 2.5rem);
          max-width: calc(1600px - 2.5rem);
          padding: 2rem 1.25rem;
        }
      }
    }
    &-label {
      margin-right: 1rem;
      font-weight: 600;
      font-size: 0.875rem;
      font-family: 'DM Mono';
    }
    &-selection {
      display: flex;
      align-items: center;
      &-buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        & .custom-button-container {
          padding: 0.4375rem 1.375rem;
          margin: 0.5rem;
          font-size: 0.9rem;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      &-dropdown {
        display: flex;
        align-items: center;
        &.mobile {
          display: none;
          @media (max-width: 768px) {
            display: flex;
          }
        }
      }
      & .edit-icon {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
  &-summary-list {
    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      padding: 2rem;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
        max-width: calc(1600px - 2.5rem);
        padding: 2rem 1.25rem;
      }
      &.column {
        flex-direction: column;
      }
    }
  }
}

.add-note-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.add-note-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 60%;
  max-height: 90dvh;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    width: 85%;
  }
}

.add-note-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &-title {
    font-family: 'Inter Tight';
    font-size: 2.75rem !important;
    font-weight: 800;
    line-height: 110%;
    &.small {
      font-size: 2rem !important;
      line-height: 110%;
      text-align: center;
      padding-bottom: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }

  &-back-btn {
    cursor: pointer;
    font-weight: 600;
    margin-left: -4rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
}

.add-note-modal {
  &-saved {
    color: green;
    font-size: 1.5rem;
    font-family: 'Libre Franklin';
    font-style: italic;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  &-content {
    padding: 2rem 2rem;
    padding-top: 1rem;
    // overflow: auto;
    display: flex;
    flex-direction: column;
    &-input {
      & > textarea {
        width: calc(100% - 1rem);
        resize: none;
        padding: 0.5rem;
        font-family: 'Libre Franklin';
        font-size: 1.25rem;
      }
    }
  }
  &-btn-group {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    & .custom-button-container {
      margin-right: 1rem;
    }
  }
  &-selected-user {
    color: $app-purple-strong;
  }
}

.add-note-error {
  &-container {
    margin: 0 0 1rem;
    align-self: flex-start;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}

.inquiry {
  &-filter {
    &-container {
      display: flex;
      align-items: center;
    }
    &-groups {
      display: flex;
      flex-direction: column;
      padding: 2rem 0 1rem;
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: $app-white;
      }
      &-wrapper {
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
        padding: 2rem;
        padding-bottom: 0rem;
        @media (max-width: 768px) {
          padding: 2rem 1.25rem;
          width: calc(100% - 2.5rem);
          max-width: calc(1600px - 2.5rem);
        }
      }
    }
    &-label {
      font-size: 0.875rem;
      margin-right: 1rem;
      font-weight: 600;
      font-family: 'DM Mono';
    }
    &-selection {
      display: flex;
      align-items: center;
      &-buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        & .custom-button-container {
          padding: 0.4375rem 1.375rem;
          margin: 0.5rem;
          font-size: 0.9rem;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      &-dropdown {
        display: flex;
        align-items: center;
        &.mobile {
          display: none;
          @media (max-width: 768px) {
            display: flex;
          }
        }
      }
      & .edit-icon {
        margin-left: 1rem;
        cursor: pointer;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}