@import '../../assets/styles/index.scss';


.app {
  &-footer {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 4rem 2rem;
      padding-right: 1rem;
      padding-bottom: 2rem; 
      background-color: $app-black;
      color: $app-white;
      width: calc(100% - 3rem);
      // position: absolute;
      // bottom: -370px;
      margin-top: auto;
      left: 0;
      @media (max-width: 520px) {
        padding-left: 1rem;
        width: calc(100% - 2rem);
      }
    }
    &-body {
      &-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: calc(1600px - 1rem - 2rem);
        @media (max-width: 520px) {
          flex-direction: column;
        }
      }
    }
    &-first-col {
      display: flex;
      flex-direction: column;
      padding-right: 4rem;
      width: 300px;
      // flex: 1;
      @media (max-width: 768px) {
        padding-right: 0rem;
      }
      @media (max-width: 520px) {
        width: calc(100% - 2rem);
        margin: 0rem 1rem;
      }
      &-logo {
        margin-bottom: 0.5rem;
        width: calc(220px - 2rem);
        padding-right: 2rem;
        cursor: pointer;
      }
      &-text {
        margin-bottom: 2rem;
        font-size: 0.9375rem;
        line-height: 1.125rem;
        font-family: 'Libre Franklin';
        @media (max-width: 520px) {
          padding-right: 3rem;
        }
      }
      &-link {
        &-container {
          display: flex;
          flex-direction: row;
          margin-top: 5rem;
          justify-content: space-between;
          font-size: 0.9375rem;
          line-height: 1.125rem;
          font-family: 'Libre Franklin';
          @media (max-width: 520px) {
            &.mobile {
              display: none;
            }
          }
          & div {
            & span {
              cursor: pointer;
            }
          }
          @media (max-width: 768px) {
            flex-direction: column;
            gap: 0.5rem;
          }
          @media (max-width: 520px) {
            flex-direction: row;
            margin-top: 2rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
    &-second-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(100% - 180px);
      @media (max-width: 520px) {
        margin-top: 3rem;
      }
      &-child {
        display: flex;
        flex-direction: column;
        flex: 1 1 calc(15% - 0.5rem);
        margin: 1rem;
        margin-top: unset;
        font-size: 1.0625rem;
        line-height: 1.4875rem;
        font-family: 'Libre Franklin';
        gap: 0.4rem;
        & div {
          & span {
            cursor: pointer;
          }
        }
        &.mobile {
          display: none;
        }
        @media (max-width: 520px) {
          &.mobile {
            display: flex;
            margin-top: 1rem;
          }
        }
        @media (max-width: 927px) and (min-width: 641px) {
          flex: 1 1 calc(30% - 0.5rem);
          max-width: 50%;
        }
        @media (max-width: 640px){
          flex: 1 1 calc(100% - 0.5rem);
          max-width: 100%;
        }
        &:last-child {
          padding-right: unset;
        }
      }
      @media (max-width: 520px) {
        width: calc(100%);
      }
    }
    &-divider {
      height: 1px;
      background-color: rgba(128, 128, 128, 0.4);
      margin: 0.5rem 0rem;
      &-mt {
        height: 1px;
        background-color: rgba(128, 128, 128, 0.4);
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
    }
    &-column {
      &-title {
        font-family: 'Inter Tight';
        font-size: 1.375rem;
        line-height: 1.5125rem;
        font-weight: 800;
      }
    }
  }
}