@import '../../../assets/styles/index.scss';

.dynamic-component-manager {
  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: calc(100% - 4rem);
  }
  &-add-component {
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: inline-block;
    user-select: none;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #ddd;
    }
  }
  &-dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 5px;
    width: 200px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    &-container {
      position: relative;
    }
    &-option {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      &:hover {
        background-color: #f1f1f1;
      }
    
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &-components {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &> * {
      margin-bottom: 2rem;
    }
    &-wrapper {
      position: relative;
      border-radius: 1rem;
      overflow: hidden;
      width: 100%;
      &-quill-container {
        width: calc(60% + 2rem + 6px);
      }
      &.video {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.55);
        padding: 1rem 0rem;
      }
      &-video-container {
        width: calc(60% + 2rem + 6px);
      }
      &.contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.55);
        padding: 1rem 0rem;
      }
      &-contact-container {
        width: calc(60% + 2rem + 6px);
      }
      &-info-box-container {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.55);
      }
    }
    &-delete-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border-radius: 50%;
      padding: 0.5rem;
      cursor: pointer;
    
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
      & svg {
        pointer-events: none; // Prevent pointer events on the icon itself
      }
    }
  }
}