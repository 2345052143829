// CheckMark
.uuid-cf6961e4-366e-4c2a-8831-71b5a05e38cd {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-9690ecce-4d5f-4c09-a175-e6b9556e94b2 {
  fill: #fcea19;
}


// HandShake
.uuid-3337cc53-73a5-484e-8851-159d89012936 {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-5c5b8efa-22e4-4204-8d3c-daa77f503122 {
  fill: #fcea19;
}


// Sustainable
.uuid-f37601c5-5b57-442e-9568-5938646e1e2c {
  stroke-linecap: round;
}

.uuid-f37601c5-5b57-442e-9568-5938646e1e2c, .uuid-7c4eb489-9343-40c4-afd3-0db1165db875 {
  fill: none;
  stroke: #000;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-5c04794c-beca-4e55-aaee-f027080e0547 {
  fill: #fcea19;
}


// Design
.uuid-184d74ad-f901-43ea-8f2a-da88e6aca27a {
  fill: #fcea19;
}

.uuid-6d6c6c7c-3f5d-4302-87b1-b0f474ba4022 {
  stroke-linejoin: round;
}

.uuid-6d6c6c7c-3f5d-4302-87b1-b0f474ba4022, .uuid-d8caf123-1e06-4b68-97fc-9138cbb50ab1 {
  fill: none;
  stroke: #000;
  stroke-width: 2.5px;
}

.uuid-d8caf123-1e06-4b68-97fc-9138cbb50ab1 {
  stroke-miterlimit: 10;
}


// Install
.uuid-76009115-f71f-4fd0-9597-17e23c3738eb {
  stroke-linecap: round;
}

.uuid-76009115-f71f-4fd0-9597-17e23c3738eb, .uuid-162b4176-da70-4053-b6d2-77163ef7677e {
  stroke-linejoin: round;
}

.uuid-76009115-f71f-4fd0-9597-17e23c3738eb, .uuid-162b4176-da70-4053-b6d2-77163ef7677e, .uuid-099ff49b-e641-4d67-94a6-3335d15d5b2c {
  fill: none;
  stroke: #000;
  stroke-width: 2.5px;
}

.uuid-a6a2c7d5-952e-4c20-8a61-467713b78993 {
  fill: #fcea19;
}

.uuid-099ff49b-e641-4d67-94a6-3335d15d5b2c {
  stroke-miterlimit: 10;
}


// Care
.uuid-91ed4204-7615-49f0-a39d-291a284019c1 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.uuid-91ed4204-7615-49f0-a39d-291a284019c1, .uuid-f8e32ea0-82a1-43e0-bad4-4944fd23525d {
  fill: none;
  stroke: #000;
  stroke-width: 2.5px;
}

.uuid-3059102a-b048-44f7-b582-840e22b1521b {
  fill: #fcea19;
}

.uuid-f8e32ea0-82a1-43e0-bad4-4944fd23525d {
  stroke-miterlimit: 10;
}


// City
.uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43, .uuid-3d827b6d-e025-4e6d-b260-a7eb04451611 {
  fill: none;
  stroke: #000;
  stroke-width: 2.5px;
}

.uuid-4921b3aa-bbeb-489b-a7fb-8a94ee495119 {
  fill: #fcea19;
}

.uuid-3d827b6d-e025-4e6d-b260-a7eb04451611 {
  stroke-miterlimit: 10;
}


// Dashing
.uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850 {
  stroke-linecap: round;
}

.uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850, .uuid-07e4f85c-47c8-43bd-ae38-e237c5f36d73 {
  fill: none;
  stroke: #000;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-d46dc7e9-af2b-4c85-b293-2d788dfdec89 {
  fill: #fcea19;
}


// Versatile
.uuid-c0d9e6bf-9770-4137-8772-fc9223c554c5 {
  fill: #fcea19;
}

.uuid-669b2756-041c-4bde-8c09-8f24ef4b2020 {
  fill: none;
  stroke: #000;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}


// Ugf
.uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-a1bb8d06-fb1e-4157-b859-61631292023f {
  fill: #fcea19;
}


// CareFree
.uuid-2c2a48a0-9047-4841-9671-c0df8512b57a {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-2a27eca9-282d-47df-a9d5-5afde1943031 {
  fill: #fcea19;
}


// Bee
.uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188 {
  stroke-linecap: round;
}

.uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188, .uuid-b307960a-64cf-44bd-96a3-d19945846aa4 {
  fill: none;
  stroke: #000;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-d53188ab-49fb-4451-ab19-8fd720c93ac2 {
  fill: #fcea19;
}


// Heart
.uuid-d7c8f392-2e2d-4434-8473-aecd0b302238 {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-906b8852-d667-4614-aa54-da1b10471d8b {
  fill: #fcea19;
}


// Future
.uuid-8fcf082b-f86f-4e3d-a9f7-9a9ec55cf102 {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.uuid-8fcacf40-2362-4054-ad5b-43ae1e4e56f1 {
  fill: #fcea19;
}


// Nature
.uuid-d299d9fc-3e07-46e2-855a-9d71101a3288, .uuid-40eb4a3e-3fb8-4437-98a4-cd065021cc74 {
  stroke-miterlimit: 10;
}

.uuid-d299d9fc-3e07-46e2-855a-9d71101a3288, .uuid-40eb4a3e-3fb8-4437-98a4-cd065021cc74, .uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a {
  fill: none;
  stroke: #000;
  stroke-width: 1.8px;
}

.uuid-40eb4a3e-3fb8-4437-98a4-cd065021cc74, .uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a {
  stroke-linecap: round;
}

.uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a {
  stroke-linejoin: round;
}

.uuid-4cf4e9d0-6d11-4648-909d-778776ed8c79 {
  fill: #85e8e5;
}


// Drop
.uuid-b073d469-57e1-4830-bb9f-8bfd34ad769d {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.8px;
}

.uuid-7fe3c2c9-1886-4f19-ae02-74be76715be9 {
  fill: #85e8e5;
}


// Circular
.uuid-d8cadf39-2228-4078-be4c-43cddfe3013d {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.8px;
}

.uuid-875bb3e9-1a0f-4064-b47a-a992016533da {
  fill: #85e8e5;
}