@import '../../assets/styles/index.scss';

.add-product-to-project-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.add-product-to-project-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 60%;
  max-height: 90dvh;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    width: 90%;
  }
}
.add-product-to-project-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom: 1px solid #a3a3a3;
  &-title {
    color: #a3a3a3;
    width: 6rem;
  }

  &-back-btn {
    cursor: pointer;
    font-weight: 600;
    margin-left: -4rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
}
.add-product-to-project-modal-content-scrollable {
  overflow: auto;
  max-height: 80dvh;
}
.add-to-project-modal {
  &-step-container {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 2rem 2rem;
  }
  &-step-title {
    font-size: 3rem !important;
    font-family: 'Inter Tight';
    margin-bottom: 2rem;
    font-weight: 800;
    line-height: 3rem;
    &.normal {
      font-weight: 600;
      margin-bottom: 0;
      font-size: 2.5rem !important;
      line-height: 110%;
    }
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }
  &-step-sub-title {
    color: #a3a3a3;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
  }
  &-step-selected-space {
    color: $app-purple-strong;
    margin-left: 0.5rem;
  }
  &-step-description {
    margin-bottom: 2rem;
    &.normal {
      margin-bottom: 0;
    }
  }
  &-add-new-resource-btn {
    font-weight: 800;
    font-size: 1.5rem;
    font-family: 'Inter Tight';
    margin-bottom: 2rem;
    cursor: pointer;
    width: fit-content;
  }
  &-selected-project-name {
    color: $app-purple-strong;
    font-family: 'DM Mono';
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &-btn-group {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }
    & .custom-button-container {
      margin-right: 1rem;
      &:first-child {
        background-color: black;
        color: white;
        border: unset;
      }
      &:nth-child(2) {
        color: black;
        border: unset;
        background-color: $app-purple !important;
      }
    }
  }
  &-text-btn {
    font-family: 'DM Mono';
    cursor: pointer;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    margin-left: 1rem;
    & svg {
      margin-right: 0.5rem;
    }
  }
}

.add-to-project-modal-new-resource {
  &-container {
    display: flex;
    flex-direction: column;

    margin: 0 -2rem;
    height: 0;
    overflow: hidden;
    transition: all 300ms;
    opacity: 0;
    &.show {
      height: 21rem;
      opacity: 1;
      border-bottom: 1px solid #a3a3a3;
      margin-bottom: 2rem;
      @media (max-width: 520px) {
        height: 24rem;
      }
    }
    &.space-show {
      height: 13rem;
      opacity: 1;
      border-bottom: 1px solid #a3a3a3;
      margin-bottom: 2rem;
      @media (max-width: 520px) {
        height: 17rem;
      }
    }
  }
  &-content {
    padding: 0 2rem;
  }
  &-input {
    &-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &-field {
      border-radius: 0.5rem;
      border: 1px solid #a3a3a3;
      padding: 1rem 0.75rem;
      font-size: 1rem;
    }
  }
  &-btn-group {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 2rem;
    @media (max-width: 520px) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }
    & .custom-button-container {
      margin-right: 1rem;
      font-size: 0.9rem;
      &:first-child {
        background-color: black;
        color: white;
        border: unset;
      }
      &:last-child {
        color: black;
      }
    }
  }
}

.add-to-project-modal-project-list {
  &-search-container {
    display: flex;
  }
  &-search-input {
    border-radius: 2rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #a3a3a3;
    margin-bottom: 1rem;
    min-width: 40%;
  }
  &-project-entries {
    border-top: 1px solid #a3a3a3;
    margin: 1rem -2rem 0;
  }
  &-project-entry {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #a3a3a3;
    padding: 0.75rem 2rem;
    font-weight: 600;
    cursor: pointer;
  }
}

.add-to-project-modal-space-list {
  margin-bottom: 2rem;
  &-entries {
    display: flex;
    flex-wrap: wrap;
  }
  &-entry {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.75rem;
    margin: 0.5rem;
    background-color: $app-yellow;
    border-radius: 2rem;
    cursor: pointer;
    font-family: 'DM Mono';
    &.default {
      background-color: $app-turquoise;
    }
  }
}
.add-to-project-modal-error {
  &-container {
    margin: 0 0 1rem;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}