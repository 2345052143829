@import "../../assets/styles/index.scss";

.product {
  &-group {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $app-beige;
      padding: 5rem 2rem;
      width: calc(100% - 4rem);
      @media (max-width: 768px) {
        padding: 2rem 1.25rem;
        width: calc(100% - 2.5rem);
      }
      &.home-page {
        background-color: #E8E2D5;
      }
      &.product-page {
        padding: 2rem 2rem;
        padding-top: 4rem;
        padding: 2rem 1.25rem;
        @media (max-width: 768px) {
          padding-top: 2rem;
          width: calc(100% - 2.5rem);
        }
      }
    }
    &-body {
      &-container {
        width: 100%;
        max-width: 1600px;
        align-items: flex-start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
    &-title {
      &-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-bottom: 0.5rem;
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &-link {
        &-wrapper {
          display: flex;
          align-items: center;
          background-color: $app-turquoise;
          padding: 7px 22px;
          border-radius: 1rem;
          margin-bottom: 1.20rem;
          margin-left: 2rem;
          cursor: pointer;
          gap: 0.5rem;
          @media (max-width: 768px) {
            margin-left: 0rem;
          }
          &.custom {
            padding: unset;
            margin-bottom: 0.8rem;
            background-color: unset !important;
          }
        }
        &-container {
          position: relative;
          display: flex;
          align-items: center;
          background-color: $app-turquoise;
          padding: 7px 22px;
          border-radius: 1rem;
          margin-bottom: 1.20rem;
          margin-left: 2rem;
          cursor: pointer;
          gap: 0.5rem;
          @media (hover: hover) {
            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(255, 255, 255, 0.3);
              z-index: 1;
              border-radius: 2rem;
            }
            &:hover {
              border-color: rgba(255, 255, 255, 0.3);
            }
            // Ensure text and content remain above the pseudo-element
            & > * {
              position: relative;
              z-index: 1;
            }
          }
          @media (max-width: 768px) {
            margin-left: 0rem;
          }
          &.custom {
            padding: unset;
            margin-bottom: 0.8rem;
            background-color: unset !important;
          }
        }
        &-text {
          font-size: 0.875rem;
          line-height: 1.14rem;
          font-family: 'DM Mono';
          font-weight: 500;
          color: $app-black;
          text-decoration: none;
          white-space: nowrap;
          &.custom {
            font-size: 1.0625rem !important;
            line-height: 1.2875rem !important;
            font-family: 'Libre Franklin' !important;
          }
        }
      }
      &-text {
        margin-bottom: 0.5rem;
        font-family: 'Inter Tight';
        font-weight: 800;
        font-size: 3.625rem;
        line-height: 4rem;
        @media (max-width: 768px) {
          font-size: 1.875rem;
          line-height: 110%;
        }
      }
    }
    &-custom {
      &-carousel {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        &-entry {
          border-radius: 1rem;
          display: flex;
          flex-direction: column;
          background-color: white;
          cursor: pointer;
          height: 100%;
          &-container {
            padding: 0 8px;
            box-sizing: border-box;
          }
          &:hover .product-group-custom-carousel-entry-image-container{
            & img {
              transform: scale(1.05)
            }
          }
          &:hover .hover-underline-product-group::after {
            opacity: 1; // Fade in underline when container is hovered
          }
          &-image {
            &-container {
              display: flex;
              width: 100%;
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
              background-color: #e7e7e7;
              height: 100%;
              min-height: 250px;
              max-height: 250px;
              overflow: hidden;
              @media (max-width: 768px) {
                min-height: calc(100vw - 20vw);
                min-height: calc(100vw - 20vw);
              }
              & img {
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
                width: 100%;
                height: auto;
                object-fit: cover;
                display: block;
                transition: transform 0.5s ease;
              }
            }
          }
          &-no-image {
            height: 100%;
            min-height: 12.75rem;
          }
          &-product {
            &-info {
              padding: 1rem;
              display: flex;
              flex-direction: column;
              display: inline-flex;
            }
            &-name {
              font-size: 1.375rem;
              font-family: 'Inter Tight';
              font-weight: 800;
              line-height: 1.5125rem;
              margin-bottom: 0.5rem;
              &-container {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                position: relative;
              }
            }
            &-customize-entry {
              font-size: 0.8125rem;
              font-family: 'DM Mono';
              font-weight: 500;
              display: flex;
              flex-direction: row;

              &-text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}

.hover-underline-product-group {
  position: relative;
  text-decoration: none; // No underline by default
  color: #000; // Text color
}

.hover-underline-product-group::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.5s ease; 
}