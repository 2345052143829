@import '../../assets/styles/index.scss';

.image-card-container {
  width: calc(100% - 4rem);
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem 1.25rem;
    padding-top: 0rem;
    width: calc(100% - 2.5rem);
  }
  // border-radius: 1rem;
  & img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
