@import '../../../assets/styles/index.scss';

.custom-contact-card {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFF;
    width: calc(100% - 2rem);
    border-radius: 1rem;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    overflow: hidden;
    min-width: calc(470px - 2rem);
    @media (max-width: 768px) {
      flex-direction: column;
      min-width: unset;
    }
  }
  &-image {
    &-wrapper {
      margin-right: 2rem;
      @media (max-width: 768px) {
        margin-right: unset;
        margin-bottom: 1rem;
      }
      &>img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  &-text {
    font-size: 1.25rem;
    font-family: 'Libre Franklin';
    font-weight: 400;
    line-height: 1.75rem;
    @media (max-width: 768px) {
      text-align: center;
    }
    &-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: 768px) {
        align-items: center;
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        align-items: center;
      }
    }
  }
  &-email {
    font-size: 1.0625rem;
    font-family: 'Libre Franklin';
    font-weight: 400;
    line-height: 1.4875rem;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &-phone {
    font-size: 1.0625rem;
    font-family: 'Libre Franklin';
    font-weight: 400;
    line-height: 1.4875rem;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &-name {
    font-size: 1.875rem;
    font-family: 'Inter Tight';
    font-weight: 800;
    line-height: 2.0625rem;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &-title {
    font-size: 1rem;
    font-family: 'DM Mono';
    font-weight: 500;
    line-height: 1.3rem;
    color: $app-purple-strong;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
}