@import '../../../../assets/styles/index.scss';

.user {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-back {
    &-link {
      font-family: 'DM Mono';
      font-size: 1.125rem !important;
      font-weight: 500;
      line-height: 1.3rem;
      color: rgb(107, 107, 107);
      width: calc(100% - 4rem);
      padding: 1rem 0rem;
      padding-bottom: 0.5rem;
      &>div {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &-header {
    font-family: 'Inter Tight';
    font-size: 2.75rem !important;
    font-weight: 800;
    line-height: 110%;
    margin-bottom: 1rem;
  }
  &-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
    margin-bottom: 2rem;
    &-assigned {
      margin-left: 0.5rem;
    }
    &-date {
      margin-left: 0.25rem;
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
    }
    &-text {
      font-family: 'Libre Franklin';
      font-size: 1.25rem;
      line-height: 100%;
      display: inline flex;
      align-items: center;
      &.deadline {
        align-items: flex-end;
      }
    }
    &-date-input {
      opacity: 0;
      width: 0px !important;
      height: 0px !important;
    }
    &-role {
      font-family: 'DM Mono' !important;
      font-weight: 500 !important;
      font-size: 1.25rem !important;
      line-height: 100% !important;
    }
    &-status {
      font-family: 'DM Mono' !important;
      font-weight: 500 !important;
      font-size: 1.25rem !important;
      line-height: 100% !important;
    }
    &-deadline {
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
    }
  }
  &-project {
    &-show {
      display: block;
    }
    &-hide {
      display: none;
    }
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    padding-bottom: 1rem;
    &.column {
      flex-direction: column;
    }
    &.no-gap {
      gap: 0;
    }
    &.mb-2 {
      margin-bottom: 2rem;
    }
    &.background {
      background-color: $app-white;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
    &-left {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: calc(50% - 2rem);
      background-color: $app-white;
      padding: 1rem;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      &-text {
        font-family: 'Libre Franklin';
        line-height: 140%;
        display: inline flex;
        &-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr; /* 2 equal columns */
        }
        &-label {
          color: #7f7f7f;
          font-family: 'Libre Franklin';
          line-height: 100%;
          margin-bottom: 0.25rem;
          font-weight: 500;
        }
        &-item {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50%;
      height: 100%;
    }
  }
}
