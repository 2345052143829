@import '../../assets/styles/index.scss';



.contact-department-group {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #E8E2D5;
    padding-top: 2rem;
  }
  &-wrapper {
    display: inline flex;
    flex-direction: row;
    gap: 1rem;
    @media (max-width: 525px) {
      flex-direction: column;
    }
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
    }
  }
  &-root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 4rem);
    margin: 0rem 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.40);
    @media (max-width: 768px) {
      width: calc(100% - 2rem);
      margin: 0rem 1rem;
    }
  }
  &-title {
    font-size: 2.75rem !important;
    line-height: 3.025rem !important;
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 110% !important;
    }
    width: calc(100% - 76px - 0.5rem);
    padding-right: 0.5rem;
    overflow-wrap: break-word;
    hyphens: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
    &-container {
      position: relative;
      width: calc(100%);
      padding: 2rem 0rem;
      cursor: pointer;
      
    }
    &-icon {
      padding: 1rem;
      position: absolute;
      top: 18px;
      right: 2rem;
      cursor: pointer;
      @media (max-width: 768px) {
        right: 1rem;
        top: 10px;
      }
      // @media (max-width: 450px) {
      //   flex-direction: column;
      //   position: inherit;
      //   top: unset;
      //   right: unset;
      // }
    }
  }
  &-phone {
    background-color: $app-turquoise;
    padding: 1.25rem 2.5rem;
    border-radius: 2rem;
    font-size: 1.25rem !important;
    line-height: 1.6275rem !important;
    margin-bottom: 0.75rem;
    white-space: nowrap;
    display: inline;
    &:hover {
      background-color: #9dede9;
    }
    @media (max-width: 768px) {
      margin-bottom: 0rem;
      text-align: center;
    }
  }
  &-email {
    background-color: $app-turquoise;
    padding: 1.25rem 2.5rem;
    border-radius: 2rem;
    font-size: 1.25rem !important;
    line-height: 1.6275rem !important;
    margin-bottom: 0.75rem;
    color: $app-black;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      background-color: #9dede9;
    }
    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  &-divider {
    height: 1px;
    width: calc(100% - 4rem);
    background-color: grey;
  }
  &-contacts {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      // min-width: 550px;
      transition: max-height 0.4s ease, opacity 0.5s ease, padding 0.5s ease;
      &.hidden {
        max-height: 0;
        opacity: 0;
        padding: 0;
      }
      &.visible {
        max-height: 4000px;
        opacity: 1;
        padding: 2rem 1rem;
        padding-top: 0rem;
      }
    }
  }
  &-employee {
    &-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0.75rem 0rem;
      justify-content: flex-start;
      width: 100%;
      &:has(.contact-department-group-employee-card-container:nth-last-child(-n+5)) {
        justify-content: center;
      }
    }
    &-card {
      &-container {
        flex: 1 1 calc(15%);
        max-width: calc(15%);
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 1rem;
        background-color: $app-white;
        border-radius: 1rem;
        margin: 0.25rem;
        @media (max-width: 400px) {
          min-width: 75vw;
        }
      }
    }
  }
}

.fa-circle {
  color: black;
}
