@import '../../assets/styles/index.scss';

.sign-up-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.sign-up-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 50%;
  max-height: 90dvh;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    width: 85%;
  }
}
.sign-up-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &-title {
    font-family: 'Inter Tight';
    font-size: 3rem !important;
    margin-bottom: 1.5rem;
    font-weight: 800;
    line-height: 3rem;
    padding: 0 2rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    &-title {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }
}
.sign-up-modal {
  &-content {
    padding: 0 2rem;
    overflow: auto;
    max-height: 80dvh;
  }
  &-input {
    &-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &-field {
      border-radius: 0.5rem;
      border: 1px solid #a3a3a3;
      padding: 1rem 0.75rem;
      font-size: 1rem;
    }
  }
  &-btn-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem 2rem 0;
  }
  &-terms {
    margin: 0 2rem 1rem;
    &-link {
      color: $app-purple-strong;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.sign-up-modal-input {
  &-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    & label {
      font-family: 'Libre Franklin';
      font-size: 1.0625rem;
      line-height: 1.4875rem;
    }
    & input {
      outline: none;
      border: 1px solid rgba(#000000, 0.3);
      padding: 0.5rem 0.75rem;
      border-radius: 0.3rem;
      font-size: 1.0625rem;
      font-family: 'Libre Franklin';
      line-height: 1.4875rem;
      &:focus {
        border-color: $app-purple !important;
        outline: 1px solid $app-purple;
      }
    }
  }
}
.sign-up-modal-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  & input {
    margin: 0;
    margin-right: 0.5rem;
  }
}
.sign-up-modal-error {
  &-container {
    margin: 0 2rem 1rem;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}
.sign-up-modal-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.115);
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
