@import '../../../../../assets/styles/index.scss';

.customize-blog {
  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh + 420px);
    width: calc(100vw - 1rem);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.30);
  }
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    margin-bottom: 2rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-height: calc(100vh - 5rem);
      max-width: 1600px;
      width: 100%;
      &.center  {
        justify-content: center;
      }
    }
  }
  &-header {
    &-language {
      padding: 0.5rem 2rem;
      border: 1px solid black;
      border-bottom: unset;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 0.5rem;
        border-right: unset;
      }
      &:last-child {
        border-top-right-radius: 0.5rem;
        border-left: unset;
      }
      &-selector {
        display: flex;
        flex-direction: row;
      }
      &-wrapper {
        width: calc(100% - 2rem);
        padding: 0rem 1rem;
      }
      &.active {
        background: $app-purple-strong;
        color: white;
        border-color: $app-purple-strong;
      }
    }
    &-title {
      &-en {
        display: none;
      }
    }
    &-button {
      &-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10rem;
        margin-bottom: 1rem;
      }
      &-error {
        font-style: italic;
        color: red;
        margin-bottom: 1rem;
      }
      &-success {
        font-style: italic;
        color: green;
        margin-bottom: 1rem;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 4rem);
    }
    &-title {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $app-white;
        width: calc(100% - 4rem);
        padding: 2rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
    }
    &-image {
      &-wrapper {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
    &-save {
      &-button {
        &-container {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1rem 2rem;
          border-radius: 3rem;
          background: $app-yellow;
          color: $app-black;
          cursor: pointer;
          font-family: 'DM Mono';
          font-size: 1rem;
          gap: 1rem;
          @media (hover: hover) {
            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(255, 255, 255, 0.3);
              z-index: 1;
            }
            &:hover {
              border-color: rgba(255, 255, 255, 0.3);
            }
            // Ensure text and content remain above the pseudo-element
            & > * {
              position: relative;
              z-index: 1;
            }
          }
          &.disabled {
            background-color: #e1e1e1 !important;
            border-color: #e1e1e1 !important;
            color: #a3a3a3 !important;
            pointer-events: none;
          }
        }
      }
    }
  }
  &-card {
    &-text {
      &-container {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        width: calc(60%);
      }
      width: 100%;
      text-align: center;
      margin-bottom: 0.25rem;
      font-size: 1.5rem;
      font-family: 'Inter Tight';
    }
  }
}