@import '../../../assets/styles/index.scss';


.custom-header {
  &-reference {
    &-container {
      display: flex;
      flex-direction: column;
      width: min(100vw - 1rem, 1600px);
      justify-content: center;
      align-items: center;
    }
    &-text {
      font-size: 1rem;
      line-height: 1.3rem;
      font-family: 'DM Mono';
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 0.8125rem;
        line-height: normal;
      }
      &-container {
        display: flex;
        flex-direction: row;
        width: calc(100% - 4rem);
        padding: 3rem 2rem;
        padding-top: unset;
        padding-bottom: unset;
        @media (max-width: 1200px) {
          flex-direction: column;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          padding: 2rem 1.25rem;
          padding-bottom: 0rem;
          width: calc(100% - 2.5rem);
        }
      }
    }
    &-title {
      &-text {
        font-family: 'Inter Tight';
        font-weight: 800;
        font-size: 4.625rem;
        line-height: 5.0875rem;
        overflow-wrap: break-word;
        hyphens: auto;
        @media (max-width: 768px) {
          font-size: 1.875rem;
          line-height: 110%;
          margin: 1.25rem 0rem;
        }
      }
    }
    &-type {
      &-text {
        color: $app-purple-strong;
        font-size: 0.875rem;
        line-height: 1.139rem;
        font-weight: 500;
        font-family: 'DM Mono';
        margin-top: 0.75rem;
        @media (max-width: 768px) {
          font-size: 0.8125rem;
          line-height: normal;
          margin-top: 0rem;
        }
      }
    }
    &-left {
      &-container {
        display: flex;
        flex-direction: column;
        width: calc(50% - 2rem);
        padding: 3rem 2rem;
        padding-right: unset;
        background-color: $app-white;
        border-top-left-radius: 1rem;
        @media (max-width: 1200px) {
          width: calc(100% - 4rem);
          padding-right: 2rem;
          padding-bottom: 2rem;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
        @media (max-width: 768px) {
          width: calc(100% - 3rem);
          padding: 2.5rem 1.5rem;
          padding-bottom: 0rem;
        }
      }
    }
    &-right {
      &-container {
        display: flex;
        flex-direction: column;
        width: calc(50% - 6rem);
        padding: 3rem 2rem;
        padding-right: 4rem;
        background-color: $app-white;
        border-top-right-radius: 1rem;
        @media (max-width: 1200px) {
          width: calc(100% - 4rem);
          padding-right: 2rem;
          border-radius: unset;
          padding-top: unset;
        }
        @media (max-width: 768px) {
          width: calc(100% - 3rem);
          padding: 2.5rem 1.5rem;
          padding-top: 1.25rem;
        }
      }
    }
    &-body {
      &-text {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 400;
        font-family: 'Libre Franklin';
        @media (max-width: 768px) {
          font-size: 1.125rem;
          line-height: 140%;
        }
      }
    }
    &-image {
      &-container {
        display: flex;
        width: calc(100% - 4rem);
        margin-bottom: 0.5rem;
        @media (max-width: 768px) {
          width: calc(100% - 2.5rem);
        }
        &>img {
          width: 100%;
          height: auto;
          object-fit: contain;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }
  &-blog {
    &-container {
      display: flex;
      flex-direction: column;
      width: min(100vw - 1rem, 1600px);
      justify-content: center;
      align-items: center;
    }
    &-text {
      &-container {
        display: flex;
        width: calc(100% - 5.5rem);
        padding: 2.5rem 1.25rem;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: $app-white;
        margin-bottom: unset;
        margin-top: unset;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        @media (max-width: 768px) {
          width: calc(100% - 5rem);
        }
      }
    }
    &-title {
      &-text {
        padding: 0rem 2rem;
        font-size: 4.625rem;
        line-height: 5.0875rem;
        font-weight: 800;
        font-family: 'Inter Tight';
        overflow-wrap: break-word;
        hyphens: auto;
        @media (max-width: 768px) {
          font-size: 1.875rem;
          line-height: 110%;
        }
      }
    }
    &-type {
      &-date {
        font-family: 'DM Mono';
        font-weight: 500 !important;
        line-height: 1.3rem !important;
        font-size: 1rem !important;
        margin-top: 1rem;
        @media (max-width: 768px) {
          font-size: 0.8125rem !important;
          line-height: normal !important;
          margin-top: 0rem;
          margin-bottom: 1.25rem;
        }
      }
      &-filter {
        font-family: 'DM Mono';
        font-size: 0.875rem !important;
        font-weight: 500 !important;
        line-height: 1.14rem !important;
        color: $app-purple-strong;
        margin-top: 1rem;
        margin-bottom: 1rem;
        @media (max-width: 768px) {
          font-size: 0.8125rem !important;
          line-height: normal;
          margin-top: 1.25rem;
          margin-bottom: 0rem;
        }
      }
    }
    &-image {
      &-container {
        display: flex;
        width: calc(100% - 3rem);
        margin-bottom: 0.5rem;
        @media (max-width: 768px) {
          width: calc(100% - 2.5rem);
        }
        &>img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }
}