.loading-spinner-container{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.30);
  &.border {
    border-radius: 1rem;
  }
  &.border2 {
    border-radius: 2rem;
  }
  &.noBackground {
    background-color: transparent;
  }
}