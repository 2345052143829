@import '../../assets/styles/index.scss';


.product-page {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 1600px;
      width: 100%;
    }
  }
  &-header {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }
    &-link {
      display: flex;
      align-items: center;
      color: grey;
      cursor: pointer;
      width: fit-content;
      font-size: 1rem !important;
      color: rgb(107, 107, 107);
      font-family: 'DM Mono';
      padding: 0.5rem 2rem;
      text-decoration: none;
      @media (max-width: 768px) {
        padding: 0.5rem 1.25rem;
      }
      & svg {
        margin-right: 0.5rem;
      }
    }
    &-product {
      &-name {
        font-size: 4rem;
        line-height: 110%;
        font-weight: 800;
        font-family: 'Inter Tight';
        margin: 0 0 3rem;
        padding: 0rem 2rem;
        @media (max-width: 768px) {
          margin-bottom: 30px;
          padding: 0rem 1.25rem;
          font-size: 2.5rem;
        }
      }
      &-img {
        &-more-btn {
          &-wrapper {
            padding: 2rem 1.25rem;
            width: calc(100% - 2.5rem);
            &.mobile {
              @media (min-width: 769px) {
                display: none;
              }
            }
          }
          position: relative;
          background-color: $app-yellow;
          border-radius: 2rem;
          padding: 1.5rem 3rem;
          font-family: 'DM Mono';
          font-weight: 500;
          font-size: 0.8125rem;
          text-align: center;
          cursor: pointer;
          &.mobile {
            @media (max-width: 768px) {
              display: none;
            }
          }
          @media (hover: hover) {
            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(255, 255, 255, 0.3);
              border-radius: 2rem;
              z-index: 1;
            }
            // Ensure text and content remain above the pseudo-element
            & > * {
              position: relative;
              z-index: 1;
            }
          }
          & > svg {
            width: 10px !important;
            height: 10px !important;
          }
        }
        &-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0rem 2rem;
          width: calc(100% - 4rem);
          @media (max-width: 768px) {
            padding: 0rem 1.25rem;
            width: calc(100% - 2.5rem);
          }
        }
        &-boilerplate {
          position: relative;
          width: 100%;
          border-radius: 1rem;
          background-color: #e7e7e7;
          max-height: 800px;
          & img {
            display: block;
            border-radius: 1rem;
            border-radius: 1rem;
            width: 100%;
            height: auto;
            object-fit: cover;
            max-height: 800px;
          }
          &-more-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $app-yellow;
            border-bottom-right-radius: 1rem;
            padding: 1.5rem 3rem;
            font-family: 'DM Mono';
            font-weight: 500;
            font-size: 1.125rem;
            cursor: pointer;
            &.mobile {
              @media (max-width: 768px) {
                display: none;
              }
            }
            @media (hover: hover) {
              &:hover::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(255, 255, 255, 0.3);
                z-index: 1;
              }
              // Ensure text and content remain above the pseudo-element
              & > * {
                position: relative;
                z-index: 1;
              }
            }
          }
          
        }
      }
    }
  }
  &-info {
    padding: 2rem 2rem;
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    width: calc(100% -  4rem);
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: unset;
      width: calc(100% - 2.5rem);
      padding: 1rem 1.25rem;
    }
  }
  &-description {
    &-text {
      text-align: left;
      font-size: 1.375rem;
      line-height: 1.925rem;
      font-family: 'Libre Franklin';
      @media (max-width: 768px) {
        font-size: 1.125rem;
        line-height: 140%;
      }
    }
    &-link {
      font-size: 1.375rem;
      line-height: 1.925rem;
      font-family: 'Libre Franklin';
      font-weight: 500;
      color: $app-purple-strong;
      text-decoration: none;
    }
    &-title {
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 2.75rem;
      line-height: 3.025rem;
    }
    &-container {
      padding: 2rem;
      padding-top: 0.5rem;
      padding-right: 4rem;
      display: flex;
      flex-direction: column;
      width: 61.5%;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 1.5rem;
        padding: 1rem 0rem;
      }
    }
  }
  &-measurements {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    padding-top: 1rem;
    @media (max-width: 768px) {
      padding: 1rem 0rem;
    }
    &-title {
      font-size: 2.5rem;
      line-height: 110%;
      font-weight: 800;
      font-family: 'Inter Tight';
      padding-bottom: 1rem;
      @media (max-width: 768px) {
        font-size: 1.875rem;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-item {
      display: flex;
      flex-direction: row;
      width: calc(100% - 0.75rem);
      padding: 0.75rem;
      padding-left: unset;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      &-value {
        font-family: 'DM Mono';
        font-weight: 500;
        font-size: 1.125rem;
        width: 50%;
        &-bullet-container {
          display: flex;
          align-items: flex-start;
          position: relative;
          padding-left: 1rem;
        }
        &-bullet {
          position: absolute;
          width: 0.4rem;
          height: 0.4rem;
          background-color: black;
          border-radius: 0.8rem;
          top: 0.5rem;
          left: 0;
        }
        &-container {
          display: flex;
          flex-direction: column;
          width: 50%;
          @media (max-width: 768px) {
            width: 100%;
            margin-top: 10px;
          }
        }
        &-array {
          font-family: 'DM Mono';
          font-weight: 500;
          font-size: 1.125rem;
          width: 100%;
          @media (max-width: 768px) {
            font-size: 1.0625rem;
          }
        }
      }
      &-type {
        font-family: 'Libre Franklin';
        font-weight: 400;
        font-size: 1.25rem;
        width: 50%;
        @media (max-width: 768px) {
          font-size: 1.125rem;
          width: 100%;
        }
      }
      & .full-width {
        width: 100% !important;
      }
      &-divider {
        width: 100%;
        height: 1px;
        background-color: rgb(170, 170, 170);
      }
    }
  }
  &-materials {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    padding-top: unset;
    @media (max-width: 768px) {
      padding: 1rem 0rem;
    }
    &-title {
      font-size: 2.5rem;
      line-height: 110%;
      font-weight: 800;
      font-family: 'Inter Tight';
      padding-bottom: 1rem;
      @media (max-width: 768px) {
        font-size: 1.875rem;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-item {
      display: flex;
      flex-direction: row;
      width: calc(100% - 0.75rem);
      padding: 0.75rem;
      padding-left: unset;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        gap: 0.75rem;
        @media (max-width: 768px) {
          width: 100%;
          margin-top: 10px;
        }
      }
      &-value {
        font-family: 'DM Mono';
        font-weight: 500;
        font-size: 1.125rem;
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 1.0625rem;
          margin-top: 10px;
        }
      }
      &-type {
        font-family: 'Libre Franklin';
        font-weight: 400;
        font-size: 1.25rem;
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 1.125rem;
        }
      }
      &-divider {
        width: 100%;
        height: 1px;
        background-color: rgb(170, 170, 170);
      }
    }
  }
  &-material {
    &-card {
      border-radius: 1rem;
      width: 100%;
    }
    &-title {
      background-color: $app-purple;
      font-family: 'Inter Tight';
      font-size: 1.875rem;
      line-height: 110%;
      font-weight: 800;
      padding: 1.5rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    &-entries {
      padding: 1rem 2rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      background-color: white;
    }
    &-entry {
      font-size: 1rem;
      line-height: 110%;
      font-family: 'Libre Franklin';
      padding: 1rem 0;
      display: flex;
      cursor: pointer;
      display: flex;
      align-items: center;
      & svg {
        margin-right: 0.5rem;
      }
      &:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #aaa;
      }
    }
    @media (max-width: 768px) {
      &-title {
        padding: 1rem;
      }
      &-entries {
        padding: 0 1rem;
      }
      &-card {
        max-width: unset;
      }
    }
    &-container {
      width: 39.5%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      @media (max-width: 1300px) {
        flex-grow: 1;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  &-wlt {
    &-card {
      margin-top: 2rem;
      width: 100%;
      &-container {
        width: calc(100% - 4rem);
        background-color: $app-white;
        padding: 2rem 2rem;
        border-radius: 1rem;
      }
      &-title {
        font-size: 1.875rem !important;
        line-height: 2.0625rem !important;
        text-align: center;
        padding-bottom: 2rem;
      }
      &-text {
        text-align: center;
        padding-bottom: 2rem;
      }
      &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        &>img {
          width: 100%;
          min-width: 10rem;
          max-width: 20rem;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}