@import '../../../assets/styles/index.scss';

.custom-cookies-banner-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-cookies-banner-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 500px;
  padding-bottom: 3rem;
  max-height: 90dvh;
  @media (max-width: 768px) {
    width: 85%;
  }
}

.custom-cookies-banner {
  &-title {
    font-family: 'Inter Tight';
    font-size: 3rem !important;
    padding: 3rem 3rem 0;
    font-weight: 800;
    line-height: 3rem;
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }
  &-content {
    padding: 2rem 3rem 1rem;
    overflow: auto;
    max-height: 60vh;
  }
  &-btn-group {
    display: flex;
    align-items: center;
    padding: 1rem 3rem 0;
    & .custom-button-container {
      margin-right: 1rem;
    }
  }
  &-link {
    cursor: pointer;
    color: $app-purple-strong;
    width: fit-content;
    margin-top: 0.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
}
.custom-cookies-banner-link {
  font-style: italic;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}