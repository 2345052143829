@import '../../assets/styles/index.scss';

.product-summary-page {
  &-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    padding-top: 0rem;
  }
  &-header {
    display: flex;
    padding: 1.5rem 0;
    &-link {
      display: flex;
      align-items: center;
      color: grey;
      cursor: pointer;
      width: fit-content;
      & svg {
        margin-right: 0.5rem;
      }
      &>p {
        font-family: 'DM Mono';
      }
      &-container {
        padding-top: 1rem;
        width: 100%;
        background-color: $app-beige-strong;
        display: inline flex;
        flex-direction: column;
        align-items: center;
      }
      &-wrapper {
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
        padding: 0rem 2rem;
        padding-top: 4rem;
        @media (max-width: 768px) {
          width: calc(100% - 2.5rem);
          max-width: calc(1600px - 2.5rem);
          padding: 0rem 1.25rem;
          padding-top: 4rem;
        }
      }
    }
    &-container {
      padding-top: 1rem;
      width: 100%;
      background-color: $app-beige-strong;
      display: inline flex;
      flex-direction: column;
      align-items: center;
    }
    &-wrapper {
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      padding: 0rem 2rem;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
        max-width: calc(1600px - 2.5rem);
        padding: 0rem 1.25rem;
      }
    }
    &-left-content {
      margin-right: 4rem;
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    &-right-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & .custom-button-container {
        margin-bottom: 1rem;
      }
    }
    & .product-summary-page-project-name {
      margin-bottom: 3rem;
      font-size: 4rem;
      line-height: 110%;
      font-weight: 800;
      font-family: 'Inter Tight';
      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }
    & .product-summary-page-project-description {
      width: 60%;
    }

    @media (max-width: 1300px) {
      & .product-summary-page-project-description {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      &-left-content {
        width: 100%;
      }
      &-right-content {
        width: 100%;
      }
    }
  }
  &-project {
    &-link {
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: $app-turquoise
      }
      &-wrapper {
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        padding: 2rem;
        @media (max-width: 600px) {
          flex-direction: column;
          gap: 1rem;
        }
        @media (max-width: 768px) {
          width: calc(100% - 2.5rem);
          max-width: calc(1600px - 2.5rem);
          padding: 2rem 1.25rem;
        }
      }
      &-text {
        font-size: 1.875rem;
        line-height: 110%;
        font-weight: 800;
        font-family: 'Inter Tight';
        width: fit-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        & svg {
          margin-left: 0.5rem;
        }
        &-small {
          font-size: 1.0625rem;
          font-family: 'Libre Franklin';
          line-height: 140%;
        }
      }
      &-print-button {
        &>.custom-button-container {
          &>.custom-button-content {
            font-size: 1rem !important;
          }
        }
      }
    }
    &-date-status {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 2rem 0;
      width: 60%;
      &-item {
        display: flex;
        flex-direction: column;
        // align-items: center;
      }
      &-label {
        color: $app-purple-strong !important;
        font-weight: 600;
        margin-bottom: 1rem;
    
      }
      @media (max-width: 1300px) {
        width: 100%;
      }
    }
  }
  &-product {
    &-filter {
      &-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      &-groups {
        display: flex;
        flex-direction: column;
        padding: 2rem 0 1rem;
        &-container {
          width: 100%;
          display: flex;
          justify-content: center;
          background-color: $app-beige;
        }
        &-wrapper {
          width: calc(100% - 4rem);
          max-width: calc(1600px - 4rem);
          padding: 2rem;
          @media (max-width: 768px) {
            width: calc(100% - 2.5rem);
            max-width: calc(1600px - 2.5rem);
            padding: 2rem 1.25rem;
          }
        }
      }
      &-label {
        margin-right: 1rem;
        font-weight: 600;
        font-size: 0.875rem;
        font-family: 'DM Mono';
      }
      &-selection {
        display: flex;
        align-items: center;
        &-buttons {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          & .custom-button-container {
            padding: 0.4375rem 1.375rem;
            margin: 0.5rem;
            font-size: 0.9rem;
          }
          @media (max-width: 768px) {
            display: none;
          }
        }
        &-dropdown {
          display: flex;
          align-items: center;
          &.mobile {
            display: none;
            @media (max-width: 768px) {
              display: flex;
            }
          }
        }
        & .edit-icon {
          margin-left: 1rem;
          cursor: pointer;
        }
      }
    }
    &-summary-list {
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &-wrapper {
        display: flex;
        justify-content: center;
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
        padding: 2rem;
        @media (max-width: 768px) {
          width: calc(100% - 2.5rem);
          max-width: calc(1600px - 2.5rem);
          padding: 2rem 1.25rem;
        }
      }
    }
  }
  &-footer {
    &-container {
      display: inline flex;
      flex-direction: column;
      align-items: center;
  
    }
    &-wrapper {
      display: flex;
      align-items: center;
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      padding: 0rem 2rem;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
        max-width: calc(1600px - 2.5rem);
        padding: 0rem 1.25rem;
      }
    }
    &-left-content {
      width: 70%;
      display: flex;
      padding: 2rem 2rem 1rem 0;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      @media (max-width: 1024px) {
        width: 60%;
      }
      @media (max-width: 768px) {
        width: 100%;
        padding: 0rem;
        flex-direction: column;
        padding-top: 1rem;
      }
      & .custom-button-container {
        padding: 28px 54px;
        border-radius: 5rem;
        // margin-right: 2rem;
        &>.custom-button-content {
          font-size: 1rem !important;
        }
        &:nth-child(2) {
          background-color: $app-purple !important;
          color: black !important;
          border: unset !important;
        }
      }
      &-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 800;
        font-size: 1.5rem;
        line-height: 140%;
        & svg {
          margin-left: 0.5rem;
        }
      }
    }
    &-right-content {
      width: 30%;
      // min-width: 10rem;
      font-size: 1.5rem;
      font-weight: 600;
      @media (max-width: 1024px) {
        width: 40%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      &-title {
        font-family: "Libre Franklin";
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 140%;
        white-space: nowrap;
        text-align: right;
        @media (max-width: 840px) {
          margin-top: 1rem;
        }
        @media (max-width: 768px) {
          margin-top: 0rem;
          text-align: center;
        }
      }
    }
  }
}

.product-summary-page-footer {
  display: flex;
  padding: 2rem 0 1rem;
  align-items: center;
  margin-top: auto;
  &-wrapper {
    @media (max-width: 768px) {
     flex-direction: column-reverse;
    }
  }
  @media (max-width: 1024px) {
    &-left-content {
      & .custom-button-container {
        padding: 1rem 2rem;
      }
    }
  }
}
