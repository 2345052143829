@import '../../assets/styles/index.scss';


.project-page {
  &-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    padding-top: 0rem;
    min-height: calc(100vh - 5rem);
  }
  &-header {
    display: flex;
    padding: 1.5rem 0;
    &-error {
      margin: 3rem;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 110%; 
      font-family: 'Libre Franklin';
      font-style: italic;
      color: red;
    }
    &-link {
      display: flex;
      align-items: center;
      color: grey;
      cursor: pointer;
      width: fit-content;
      & svg {
        margin-right: 0.5rem;
      }
      &>p {
        font-family: 'DM Mono';
      }
      &-container {
        padding-top: 1rem;
        width: 100%;
        background-color: $app-beige-strong;
        display: inline flex;
        flex-direction: column;
        align-items: center;
      }
      &-wrapper {
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
        padding: 0rem 2rem;
        padding-top: 4rem;
        @media (max-width: 768px) {
          padding: 0rem 1.25rem;
          padding-top: 4rem;
          width: calc(100% - 2.5rem);
          max-width: calc(1600px - 2.5rem);
        }
      }
    }
    &-container {
      display: inline flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      background-color: $app-beige-strong;
    }
    &-wrapper {
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      padding: 0rem 2rem;
      @media (max-width: 768px) {
        padding: 0rem 1.25rem;
        width: calc(100% - 2.5rem);
        max-width: calc(1600px - 2.5rem);
      }
    }
    &-left-content {
      margin-right: 4rem;
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    &-right-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & .custom-button-container {
        margin-bottom: 1rem;
      }
    }
  }
  &-product {
    &-summary {
      &-link {
        &-container {
          width: 100%;
          display: flex;
          justify-content: center;
          background-color: $app-purple;
        }
        &-wrapper {
          width: calc(100% - 4rem);
          max-width: calc(1600px - 4rem);
          padding: 2rem;
        }
        &-text {
          font-size: 1.875rem;
          line-height: 110%;
          font-weight: 800;
          font-family: 'Inter Tight';
          width: fit-content;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          & svg {
            margin-left: 0.5rem;
          }
          &-small {
            font-size: 1.0625rem;
            font-family: 'Libre Franklin';
            line-height: 140%;
          }
        }
      }
    }
    &-filter {
      &-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      &-groups {
        display: flex;
        flex-direction: column;
        padding: 2rem 0 1rem;
        &-container {
          width: 100%;
          display: flex;
          justify-content: center;
          background-color: $app-beige;
        }
        &-wrapper {
          width: calc(100% - 4rem);
          max-width: calc(1600px - 4rem);
          padding: 2rem;
          @media (max-width: 768px) {
            padding: 2rem 1.25rem;
            width: calc(100% - 2.5rem);
            max-width: calc(1600px - 2.5rem);
          }
        }
      }
      &-label {
        font-size: 0.875rem;
        margin-right: 1rem;
        font-weight: 600;
        font-family: 'DM Mono';
      }
      &-selection {
        display: flex;
        align-items: center;
        &-buttons {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          & .custom-button-container {
            padding: 0.4375rem 1.375rem;
            margin: 0.5rem;
            font-size: 0.9rem;
          }
          @media (max-width: 768px) {
            display: none;
          }
        }
        &-dropdown {
          display: flex;
          align-items: center;
          &.mobile {
            display: none;
            @media (max-width: 768px) {
              display: flex;
            }
          }
        }
        & .edit-icon {
          margin-left: 1rem;
          cursor: pointer;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    &-list {
      &-loader {
        width: 100%;
        padding: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10rem;
      }
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: $app-beige;
      }
      &-wrapper {
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
        padding: 2rem;
        @media (max-width: 768px) {
          padding: 2rem 1.25rem;
          padding-top: 0;
          width: calc(100% - 2.5rem);
          max-width: calc(1600px - 2.5rem);
        }
      }
      &-header {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.40);
        font-size: 2rem;
        font-weight: 600;
        &-cell {
          padding: 1rem 0;
          &:first-child {
            flex-grow: 1;
            width: 80%;
            @media (max-width: 768px) {
              display: none;
            }
          }
          &:last-child {
            width: 20%;
            min-width: 10rem;
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
      }
      &-product {
        &-new-btn {
          width: calc(100% - 1.25rem);
          background-color: $app-turquoise;
          padding: 0.625rem;
          font-family: "DM Mono";
          font-size: 1.125rem;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          border-radius: 3rem;
          margin-top: 3rem;
          cursor: pointer;
          position: relative;
          &.disabled {
            background-color: #e1e1e1 !important;
            color: #a3a3a3 !important;
            pointer-events: none;
          }
          &.mobile {
            @media (max-width: 768px) {
              display: none;
            }
          }
          @media (hover: hover) {
            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(255, 255, 255, 0.3);
              z-index: 1;
            }
            // Ensure text and content remain above the pseudo-element
            & > * {
              position: relative;
              z-index: 1;
            }
          }
          &>span {
            margin-left: 1rem;
          }
        }
        &-no-entry {
          padding: 2rem;
          font-size: 1.2rem;
          font-style: italic;
        }
      }
      &-no-entry {
        padding: 2rem;
        font-size: 1.2rem;
        font-style: italic;
      }
    }
  }
  &-footer {
    &-container {
      display: inline flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem 2rem;
      @media (max-width: 768px) {
        padding: 2rem 1.25rem;
        padding-top: 0;
      }
    }
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      padding: 0rem 2rem;
      @media (max-width: 768px) {
        padding: 0rem 0rem;
        width: calc(100%);
        max-width: calc(1600px);
      }
      @media (max-width: 580px) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
    
    &-left-content {
      display: flex;
      align-items: center;
      @media (max-width: 840px) {
        flex-direction: column;
        align-items: flex-start;
        width: 60%;
      }
      @media (max-width: 580px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      & .custom-button-container {
        padding: 28px 54px;
        border-radius: 5rem;
        margin-right: 2rem;
        & > .custom-button-content {
          font-family: "DM Mono";
          font-size: 1rem !important;
          font-weight: 500;
          line-height: normal;
        }
        @media (max-width: 840px) {
          margin-right: 0rem;
        }
        @media (max-width: 580px) {
          width: calc(100% - 8rem);
        }
      }
      &-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 800;
        font-size: 1.5rem;
        line-height: normal;
        @media (max-width: 840px) {
          margin-top: 1.5rem;
        }
        @media (max-width: 580px) {
          margin-top: 2rem;
        }
        & svg {
          margin-left: 0.5rem;
        }
      }
    }
    &-right-content {
      min-width: 10rem;
      display: flex;
      gap: 0.5rem;
      &-title {
        font-family: "Libre Franklin";
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 140%;
        white-space: nowrap;
        @media (max-width: 840px) {
          margin-top: 1rem;
        }
        @media (max-width: 580px) {
          margin-top: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.project-page-header {
  & .project-page-project-name {
    margin-bottom: 3rem;
    font-size: 4.625rem;
    font-weight: 800;
    line-height: 110%; 
    font-family: 'Inter Tight';
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
  & .project-page-project-description {
    width: 60%;
    font-family: "Libre Franklin";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
  }
  @media (max-width: 1300px) {
    & .project-page-project-description {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    &-left-content {
      width: 100%;
    }
    &-right-content {
      width: 100%;
    }
  }
}

.project-page-project-date-status {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 2rem 0;
  width: 60%;
  &-item {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  &-label {
    color: $app-purple-strong;
    font-weight: 600;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      margin-bottom: 0rem;
    }
  }
  &-value {
    font-family: "Libre Franklin";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 23.8px */
    @media (max-width: 768px) {
      margin-top: 0.2rem;
    }
  }
  @media (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

