@import '../../assets/styles/index.scss';

.home {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 5rem);
    // padding-bottom: 2rem;
  }
  &-title {
    font-size: 5.375rem;
    font-weight: 800;
    line-height: 110%;
    margin-bottom: 2rem;
    font-family: 'Inter Tight';
    @media (max-width: 768px) and (orientation: portrait) {
      font-size: 5.375rem;
    }
  }
  &-video {
    &-container {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-bottom: 2rem;
      width: 100%;
      @media (max-width: 768px) and (orientation: portrait) {
        align-items: center;
        overflow: hidden;
      }
      & .home-video {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 56.25%;
        @media (max-width: 768px) and (orientation: portrait) {
          padding-top: 0;
          height: 100vh;
          width: calc(100vh * (16 / 9)) !important;
          & iframe {
            height: 100vh;
            width: calc(100vh * (16 / 9)) !important;
          }
        }
        &-player {
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
        }
        &-loader {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 10;
          background-color: rgba(0, 0, 0, 0.115);
        }
        & img {
          display: block;
          width: 100vw;
          height: auto;
          object-fit: cover;
        }
      }
      & .home-title {
        color: $app-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }
  }
}
