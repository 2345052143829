@import '../../assets/styles/index.scss';

.search-page {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 26rem);
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 4rem);
      max-width: 1600px;
    }
  }
  &-title {
    font-size: 3.625rem;
    line-height: 3.9875rem;
    font-family: 'Inter Tight';
    font-weight: 800;
    padding: 2rem 0rem;
  }
  &-input {
    &-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    &-wrapper {
      position: relative;
      width: 100%;
      max-width: 760px;
      &> input {
        padding-left: 3rem;
        padding-right: 1rem;
        width: calc(100% - 4rem);
        height: calc(3.025625rem - 3px);
        border: 1px solid rgba(#000000, 0.30);
        border-radius: 0.3rem;
        outline: none;
        font-size: 1.0625rem;
        font-family: 'Libre Franklin';
        line-height: 1.4875rem;
        &:focus {
          border-color: $app-purple !important;
          outline: 1px solid $app-purple;
        }
      }
    }
    &-icon {
      &-wrapper {
        position: absolute;
        left: calc((3.025625rem - 5px) - (3rem / 2) - (24px / 4));
        top: calc((3.025625rem - 5px) - (3rem / 2) - (24px / 4));
      }
    }
  }
  &-divider {
    margin: 3rem 0rem;
    height: 1px;
    width: 100%;
    background-color: rgba(#000000, 0.40);
  }
}