// ArrowLeft
.uuid-3cedb777-c9e8-4dfa-8258-3488c171c433 {
  fill: none;
  stroke-linejoin: round;
}

// ArrowRight
.cls-1 {
  fill: none;
  stroke-linejoin: round;
}

// ArrowShort
.uuid-6d95e68e-e3c6-4ec2-a88b-e14e0afaa392 {
  fill: none;
  stroke-width: 2px;
}

// CheckMark
.uuid-9b7cecff-674a-4ce0-acd7-c4363cf24697 {
  fill: #fcea19;
}

.uuid-385ea7f3-55b0-486e-a606-f4cf88e2c573 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

// CircleSelected
.uuid-2f7585f9-4bb5-443d-a8f4-b5c6209c5ef2 {
  stroke: #000;
  stroke-linejoin: round;
}

// CircleUnselected
.uuid-84958daf-d199-4d03-a234-6066a9888e8a {
  fill: none;
  stroke: #000;
  stroke-linejoin: round;
}

// Close
.uuid-e1e7784a-cefc-485f-aa47-5b2454343819 {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}

// Download
.uuid-025a2db4-e358-4596-a946-927174209af8 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}

// InDoor
.uuid-fb2a9905-7405-466d-9f6c-52d77a992fff {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

// OutDoor
.uuid-abbaa7c6-fb04-4d40-b651-63e75638a760 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

// MenuClose
.uuid-836f8830-3055-466d-b989-5aa47950b845 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.uuid-9fb96526-a90f-4c91-a4bd-b697f94aa5eb {
  fill: #85e8e5;
}

// MenuOpen
.uuid-edaa6f02-a9a0-4724-8ea8-896b08e91864 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.uuid-45ccc717-72ad-47ff-a228-76dfe8f5c088 {
  fill: #85e8e5;
}

// SearchIcon
.uuid-2eb59806-933c-4c7f-8d05-576bf4f50202 {
  fill: none;
  stroke-linejoin: round;
  stroke-width: 2px;
}

// CirlePlusIcon
.uuid-dff81ba5-6e00-4f4c-8d0d-b2c0951c82c8 {
  stroke-opacity: 0.5;
  stroke-linejoin: round;
}

// PlusIcon
.uuid-ca23c1eb-6c64-4323-8e5e-41533f116f39 {
  stroke-width: 2;
  stroke-linejoin: round;
}

// CircleMinusIcon
.uuid-9de4684c-3072-41ce-b801-6bf4543c01a6 {
  stroke-opacity: 0.5;
  stroke-linejoin: round;
}

//MinusIcon
.uuid-29ce893d-7e86-4032-adcb-8a13c8c5bf72 {
  stroke-width: 2;
  stroke-linejoin: round;
}

// AccordionClose
.uuid-783699f4-b94c-4d6a-97c5-4d60da712c76 {
  fill: #fff;
}

.uuid-02fde4c8-3024-467b-bfd8-cb113ceb9770 {
  fill: none;
  // stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}


// AccordionOpen
.uuid-942eb191-d864-4b53-b1a6-e4dd28479ef4 {
  fill: none;
  // stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}


// ArrowLeftLong
.uuid-e335c038-86f6-4ae0-8ac1-036cbce0d78e {
  stroke: #666666;
  stroke-width: 1.3;
  stroke-miterlimit: 10;
}
.uuid-d668e60e-3b33-48b7-a094-8fe6e3453c8f {
  fill: #666666;
}


// ArrowShortCircleDown
.uuid-ba00ff20-5a40-4f81-8e6f-9adb66dec385 {
  fill: black;
}
.uuid-119d77b1-d611-4033-a510-d968db189f09 {
  stroke: white;
}

// ArrowShortCircleUp
.uuid-7fc5d055-fb76-4cd3-9ede-c736af04752f {
  fill: black;
}
.uuid-8e3a3b9e-f4d2-40ab-9a0c-9275c06a6972 {
  stroke: white;
}

// Duplicate
.uuid-f4346bb8-dcb3-40c9-9974-2e59d3177c98 {
  fill: none;
  // stroke: #000;
  stroke-linejoin: round;
}


// Delete
.uuid-cf7ba262-25c1-49a2-bb9a-5789b4be8e05 {
  fill: none;
  // stroke: #000;
  stroke-linejoin: round;
}