@import '../../assets/styles/index.scss';

.product-details-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0rem;
  padding-top: 0rem;
  // min-height: calc(100vh - 5rem);
  padding-top: 4rem;
}

.product-details-page-body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 4rem);
  max-width: calc(1600px - 4rem);
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    max-width: calc(1600px - 2rem);
  }
}

.product-details-page-back-navigation-link {
  display: flex;
  align-items: center;
  color: grey;
  cursor: pointer;
  width: fit-content;
  font-size: 1rem !important;
  color: rgb(107, 107, 107);
  font-family: 'DM Mono';
  text-decoration: none;
  & svg {
    margin-right: 0.5rem;
  }
}
.product-details-page-product-name {
  font-size: 4rem !important;
  margin: 1.5rem 0 3rem;
  // @media (max-width: 768px) {
  //   font-size: 2.5rem;
  // }
}
.product-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-img-boilerplate {
  width: 65%;
  border-radius: 1rem;
  border-radius: 1rem;
  background-color: $app-beige;
  //max-height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    border-radius: 1rem;
    border-radius: 1rem;
    width: inherit;
    height: inherit;
    max-height: 800px;
    margin-bottom: 2rem;
  }
}
.product-details-page-info {
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: unset;
    margin-bottom: 1rem;
  }
}
.product-details-page-description {
  width: 50%;
  // margin-right: 4rem;
  padding-right: 4rem;
  text-align: justify;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.product-details-page-material-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 2rem;
  }
}
.product-details-page-material {
  &-card {
    border-radius: 1rem;
    width: 100%;
    max-width: 40rem;
  }

  &-title {
    background-color: $app-purple;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  &-entries {
    padding: 0 1.5rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: white;
  }
  &-entry {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    & svg {
      margin-right: 0.5rem;
    }
    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
    &:not(:last-child) {
      border-bottom: 1px solid black;
    }
  }
  @media (max-width: 768px) {
    &-title {
      padding: 1rem;
    }
    &-entries {
      padding: 0 1rem;
    }
    &-card {
      max-width: unset;
    }
  }
}
.product-details-page-configurate-product-btn {
  width: 15rem;
  margin-top: 2rem;

  & .custom-button-container {
    padding: 2rem 4rem;
    border-radius: 4rem;
    background-color: black;
    color: white;
    width: 15rem;
  }
  @media (max-width: 1200px) {
    width: 100%;

    & .custom-button-container {
      padding: 1rem 2rem;
      font-size: 0.9rem;
      width: unset;
    }
  }
}
