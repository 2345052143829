@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@500;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;600;800&$display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&display=swap');
@import './assets/styles/index.scss';

body {
  margin: 0;
  font-family: 'Libre Franklin', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1rem; //16px
  line-height: 1.4rem; //22.4px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

a {
  &:hover {
    text-decoration: underline !important;
  }
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight */
}

#App {
  background-color: $app-beige;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// USE THE BELOW CLASSES FOR THE ENTIRE APP WHERE APPLICABLE
.header-text {               //FOR HEADINGS: ONE WEIGHT - 800 (EXTRA BOLD)
  font-family: 'Inter Tight';
  font-weight: 800;
  font-size: 2.75rem; //44px
  line-height: 3.025rem; //48.4px
}

.body-text {               //FOR BODY TEXT & INGRESS: 400 (REGULAR) AND 600 (SEMI BOLD)
  font-family: 'Libre Franklin';
  font-size: 1rem; //16px
  line-height: 1.4rem; //22.4px
}

.small-text {            //PAGE NUMBERING, AND OTHERS ?!?! : 500 (MEDIUM)
  font-family: 'DM Mono';
  font-size: 0.625rem; //10px
  line-height: 0.75rem; //12px
}

.no-select {
  user-select: none; /* Disable text selection for supported browsers */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
}

.no-highlight {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight */
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.css-1arijsr-MuiSnackbar-root {
  z-index: 10001 !important;
}

.loading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 1.0);
}