@import '../../../assets/styles/index.scss';

.custom-select-container {
  position: relative;
  &.blog {
    margin-top: 1rem;
  }
}

.custom-select-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
  &.blog {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.custom-select-tag {
  background-color: #e0e0e0;
  border-radius: 12px;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  color: $app-purple-strong;
}

.custom-select-tag-delete {
  margin-left: 8px;
  cursor: pointer;
  color: $app-purple-strong;
}

.custom-select-label {
  color: #888;
  cursor: pointer;
  &.blog {
    text-align: center;
  }
}

.custom-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 10;
  margin-top: 4px;
  max-height: 240px;
  overflow-y: auto;
  width: 200px;
  text-wrap: nowrap;
  &.blog {
    left: 50%;
    transform: translateX(-50%);
    width: 270px;
  }
}

.custom-select-option {
  padding: 10px;
  cursor: pointer;
}

.custom-select-option:hover {
  background-color: #f0f0f0;
}

.custom-select-option.selected {
  background-color: #e0e0e0;
  font-weight: bold;
}
