@import '../../assets/styles/index.scss';

.social-icon-group {
  color: $app-white;
  &-container {
    display: flex;
    padding-right: 1.75rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    max-height: 25px;
  }
  &.black {
    color: $app-black;
  }
}