@import '../../../assets/styles/index.scss';

.custom-image-header-input {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;
  }
  &-has-image {
    width: 100%;
  }
  &-icon {
    &-circle {
      color: white;
    }
  }
  &-image {
    &-preview {
      position: relative;
      &>img {
        display: block;
        cursor: pointer;
      }
    }
    &-placeholder {
      width: 250px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed #ccc;
      border-radius: 8px;
      color: gray;
    }
    &-uploaded {
      object-fit: cover;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      width: 100%;
    }
    &-remove-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.5rem;
    }
  }
}
