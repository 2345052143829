@import '../../assets/styles/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@500;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&display=swap');
@import '~quill/dist/quill.snow.css';

// Quill font picker for Inter Tight
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="inter-tight"]::before {
  content: 'Inter Tight';
  font-family: 'Inter Tight', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="inter-tight"]::before {
  content: 'Inter Tight';
  font-family: 'Inter Tight', sans-serif;
}

// Quill font picker for Libre Franklin
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="libre-franklin"]::before {
  content: 'Libre Franklin';
  font-family: 'Libre Franklin', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="libre-franklin"]::before {
  content: 'Libre Franklin';
  font-family: 'Libre Franklin', sans-serif;
}

// Quill font picker for DM Mono
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="dm-mono"]::before {
  content: 'DM Mono';
  font-family: 'DM Mono', monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="dm-mono"]::before {
  content: 'DM Mono';
  font-family: 'DM Mono', monospace;
}

.ql-font-inter-tight {
  font-family: 'Inter Tight', sans-serif;
  // @media (max-width: 768px) {
  //   font-size: 1.875rem !important;
  //   line-height: 110% !important;
  //  }
}

.ql-font-libre-franklin {
  font-family: 'Libre Franklin', sans-serif;
  // @media (max-width: 768px) {
  //   font-size: 1.125rem !important;
  //   line-height: 140% !important;
  //  }
}

.ql-font-dm-mono {
  font-family: 'DM Mono', monospace;
  // @media (max-width: 768px) {
  //   font-size: 1.25rem !important;
  //   line-height: 140% !important;
  //  }
}

.ql-font {
  width: 116px !important;
}

.ql-editor {
  min-height: 200px; /* Ensure editor's content area has height */
  & > p {
    & > a {
      text-decoration: none !important;
      & > span {
        color: $app-purple-strong;
        font-weight: 600 !important;
        
      }
    }
  }
}
//  Quick Selection
.ql-quickSelection {
  & .ql-picker-label {
    padding-right: 1rem !important;
  }
}

.ql-editor .ql-quickSelection-card {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  font-family: 'Libre Franklin' !important;
}

.ql-editor .ql-quickSelection-text {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  font-family: 'Libre Franklin' !important;
}

.ql-editor .ql-quickSelection-text-emphasized {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  font-family: 'Libre Franklin' !important;
}

.ql-editor .ql-quickSelection-heading {
  font-size: 2.75rem;
  font-weight: 800;
  line-height: 3.025rem;
  font-family: 'Inter Tight' !important;
}

.ql-quickSelection-heading {
  font-family: 'Inter Tight' !important;
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-label::before,
.ql-snow .ql-picker.ql-quickSelection .ql-picker-item::before {
  /* content: 'Normal'; */
  content: '12px';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-label[data-value="card"]::before,
.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="card"]::before {
  /* content: 'Small'; */
  content: 'Card';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-label[data-value="text"]::before,
.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="text"]::before {
  /* content: 'Large'; */
  content: 'Text';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-label[data-value="text-emphasized"]::before,
.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="text-emphasized"]::before {
  /* content: 'Large'; */
  content: 'Text Emphasized';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-label[data-value="heading"]::before,
.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="heading"]::before {
  /* content: 'Huge'; */
  content: 'Heading';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="card"]::before {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  font-family: 'Libre Franklin';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="text"]::before {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="text-emphasized"]::before {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
}

.ql-snow .ql-picker.ql-quickSelection .ql-picker-item[data-value="heading"]::before {
  font-size: 2.75rem;
  font-weight: 800;
  line-height: 3.025rem;
  font-family: 'Inter Tight';
}
