@import '../../assets/styles/index.scss';

.employee-card-name {
  font-size: 1.375rem !important;
  line-height: 1.5125rem !important;
  margin-bottom: 1rem;
  margin-top: 1.25rem;
  text-align: center;
}

.employee-card-department {
  color: $app-purple-strong;
  font-size: 0.875rem !important;
  line-height: 1.14rem !important;
  text-align: center;
  margin-bottom: 1rem;
}

.employee-card-email {
  font-size: 0.9375rem !important;
  line-height: 1.3125rem !important;
  text-align: center;
}

.employee-card-phone {
  font-size: 0.9375rem !important;
  line-height: 1.3125rem !important;
  text-align: center;
}

.employee-card-img-wrapper {
  & img {
    width: 180px;
    height: 180px;
    border-radius: 100%;
  }
}