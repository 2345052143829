@import '../../assets/styles/index.scss';

.youtube-video-input {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 1rem;
  }
  &-video {
    &-url {
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }
    &-preview {
      margin-bottom: 1rem;
    }
  }
}