@import '../../assets/styles/index.scss';


.blog-group {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    padding-bottom: 2rem;
    &.default {
      background-color: #E8E2D5;
    }
    &.mt-0 {
      margin-top: 0rem;
    }
  }
  &-body {
    &-container {
      padding: 2rem 2rem;
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 768px) {
        padding: 2rem 1.5rem;
        width: calc(100% - 3rem);
      }
      &.center {
        align-items: center;
        justify-content: center;
      }
    }
  }
  &-title {
    margin-right: 2rem;
    font-family: 'Inter Tight';
    font-weight: 800;
    font-size: 2.75rem !important;
    line-height: 3.25rem !important;
    overflow-wrap: break-word;
    hyphens: auto;
    &-container {
      padding-bottom: 2rem;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  &-link {
    display: flex;
    align-items: center;
    font-size: 1.0625rem !important;
    line-height: 1.285rem !important;
    font-family: 'Inter Tight';
    font-weight: 500;
    margin-bottom: 0.4rem;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
    }
  }
  &-cards {
    &-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: wrap;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      &.center {
        align-items: center;
        justify-content: center;
      }
    }
  }
}
