@import '../../assets/styles/index.scss';

.account-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 5rem);
  padding-top: 6rem;
}

.account-page-tab-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0rem;
  gap: 1rem;
  & .custom-dropdown-container {
    height: 3rem;
    & .custom-dropdown-selected-container {
      padding: 0rem;
      & .custom-dropdown-selected-content-wrapper {
        & .custom-dropdown-selected-content {
          font-size: 3.625rem !important;
          font-family: 'Inter Tight' !important;
          font-weight: 800 !important;
          line-height: 140% !important;
        }
      }
    }
  }
}

.account-page-tab-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem;
  gap: 1rem;
}

.account-page-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  color: $app-black;
  transition: all 0.3s;
  &.active {
    color: $app-purple-strong;
  }
  &:hover {
    color: $app-purple-strong;
  }
}



.account-page-body-container {
  display: flex;
  max-width: calc(1600px);
  width: 100%;
  min-height: calc(100vh - 5rem);
  position: relative;
}

.account-page-content-wrapper {
  display: flex;
  width: 100%;
  height: calc(100% + 80px);
  position: absolute;
  top: -80px;
  @media (max-width: 768px) {
   flex-direction: column;
   top: 0;
  }
  @media (max-width: 1130px) {
    position: inherit;
    top: 0;
    min-height: calc(100vh - 5rem);
  }
}

.account-page-left-content {
  width: 24vw;
  max-width: 450px;
  padding: 2rem 2rem;
  box-sizing: border-box;
  height: 100%;
  background-color: $app-turquoise;
  padding-top: 100px;
  &-title {
    font-weight: 800;
    font-size: 3rem;
    margin-bottom: 3rem;
    font-family: 'Inter Tight';
    line-height: 110%;
    @media (max-width: 768px) {
      font-size: 1.875rem;
      line-height: 140%;
      margin-bottom: 1rem;
    }
  }
  &-text {
    &-group {
      margin-bottom: 2rem;
      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }
  &-update-link {
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 2rem;
  }
  & .custom-button-container {
    width: 8rem;
    margin-top: 0.75rem;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  @media (max-width: 1130px) {
    padding-top: 4rem;
    min-width: 273px;
    min-height: calc(100vh - 5rem);
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem 1.25rem;
    height: auto;
    min-height: unset;
    min-width: unset;
    max-width: unset;
  }

}

.account-page-right-content {
  width: 76vw;
  padding: 4rem 4rem;
  padding-right: 2rem;
  box-sizing: border-box;
  height: 100%;
  padding-top: 5rem;
  &-title {
    font-size: 3.625rem;
    font-family: 'Inter Tight';
    line-height: 110%;
    font-weight: 800;
    margin-bottom: 3rem;
    @media (max-width: 768px) {
      font-size: 3rem;
      line-height: 110%;
      margin-bottom: 2rem;
    }
  }
  &-filter {
    &-container {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
      flex-wrap: wrap;
    }
  }
  &-create-btn {
    font-family: 'Inter Tight';
    cursor: pointer;
    display: inline flex;
    align-items: center;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 1.5rem;
    & svg {
      margin-right: 0.5rem;
    }
  }
  &-search-container {
    display: flex;
    margin-bottom: 2rem;
  }
  &-search-input {
    border-radius: 2rem;
    padding: 0.75rem 1rem;
    border: 1px solid #a3a3a3;
    margin-bottom: 1rem;
    min-width: 40%;
    background-color: transparent;
    font-size: 1rem;
  }
  &-project {
    &-headers {
      border-bottom: 1px solid #a3a3a3;
      display: grid;
      grid-template-columns: 35% 20% 20% 20% 10%;
    }
    &-header {
      display: inline flex;
      flex-direction: row;
      align-items: center;

      gap: 0.5rem;
      padding: 0.5rem 0;
      color: $app-purple-strong;
      font-weight: 600;
      &.hover {
        cursor: pointer;
      }
    }
    &-entry {
      border-bottom: 1px solid #a3a3a3;
      display: grid;
      grid-template-columns: 35% 20% 20% 20% 5%;;
      cursor: pointer;
      &:hover {
        background-color: #0000000b;
        & .account-page-right-content-project-entry-icon {
          display: flex;
        }
      }
      &-cell {
        padding: 0.5rem 0;
      }
      &-icon {
        display: none;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
          & > svg {
            stroke: $app-purple-strong;
          }
        }
        &-group {
          display: flex;
          justify-content: flex-end;
          padding-right: 0.25rem;
          gap: 0.5rem;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    &-no-entry {
      display: flex;
      padding: 0.5rem 0;
      font-style: italic;
      color: #a3a3a3;
    }
  }
  @media (max-width: 1130px) {
    padding-top: 4rem;
    padding-left: 2rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem 1.25rem;
  }
}
