@import '../../assets/styles/index.scss';

.contact-card-group-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 4rem);
  padding: 2rem;
  &>.contact-card-container {
    width: calc(50% - 8.5rem);
    margin-bottom: 1rem;
  }
  @media (max-width: 768px) {
    &>.contact-card-container {
      width: 100%;
    }
    padding: 2rem 1.25rem;
    width: calc(100% - 2.5rem);
  }
}

.contact-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $app-yellow;
  border-radius: 0.75rem;
  padding: 3rem 4rem;
  &.no-background {
    background-color: unset;
  }
  @media (max-width: 500px) {
    padding: 1rem 1rem;
  }
}