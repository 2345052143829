@import '../../../assets/styles/index.scss';
.custom-button {
  &-container {
    padding: 20px 40px;
    border-radius: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #999999;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.primary {
      background-color: black !important;
      border-color: black !important;
      color: white;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.3) !important;
          z-index: 1;
        }
        &:hover {
          border-color: rgba(255, 255, 255, 0.3) !important;
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &.selected {
      background-color: black !important;
      border-color: black !important;
      color: white;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.3) !important;
          z-index: 1;
        }
        &:hover {
          border-color: rgba(255, 255, 255, 0.3) !important;
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &.danger {
      background-color: #fa4141 !important;
      border-color: #fa4141 !important;
      color: white !important;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.3) !important;
          z-index: 1;
        }
        &:hover {
          border-color: rgba(255, 255, 255, 0.3) !important;
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &.other-spaces {
      border: none;
    }
    &.default-space {
      border: none;
    }
    &.yellow {
      background-color: $app-yellow;
      border-color: $app-yellow;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.3);
          z-index: 1;
        }
        &:hover {
          border-color: rgba(255, 255, 255, 0.3);
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &.turquoise {
      background-color: $app-turquoise;
      border-color: $app-turquoise;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.3);
          z-index: 1;
        }
        &:hover {
          border-color: rgba(255, 255, 255, 0.3);
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &.purple {
      background-color: $app-purple;
      border-color: $app-purple;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.3);
          z-index: 1;
        }
        &:hover {
          border-color: rgba(255, 255, 255, 0.3);
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &.white {
      background-color: $app-white;
      border-color: $app-white;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #f9f8f5;
          z-index: 1;
        }
        &:hover {
          border-color: #f9f8f5;
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &.transparent {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid #000;
      &:hover {
        filter: brightness(0.935);
        background-color: black !important;
        color: $app-white !important;
      }
    }
    &.disabled {
      pointer-events: none;
      background-color: #e1e1e1 !important;
      border-color: #e1e1e1 !important;
      color: #a3a3a3 !important;
    }
    &.info-box {
      padding: 1.25rem 2.75rem;
      margin-top: 2rem;
      @media (max-width: 768px) {
        margin-top: 0rem;
      }
      @media (max-width: 500px) {
        padding: 15px 25px;
      }
    }
    & .custom-button-content {
      display: inline flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-size: 0.875rem !important;
      font-family: 'DM Mono';

      &.references-filter-btn{
        font-size: 0.875rem !important;
        line-height: 1.5rem !important;
      }
      &.blogs-filter-btn {
        font-size: 0.875rem !important;
        line-height: 1.5rem !important;
      }
      &.products-filter-btn {
        font-size: 0.875rem !important;
        line-height: 1.5rem !important;
      }
      &.send-btn {
        font-size: 1.25rem !important;
        line-height: 1.6275rem !important;
        font-weight: 500;
        @media (max-width: 500px) {
          font-size: calc(0.75rem + 1.5vw) !important;
          line-height: calc(0.75rem + 4vw) !important;
        }
      }
      &.info-box {
        font-size: 1.25rem !important;
        line-height: 1.6275rem !important;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 1rem !important;
          line-height: normal !important;
        }
      }
      &.search {
        font-size: 1.125rem !important;
        line-height: 1.4625rem !important;
        font-weight: 500;
      }
      &.login {
        font-size: 1.125rem !important;
        line-height: 1.4625rem !important;
        font-weight: 500;
      }
      &.project-header {
        font-family: "DM Mono" !important;
        font-size: 1.125rem !important;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 0.875rem !important;
        }
      }
      &.project-filter {
        font-family: "DM Mono" !important;
        font-size: 0.875rem !important;
        font-weight: 500;
      }
      &.product-overview {
        font-family: "DM Mono" !important;
        font-size: 1rem !important;
        font-weight: 500;
      }
      & .fa-layers {
        & .fa-circle {
          color: rgb(185, 185, 185);
        }
      }
    }

  }
}
