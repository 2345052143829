@import '../../assets/styles/index.scss';

.products-page {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 5rem);
    padding-bottom: 2rem;
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
    }
  }
  &-filter {
    border-radius: 2rem;
    padding: 0.25rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    font-size: 0.875rem !important;
    @media (hover: hover) {
      &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f9f8f5;
        z-index: 1;
        border-radius: 2rem;
      }
      &:hover {
        border-color: #f9f8f5;
      }
      // Ensure text and content remain above the pseudo-element
      & > * {
        position: relative;
        z-index: 1;
      }
    }
    &-container {
      position: relative;
    }
    &-separator {
      width: 1px;
      height: 1.5rem;
      background-color: black;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &.selected {
      color: white;
      background-color: black;
      @media (hover: hover) {
        &:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.3);
          z-index: 1;
          border-radius: 2rem;
        }
        &:hover {
          border-color: rgba(255, 255, 255, 0.3);
        }
        // Ensure text and content remain above the pseudo-element
        & > * {
          position: relative;
          z-index: 1;
        }
      }
    }
    &-clear-btn {
      margin-left: 1rem;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &-groups {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0 2rem;
      
      border-bottom: 1px solid rgb(171, 171, 171);
      width: calc(100% - 4rem);
      font-size: 0.65rem !important;
      font-family: 'DM Mono';
      gap: 0.5rem;
      padding: 1.5rem 0rem;
      @media (max-width: 768px) {
        margin: 0 1.5rem;
        width: calc(100% - 3rem);
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-text {
      margin-right: 1rem;
      font-size: 0.875rem;
    }
    &-btn {
      &-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
        align-items: center;
      }
      &-with-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
  &-no-product-text {
    font-style: italic;
    padding: 2rem 4rem;
    @media (max-width: 768px) {
      padding: 2rem;
    }
  }
  &-service {
    &-group {
      &-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 4rem);
        padding: 2rem 2rem;
        &-items {
          padding: 0 8px;
            box-sizing: border-box;
        }
        @media (max-width: 768px) {
          padding: 2rem 1.25rem;
          width: calc(100% - 2.5rem);
        }
      }
      &-title {
        font-size: 2.75rem;
        font-family: 'Inter Tight';
        line-height: 110%;
        font-weight: 800;
        margin-bottom: 1rem;
        @media (max-width: 768px) {
          font-size: 1.875rem;
        }
      }
      &-custom-carousel {
        &-entry {
          border-radius: 1rem;
          display: flex;
          flex-direction: column;
          background-color: white;
          cursor: pointer;
          color: $app-black;
          text-decoration: none;
          &:hover .products-page-service-group-custom-carousel-entry-image-container {
            & img {
              transform: scale(1.05)
            }
          }
          &:hover .hover-underline-service-group::after {
            opacity: 1; // Fade in underline when container is hovered
          }
          &-image-container {
            width: 100%;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            background-color: #e7e7e7;
            overflow: hidden;
            & img {
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
              width: 100%;
              height: auto;
              object-fit: contain;
              display: block;
              transition: transform 0.5s ease;
            }
          }
          &-title {
            font-size: 1.375rem ;
            font-weight: 800;
            
            &-container {
              display: inline flex;
              padding: 1.5rem 1.25rem;
            }
          }
        }
      }
    }
  }
}

.hover-underline-service-group {
  position: relative;
  text-decoration: none; // No underline by default
  color: #000; // Text color
}

.hover-underline-service-group::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.5s ease; 
}