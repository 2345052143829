@import '../../assets/styles/index.scss';


.header-box {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 4rem);
    padding: 1rem 2rem;
    padding-bottom: 1.75rem;
    @media (max-width: 768px) {
      padding: 1rem 1.25rem;
      width: calc(100% - 2.5rem);
    }
  }
  &-body {
    &-container {
      width: 100%;
      max-width: 1600px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 768px) {
        justify-content: flex-start;
      }
    }
  }
  &-title {
    &-container {
      width: calc(100% - 3rem);
      padding: 3.5rem 1.5rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      @media (max-width: 768px) {
        padding: 40px 20px;
        width: calc(100% - 40px);
      }
      & .header-box-page-text {
        font-size: 1rem;
        font-family: 'DM Mono';
        font-weight: 500;
        line-height: 1.3rem;
        margin-bottom: 0.25rem;
        @media (max-width: 768px) {
          font-size: 0.8125rem;
          line-height: normal;
        }
      }
      & .header-box-title-text {
        font-size: 4.625rem;
        line-height: 5.0875rem;
        font-family: 'Inter Tight';
        font-weight: 800;
        padding-bottom: 2rem;
        @media (max-width: 768px) {
          font-size: 2.5rem;
          line-height: 110%;
          padding-bottom: 0.5rem;
          overflow-wrap: break-word;
          hyphens: auto;
        }
      }
      &.purple {
        background-color: $app-purple;
      }
      &.turquoise {
        background-color: $app-turquoise;
      }
      &.yellow {
        background-color: $app-yellow;
      }
      &.contact-page {
        padding-left: unset;
        padding-bottom: 1.5rem;
        padding-top: 1rem;
      }
      &.product-page {
        padding-left: unset;
        padding-bottom: 0rem;
        padding-top: 1rem;
      }
    }
  }
  &-image {
    &-container {
      width: calc(100vw - 4rem);
      max-width: calc(100%);
      height: 100%;
      display: inline-block;
      @media (max-width: 768px) {
        width: 100%;
        height: calc(100vw - 3.5rem + 1px);
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        &.contact-page {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
        &.work-page {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
        &.product-page {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          max-height: 700px;
          object-fit: cover;
        }
      }
    }
  }
  &-video {
    &-container {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-bottom: 2rem;
      width: 100%;
    }
    &-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 56.25%;
      overflow: hidden;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      @media (max-width: 768px) and (orientation: portrait) {
        width: calc(100vw - 2.5rem);
        height: calc(100vw - 2.5rem);
        padding-top: unset;
        & iframe {
          width: calc(100vw * (16 / 9) - 5rem) !important;
          margin-left: -38%;
        }
      }
    }
    &-player {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
    &-loader {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.115);
    }
  }
}