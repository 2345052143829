@import '../../../assets/styles/index.scss';

.custom-quill-input {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.55);
    align-items: center;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 1rem 0rem;
  }
  &-wrapper {
    width: calc(60% + 2rem + 6px);
    &.en {
      display: none;
    }
  }
}