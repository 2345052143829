@import '../../assets/styles/index.scss';


.innovation-media {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
    @media (max-width: 768px) {
      padding: 2rem 1.5rem;
      width: calc(100% - 3rem);
    }
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      width: 100%;
      max-width: 1600px;
      gap: 2rem;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
    }
  }
  &-left {
    display: flex;
    flex-direction: column;
    &-container {
      width: calc(75% - 4rem);
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 2rem;
      border-radius: 1rem;
      @media (max-width: 1200px) {
        width: calc(100% - 4rem);
      }
    }
    &-item {
      &-title {
        font-family: 'Inter Tight';
        font-weight: 800;
        font-size: 2.75rem;
        line-height: 3.025rem;
        padding-bottom: 2rem;
        overflow-wrap: break-word;
        hyphens: auto;
      }
      &-divider {
        height: 1px;
        background-color: rgba(#000000, 0.4);
        width: 100%;
        margin: 1.5rem 0;
      }
      &-text {
        font-family: 'Libre Franklin';
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      &-link {
        font-family: 'Libre Franklin';
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: $app-purple-strong;
        cursor: pointer;
        text-decoration: none;
        &>svg {
          opacity: 1;
          stroke:  $app-purple-strong !important;
          fill:  $app-purple-strong !important;
        }
        
      }
    }
  }
  &-right {
    &-container {
      width: calc(25% - 4rem);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $app-white;
      border-radius: 1rem;
      padding: 2rem;
      gap: 2rem;
      @media (max-width: 1200px) {
        width: calc(100% - 4rem);
      }

    }
    &-title {
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 1.875rem;
      line-height: 2.0625rem;
      text-align: center;
    }
    &-image {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 2rem;
        flex-wrap: wrap;
        &>img {
          display: block;
          width: 100%;
          max-width: 120px;
          height: auto;
        }
        @media (max-width: 1200px) {
          width: calc(100% - 4rem);
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }
}
