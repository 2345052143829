@import '../../assets/styles/index.scss';

.trust-mary-widget {
  &-container {
    background-color: #E8E2D5;
    padding-bottom: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-body {
    width: 100%;
    max-width: calc(1600px);
  }
}