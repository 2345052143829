@import '../../assets/styles/index.scss';

.work {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
    }
  }
  &-intro {
    &-container {
      display: flex;
      flex-direction: column;
      padding: 2rem 20rem;
      @media (min-width: 768px) and (max-width: 1102px)  {
        padding: 2rem 10rem;
      }
      @media (max-width: 768px) {
        padding: 2rem 1.25rem;
        width: calc(100% - 2.5rem);
        padding-bottom: 0rem;
      }
    }
    &-title {
      font-family: 'Libre Franklin';
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2.1rem;
      padding-bottom: 2rem;
      @media (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 140%;
      }
      &.big-font {
        font-weight: 800;
        font-size: 2.75rem;
        line-height: 3.025rem;
        font-family: 'Inter Tight';
        text-align: center;
        @media (max-width: 768px) {
          font-size: 2.25rem;
          line-height: 110%;
          padding-bottom: 0rem !important;
        }
      }
    }
    &-text {
      font-family: 'Libre Franklin';
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.75rem;
      @media (max-width: 768px) {
        font-size: 1.125rem;
        line-height: 140%;
      }
      &.text-center {
        text-align: center;
        @media (max-width: 768px) {
          text-align: left;
          padding-top: 30px;
          // font-size: 1.125rem;
          // line-height: 140%;
        }
      }
    }
    &-link {
      color: $app-purple-strong;
      font-family: "Libre Franklin";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-decoration: none;
    }
    &-highlight {
      font-family: 'Libre Franklin';
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      padding-bottom: 2rem;
      color: $app-purple-strong;
    }
  }
  &-card {
    &-wrapper {
      width: 75%;
      @media (min-width: 768px) and (max-width: 1100px)  {
        width: 85%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      padding: 2rem 10rem;
      padding-top: 1rem;
      padding-bottom: 4rem;
      width: calc(100% - 20rem);
      @media (min-width: 768px) and (max-width: 1100px)  {
        padding: 2rem 5rem;
        width: calc(100% - 10rem);
      }
      @media (max-width: 768px) {
        padding: 2rem 1.25rem;
        width: calc(100% - 2.5rem);
      }
    }
    &-item {
      &-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border-top: 1px solid rgba(0, 0, 0, 0.40);
        @media (max-width: 768px) {
          flex-direction: column;
          padding-bottom: 1rem;
        }
        &.last-item {
          border-bottom: 1px solid rgba(0, 0, 0, 0.40);
        }
      }
      &-left {
        &-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2rem 0rem;
          padding-top: 4rem;
          padding-right: 2rem;
          width: calc(50% - 2rem);
          @media (max-width: 768px) {
            width: 100%;
            padding-bottom: unset;
            padding-top: 2rem;
            padding-right: unset;
          }
        }
        &-title {
          font-family: 'Inter Tight';
          font-weight: 800;
          line-height: 2.0625rem;
          font-size: 1.875rem;
        }
      }
      &-right {
        &-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem 0rem;
          width: 50%;
          @media (max-width: 768px) {
            width: 100%;
            padding-bottom: unset;
          }
        }
        &-text {
          font-family: 'Libre Franklin';
          font-weight: 400;
          line-height: 1.75rem;
          font-size: 1.25rem;
          padding: 1rem 0rem;
          @media (max-width: 768px) {
            font-size: 1.125rem;
            line-height: 140%;
            padding-top: 0rem;
          }
        }
      }
    }
  }
  &-open-position {
    &-container {
      display: flex;
      flex-direction: column;
      padding: 0rem 10rem;
      width: calc(100% - 20rem);
      @media (min-width: 768px) and (max-width: 1102px)  {
        padding: 2rem 5rem;
        width: calc(100% - 10rem);
      }
      @media (max-width: 768px) {
        padding: 2rem 1.25rem;
        width: calc(100% - 2.5rem);
      }
    }
    &-wrapper {
      width: 100%;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-item {
      font-size: 1.125rem;
      line-height: 140%;
    }
  }
  &-employee {
    &-card {
      &-container {
        display: flex;
        flex-direction: column;
        padding: 2rem 10rem;
        padding-top: unset;
        width: calc(100% - 20rem);
        gap: 2rem;
        @media (min-width: 768px) and (max-width: 1100px)  {
          padding: 2rem 5rem;
          width: calc(100% - 10rem);
        }
        @media (max-width: 768px) {
          padding: 2rem 1.25rem;
          width: calc(100% - 2.5rem);
          gap: 1.25rem;
        }
        
      }
      &-item {
        &-container {
          display: flex;
          flex-direction: row;
          padding: 2rem;
          gap: 2rem;
          background-color: $app-white;
          border-radius: 1rem;
          @media (max-width: 900px) {
            flex-direction: column;
          }
        }
        &-left {
          &-container {
            width: 50%;
            @media (max-width: 900px) {
              width: 100%;
            }
          }
          &-image-wrapper {
            width: 100%;
            &>img {
              width: 100%;
              height: auto;
              min-height: 30rem;
              display: block;
              object-fit: cover;
              border-radius: 1rem;
            }
          }
        }
        &-right {
          &-container {
            display: flex;
            flex-direction: column;
            width: 50%;
            @media (max-width: 900px) {
              width: 100%;
            }
          }
          &-year {
            font-family: 'DM Mono';
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.14rem;
            padding-bottom: 1rem;
          }
          &-name {
            font-family: 'Inter Tight';
            font-weight: 800;
            font-size: 1.875rem;
            line-height: 2.0625rem;
            padding-bottom: 2rem;
            @media (max-width: 768px) {
              overflow-wrap: break-word;
              hyphens: auto;
            }
          }
          &-text {
            font-family: 'Libre Franklin';
            font-weight: 400;
            line-height: 1.75rem;
            font-size: 1.25rem;
            @media (max-width: 768px) {
              font-size: 1.125rem;
              line-height: 140%;
            }
          }
        }
      }
    }
  }
}
