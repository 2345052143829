@import '../../assets/styles/index.scss';

.innovation {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
      padding-bottom: 2rem;
    }
  }
  &-text {
    &-card {
      text-align: center;
      font-family: 'Inter Tight';
      font-weight: 800;
      line-height: 3.025rem;
      font-size: 2.75rem;
      padding: 2rem 10vw;
      @media (max-width: 768px) {
        font-size: 1.875rem;
        line-height: 110%;
        padding: 2rem 1.25rem;
        overflow-wrap: break-word;
        hyphens: auto;
      }
    }
  }
  &-card {
    &-container {
      display: flex;
      flex-direction: column;
      padding: 2rem 6rem;
      padding-top: 1rem;
      padding-bottom: 4rem;
      width: calc(100% - 12rem);
      @media (max-width: 768px) {
        padding: 2rem 1.5rem;
        width: calc(100% - 3rem);
      }
    }
    &-item {
      &-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border-top: 1px solid rgba(0, 0, 0, 0.40);
        @media (max-width: 768px) {
          flex-direction: column;
          padding-bottom: 1rem;
        }
        &.last-item {
          border-bottom: 1px solid rgba(0, 0, 0, 0.40);
        }
      }
      &-left {
        &-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2rem 0rem;
          padding-top: 4rem;
          width: 50%;
          @media (max-width: 768px) {
            width: 100%;
            padding-bottom: unset;
            padding-top: 2rem;
          }
        }
        &-icon-wrapper {
          padding: 1rem;
          background-color: $app-turquoise;
          border-radius: 100%;
          margin-right: 1rem;
          &>img {
            width: 2.5rem;
            height: 2.5rem;
            display: block;
          }
        }
        &-title {
          font-family: 'Inter Tight';
          font-weight: 800;
          line-height: 2.0625rem;
          font-size: 1.875rem;
        }
      }
      &-right {
        &-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem 0rem;
          width: 50%;
          @media (max-width: 768px) {
            width: 100%;
            padding-bottom: unset;
          }
        }
        &-bullet {
          &-dot {
            padding: 2px;
            border-radius: 4px;
            background-color: black;
            margin-top: calc(0.745rem + 1rem);
            margin-right: 0.5rem;
          }
          &-container {
            display: flex;
            flex-direction: row;
          }
          &-text {
            font-family: 'Libre Franklin';
            font-weight: 400;
            line-height: 1.75rem;
            font-size: 1.25rem;
            padding: 1rem 0rem;
          }
        }
      }
    }
  }
}
