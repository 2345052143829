@import '../../assets/styles/index.scss';

.green-service {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 5rem);
    padding-bottom: 2rem;
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1600px;
      width: 100%;
    }
  }
  &-link {
    &-container {
      text-align: center;
      padding: 2rem 20vw;
      @media (max-width: 768px) {
        padding: 2rem 1.5rem;
      }
    }
  }
}
