@import '../../../assets/styles/index.scss';

.custom-image {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1.25rem;
    }
  }
  &-wrapper {
    width: 100%;
    &>img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 1rem;
    }
    &-double {
      width: 50%;
      height: 100%;
      @media (max-width: 768px) {
        width: 100%;
      }
      &>img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        object-fit: cover;
      }
    }
  }
}

.custom-image-blog {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0rem;
    margin-top: unset;
    width: 100%;
    &-double {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      margin: 2rem 0rem;
    }
  }
  &-wrapper {
    width: 100%;
    &>img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 1rem;
    }
    &-double {
      width: 50%;
      height: 100%;
      &>img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        object-fit: cover;
      }
    }
  }
  &-text {
    font-size: 1.0625rem;
    font-family: 'Libre Franklin';
    font-style: italic;
    padding: 1rem 1rem;
    padding-bottom: unset;
    @media (max-width: 768px) {
      font-size: 1.125rem !important;
      line-height: 140% !important;
    }
  }
  &-title {
    font-size: 2.75rem;
    font-weight: 800;
    line-height: 3.025rem;
    font-family: 'Inter Tight';
    padding: 1rem 1rem;
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 110% !important;
    }
  }
}