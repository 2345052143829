@import '../../../../../assets/styles/index.scss';

.customize-reference {
  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh + 420px);
    width: calc(100vw - 1rem);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.30);
  }
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    margin-bottom: 2rem;
    position: relative;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-height: calc(100vh - 5rem);
      max-width: 1600px;
      width: 100%;
      &.center {
        justify-content: center;
      }
    }
  }
  &-header {
    &-language {
      padding: 0.5rem 2rem;
      border: 1px solid black;
      border-bottom: unset;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 0.5rem;
        border-right: unset;
      }
      &:last-child {
        border-top-right-radius: 0.5rem;
        border-left: unset;
      }
      &-selector {
        display: flex;
        flex-direction: row;
      }
      &-wrapper {
        width: calc(100% - 6rem);
        padding: 0rem 3rem;
      }
      &.active {
        background: $app-purple-strong;
        color: white;
        border-color: $app-purple-strong;
      }
    }
    &-title {
      &-en {
        display: none;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    &-text {
      font-size: 1rem;
      line-height: 1.3rem;
      font-family: 'DM Mono';
      font-weight: 500;
      &-container {
        display: flex;
        flex-direction: row;
        width: calc(100% - 4rem);
        padding: 3rem 2rem;
        padding-top: unset;
        padding-bottom: unset;
        min-height: 32vh;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }
    &-title {
      &-text {
        font-size: 4.625rem !important;
        line-height: 5.0875rem !important;
      }
    }
    &-type {
      &-text {
        color: $app-purple-strong;
        font-size: 0.875rem;
        line-height: 1.139rem;
        font-weight: 500;
        font-family: 'DM Mono';
      }
    }
    &-left {
      &-button-wrapper {
        width: 100%;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(50% - 4rem);
        padding: 1.5rem 2rem;
        background-color: $app-white;
        border-top-left-radius: 1rem;
        @media (max-width: 768px) {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          width: calc(100% - 4rem);
        }
      }
      &-tag {
        &-container {
          display: flex;
          align-items: flex-end;
          gap: 1rem;
        }
      }
      &-product {
        margin-top: 2rem;
      }
      &-save {
        &-button {
          &-container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1rem 2rem;
            border-radius: 3rem;
            background: $app-yellow;
            color: $app-black;
            cursor: pointer;
            font-family: 'DM Mono';
            font-size: 1rem;
            gap: 1rem;
            @media (hover: hover) {
              &:hover::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(255, 255, 255, 0.3);
                z-index: 1;
              }
              &:hover {
                border-color: rgba(255, 255, 255, 0.3);
              }
              // Ensure text and content remain above the pseudo-element
              & > * {
                position: relative;
                z-index: 1;
              }
            }
            &.disabled {
              background-color: #e1e1e1 !important;
              border-color: #e1e1e1 !important;
              color: #a3a3a3 !important;
              pointer-events: none;
            }
          }
        }
      }
    }
    &-right {
      &-container {
        display: flex;
        flex-direction: column;
        width: calc(50% - 6rem);
        padding: 3rem 2rem;
        padding-right: 4rem;
        background-color: $app-white;
        border-top-right-radius: 1rem;
        @media (max-width: 768px) {
          border-radius: unset;
          width: calc(100% - 4rem);
          padding: 2rem 2rem;
          padding-top: unset;
        }
      }
    }
    &-body {
      &-text {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 400;
        font-family: 'Libre Franklin';
      }
      &-en {
        display: none;
      }
    }
    &-image {
      // &-container {
      //   display: flex;
      //   width: calc(100% - 4rem);
      //   margin-bottom: 0.5rem;
      //   &>img {
      //     width: 100%;
      //     height: auto;
      //     object-fit: contain;
      //     border-bottom-left-radius: 1rem;
      //     border-bottom-right-radius: 1rem;
      //   }
      // }
      &-wrapper {
        width: calc(100% - 4rem);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.50);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}