
.your-selection {
  &-page {
    &-container {
      display: flex;
      width: 100%;
      background-color: #ffe200;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
    }
    &-body {
      &-container {
        display: flex;
        width: calc(100% - 8rem);
        max-width: calc(1600px - 8rem);
        padding: 4rem 4rem;
        @media (max-width: 1024px) {
          flex-direction: column;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          width: calc(100% - 2rem);
          padding: 2rem 1rem;
        }
      }
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    width: 40%;
    // max-width: 500px;
    margin-right: 4rem;
    &-title {
      margin-bottom: 1rem;
      font-size: 2.75rem;
      line-height: 110%;
      font-weight: 800;
      font-family: 'Inter Tight';
    }
    &-description {
      text-align: justify;
    }
    @media (max-width: 1200px) {
      &-description {
        margin-bottom: 1rem;
      }
    }
    @media (max-width: 768px) {
      max-width: unset;
      &-title {
        font-size: 2.25rem;
      }
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
  &-cart {
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    padding-bottom: 3rem;
    min-width: 35rem;
    width: 60%;
    @media (max-width: 768px) {
      padding: 40px 30px;
      width: calc(100% - 60px);
      min-width: unset;
    }
    &-title {
      font-weight: 600;
      margin-bottom: 1rem;
      padding-top: 30px;
      font-size: 1.875rem;
      font-family: 'Inter Tight';
      @media (max-width: 768px) {
        padding-top: 0px;
      }
    }
    &-customization-entries {
      margin-bottom: 2rem;
    }
    &-customization-entry {
      padding: 0.5rem 0;
      display: grid;
      grid-template-columns: 17rem auto;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #a3a3a3;
        padding-bottom: 20px;
      }
      &-name {
        font-weight: 600;
      }
      &-value {
        font-weight: 600;
      }
    }
    &-actions {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
    &-btn {
      cursor: pointer;
      border-radius: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
      &.add-btn {
        background-color: black;
        color: white;
        margin-right: 1rem;
        font-family: 'DM Mono';
      }
      &.add-btn-disabled {
        background-color: rgba(0, 0, 0, 0.29);
        color: white;
        margin-right: 1rem;
        cursor: default;
        pointer-events: none;
      }
      &.inquiry-btn {
        background-color: #cca2f2;
        color: black;
        font-family: 'DM Mono';
        position: inherit;
      }
    }
  }
  &-customization {
    &-entry {
      &-name {
        font-family: 'DM Mono';
        font-size: 1.125rem;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 0.875rem;
          color: rgba(0, 0, 0, 0.60);
        }
      }
      &-value {
        font-family: 'DM Mono';
        font-size: 1.125rem;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 1.125rem;
        }
      }
      &-container {
        display: flex;
        flex-direction: column;
        &-value {
          // padding-left: 4rem;
          font-family: 'DM Mono';
          font-size: 1.125rem;
          font-weight: 500;
        }
      }
      &:has(.your-selection-customization-entry-value-no-selection) {
        color: #ff1c1c;
        cursor: pointer;
      }
    }
  }
}
