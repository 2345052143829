@import '../../assets/styles/index.scss';


.not-found {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10rem;
    padding-top: 10rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      gap: 2rem;
      max-width: calc(1600px);
    }
  }
  &-title {
    font-family: "Inter Tight";
    font-size: 44px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
  }
}