@import '../../assets/styles/index.scss';

.uuid-2533be24-689e-4b2f-8f4d-a0ff065657fa {
  stroke-width: 0px;
  &.white {
    fill: $app-white;
  }
  &.black {
    fill: $app-black;
  }
}