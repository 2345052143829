@import '../../assets/styles/index.scss';

.wlt-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 8rem);
  padding: 2rem;
  margin: 2rem;
  background-color: $app-white;
  border-radius: 1rem;
}

.wlt-card-title {
  font-size: 2.5rem !important;
  padding: 1rem;
  text-align: center;
}

.wlt-card-text {
  text-align: center;
  padding: 1rem 12vw;
  font-size: 0.9rem;
  &>a {
    font-family: Libre Franklin;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    padding: 1rem 5vw;
  }
}

.wlt-card-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  & img {
    width: 16rem;
    height: auto;
    object-fit: contain;
    border-radius: 0.8rem;
  }
}