@import '../../assets/styles/index.scss';

.text-card {
  &-group {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    border-radius: 1rem;
    width: 50%;
    background-color: $app-white;
    @media (max-width: 1300px) {
      padding: 2rem 1.5rem;
      width: 65%;
    }
    @media (max-width: 1000px) {
      padding: 2rem 1.5rem;
      width: 80%;
    }
    @media (max-width: 768px) {
      padding: 2rem 1.5rem;
      width: calc(100% - 3rem);
    }
    &-title {
      font-weight: 800;
      font-family: 'Inter Tight';
      font-size: 1.875rem;
      line-height: 2.0625rem;
      margin-bottom: 1rem;
      @media (max-width: 768px) {
        overflow-wrap: break-word;
        hyphens: auto;
      }
    }
    &-text {
      font-weight: 400;
      font-family: 'Libre Franklin';
      font-size: 1.25rem;
      line-height: 140%;
      @media (max-width: 768px) {
        font-size: 1.125rem;
        line-height: 140%;
      }
      &-container {
        width: 100%;
        max-height: 80px;
        overflow: hidden;
        &.show {
          max-height: unset;
        }
      }
      & > a {
        color: $app-purple-strong;
        font-weight: 500;
        text-decoration: none;
      }
    }
    &-link {
      margin-top: 1rem;
      font-weight: 600;
      font-family: 'Libre Franklin';
      font-size: 1.25rem;
      line-height: 1.75rem;
      cursor: pointer;
    }
    &-container {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      padding-bottom: 4rem;
      width: calc(100% - 4rem);
      align-items: center;
      gap: 1rem;
      @media (max-width: 768px) {
        padding: 2rem 1.5rem;
        width: calc(100% - 3rem);
      }
    }
  }
}