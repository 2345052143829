@import '../../assets/styles/index.scss';

.well-being {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
    }
  }
  &-app {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      width: calc(100% - 4rem);
      @media (max-width: 768px) {
        padding: 2rem 1.5rem;
        width: calc(100% - 3rem);
      }
    }
    &-title {
      font-size: 3.625rem;
      font-weight: 800;
      font-family: 'Inter Tight';
      line-height: 3.9875rem;
      margin-bottom: 1rem;
      @media (max-width: 425px) {
        font-size: calc(2.625rem + 3vw);
        line-height: calc(2.9875rem + 3vw);
      }
    }
    &-text {
      font-size: 1.5rem;
      font-weight: 600;
      font-family: 'Libre Franklin';
      line-height: 2.1rem;
      margin-bottom: 2rem;
    }
    &-body {
      &-container{
        position: relative;
        display: flex;
        padding: 3rem 2rem;
        background-color: $app-white;
        border-radius: 1rem;
        width: calc(100% - 4rem);
        gap: 2rem;
        flex-direction: row;
        @media (max-width: 1000px) {
          flex-direction: column;
        }
      }
      &-left {
        &-container {
          display: flex;
          flex-direction: column;
          width: 50%;
          @media (max-width: 1000px) {
            width: 100%;
          }
        }
        &-image {
          &-container {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            width: calc(100% - 3rem);
            padding-right: 3rem;
            @media (max-width: 768px) {
              width: calc(100%);
              padding-right: 0rem;
            }
          }
          &-wrapper {
            width: calc(50%);
            &>img {
              width: 100%;
              height: auto;
              display: block;
              object-fit: contain;
            }
          }
        }
        &-bullet {
          &-container {
            display: flex;
            flex-direction: column;
            padding-right: 3rem;
            @media (max-width: 768px) {
              width: calc(100%);
              padding-right: 0rem;
            }
          }
          &-title {
            font-weight: 700;
            font-family: 'Libre Franklin';
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin: 2rem 0rem;
          }
          &-text {
            font-weight: 400;
            font-family: 'Libre Franklin';
            font-size: 1.25rem;
            line-height: 1.75rem;
            &-container {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
            }
            &-bullet {
              padding: 2px;
              margin-top: 0.75rem;
              background-color: black;
              border-radius: 4px;
              margin-right: 0.5rem;
            }
          }
        }
      }
      &-right {
        &-container {
          display: flex;
          flex-direction: column;
          width: 50%;
          @media (max-width: 1000px) {
            width: 100%;
          }
        }
        &-title {
          font-weight: 800;
          font-family: 'Inter Tight';
          font-size: 1.875rem;
          line-height: 2.0625rem;
          margin-bottom: 1.5rem;
        }
        &-text {
          font-weight: 400;
          font-family: 'Libre Franklin';
          font-size: 1.25rem;
          line-height: 1.75rem;
          margin-bottom: 2rem;
          &-link {
            font-weight: 600;
            font-family: 'Libre Franklin';
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: $app-purple-strong;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &-input-container {
          display: flex;
          flex-direction: column;
          margin-top: 1rem;
          &>input {
            padding: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid rgb(192, 192, 192);
            font-size: 1.05rem;
            font-family: 'Libre Franklin';
            &:focus {
              outline: none;
            }
          }
          &-row{
            margin: 1rem 0rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            &>input {
              margin-right: 0.5rem;
              width: 1rem;
              height: 1rem;
            }
          }
        }
        &-btn-container {
           width: 16rem;
           margin-top: 1rem;
        }
      }
    }
    &-text-card {
      &-container {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        padding-bottom: 4rem;
        width: calc(100% - 4rem);
        align-items: center;
        gap: 1rem;
        @media (max-width: 768px) {
          padding: 2rem 1.5rem;
          width: calc(100% - 3rem);
        }
      }
      &-group {
        display: flex;
        flex-direction: column;
        padding: 3rem 2rem;
        border-radius: 1rem;
        width: 50%;
        background-color: $app-white;
        @media (max-width: 1300px) {
          padding: 2rem 1.5rem;
          width: 65%;
        }
        @media (max-width: 1000px) {
          padding: 2rem 1.5rem;
          width: 80%;
        }
        @media (max-width: 768px) {
          padding: 2rem 1.5rem;
          width: calc(100% - 3rem);
        }
        &-title {
          font-weight: 800;
          font-family: 'Inter Tight';
          font-size: 1.875rem;
          line-height: 2.0625rem;
          margin-bottom: 1rem;
        }
        &-text {
          font-weight: 400;
          font-family: 'Libre Franklin';
          font-size: 1.25rem;
          line-height: 1.75rem;
          max-height: 120px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          &.type-2 {
            max-height: 200px;
            -webkit-line-clamp: 6;
            line-clamp: 6;
          }
          &.show {
            max-height: unset;
            -webkit-line-clamp: unset;
            line-clamp: unset;
          }
        }
        &-link {
          margin-top: 1rem;
          font-weight: 600;
          font-family: 'Libre Franklin';
          font-size: 1.25rem;
          line-height: 1.75rem;
          cursor: pointer;
        }
      }
    }
  }
}
.well-being-app-body-error {
  &-container {
    margin: 0 0 1rem;
    align-self: flex-start;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}