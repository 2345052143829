@import "../../assets/styles/index.scss";


.customization {
  &-page {
    &-inquiry {
      &-title {
        font-family: "Inter Tight";
        font-size: 58px;
        font-weight: 800;
        line-height: 110%;
        padding-bottom: 40px;
      }
      &-container {
        display: flex;
        width: 100%;
        background-color: #ffe200;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
      }
      &-body {
        &-container {
          display: flex;
          flex-direction: column;
          width: calc(100% - 8rem);
          max-width: calc(1600px - 8rem);
          padding: 4rem 4rem;
          @media (max-width: 768px) {
            flex-direction: column;
            width: calc(100% - 2rem);
            padding: 2rem 1rem;
          }
        }
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      background-color:  #E8E2D5;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 3rem;
    }
    &-body {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1600px;
      }
    }
    &-title {
      padding: 2rem 2rem;
      padding-top: 4rem;
      padding-left: 4rem;
      font-size: 3.625rem;
      line-height: 110%;
      font-weight: 800;
      font-family: 'Inter Tight';
      text-align: left;
      width: calc(100% - 6rem);
      @media (max-width: 768px) {
        padding: 1rem 0;
        padding-top: 3rem;
        font-size: 2.25rem;
        width: calc(100% - 2.5rem);
      }
    }
    &-divider {
      width: calc(100% - 4rem);
      height: 1px;
      background-color: #a3a3a3;
    }
  }
  &-entry {
    &-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 4rem 2rem;
      padding-left: 4rem;
      width: calc(100% - 6rem);
      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: unset;
        padding: 2rem 2rem;
      }
      @media (max-width: 768px) {
        padding: 4rem 1.25rem;
        width: calc(100% - 2.5rem);
      }
      & .mobile {
        display: none;
      }
      & > .mobile {
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }
    &-left {
      width: 30%;
      margin-right: 4rem;
      @media (max-width: 1024px) {
        width: 100%;
        margin-right: 0;
      }
    }
    &-right {
      width: 65%;
      display: flex;
      align-items: flex-end;
      overflow-x: auto;
      padding: 0 1rem;
      margin-top: 2.4rem;
      min-height: 10rem;
      &.align-start {
        align-items: flex-start;
      }
      @media (max-width: 1024px) {
        width: 100%;
        padding: 0;
      }
      &.desktop {
        @media (max-width: 768px) {
          display: none;
          
        }
      }
      &.mobile {
        display: none;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1.25rem;
        @media (max-width: 768px) {
          display: flex;
          & .customization-option {
            flex-basis: calc(50% - 0.625rem);
            max-width: calc(50% - 0.625rem);
            margin-right: 0rem !important;
          }
        }
      }
    }
  }
  &-carousel {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    width: 60%;
    max-width: 800px;
    & .carousel-slider {
      width: 80% !important;
    }
    @media (max-width: 1300px) {
      & .carousel-slider {
        width: 90% !important;
      }
      padding: 0;
      margin-right: 2rem;
    }
    @media (max-width: 1024px) {
      & .carousel-slider {
        width: 100% !important;
      }
      margin-left: 0rem;
      width: 80%;
      align-self: center;
    }
    @media (max-width: 768px) {
      margin-left: 0rem;
      margin-right: 0rem;
      width: 100%;
    }
    &.green-stop {
      max-width: 720px;
      & .control-dots {
        bottom: -2.75rem !important;
        left: 0;
      }
    }
    &-image {
      // height: calc(100% - 10rem);
      border-radius: 1.5rem;
      background-color: $app-white;
      overflow: hidden;
      &:not(:last-child) {
        margin-right: 2rem;
      }
      & img {
        height: 100%;
        width: auto;
        object-fit: cover;
        cursor: pointer;
        min-height: 20rem;
      }
      &.green-stop {
        height: calc(100%);
      }
    }
  }
  &-content {
    max-width: 32rem;
    width: 80%;
    & > .desktop {
      @media (max-width: 768px) {
        display: none;
      }
    }
    &-container {
      display: flex;
      width: 40%;
      @media (max-width: 768px) {
        margin-top: 3rem;
      }
    }
    @media (max-width: 1024px) {
      max-width: unset;
      margin-top: 1rem;
      max-width: unset;
      width: 100%;
      &-container {
        width: calc(100%);
      }
    }
  }
  &-title {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    @media (max-width: 1024px) {
      margin: 1rem 0;
    }
  }
  &-description {
    margin-bottom: 1rem;
    white-space: pre-wrap;
    &-container {
      border-radius: 1rem;
      background-color: white;
      margin-bottom: 1.5rem;
      padding: 1.5rem;
    }
    &-link {
      font-weight: 600;
      cursor: pointer;
    }
  }
  &-options {
    display: flex;
    flex-direction: column;
    &.horizontal {
      flex-direction: row;
      display: flex;
    }
    &.gap {
      gap: 1rem;
    }
  }
  &-option {
    border-radius: 1rem;
    border: 1px solid #a3a3a3;
    // padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      & .customization-option-text {
        background-color: #f9f8f5 !important;
        &.type-1 {
          width: 100%;
          background-color: #eee9df !important;
          border-radius: 1rem;
        }
      }
      &.selected {
        & .customization-option-text {
          background-color: rgb(132 233 233) !important;
        }
      }
    }
    &-minus-icon {
      position: absolute;
      top: 14px;
      left: -50px;
      cursor: pointer;
      &>svg {
        stroke: $app-black;
        opacity: 50%;
      }
      &:hover {
        &>svg {
          opacity: 100%;
        }
      }
    }
    &-plus-icon {
      position: absolute;
      top: 14px;
      right: -50px;;
      cursor: pointer;
      &>svg {
        stroke: $app-black;
        opacity: 50%;
      }
      &:hover {
        &>svg {
          opacity: 100%;
        }
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 1rem;
      border: 1px solid rgb(170, 170, 170);
      background-color:  #E8E2D5;
      min-width: 8rem;
      max-width: 8rem;
      padding: 20px 28px;
      font-size: 1.25rem;
      font-family: 'Libre Franklin';
      font-weight: 600;
      margin-left: 3rem;
      @media (max-width: 768px) {
        margin-left: 0rem;
      }
      &>input {
        width: 3rem;
        text-align: center;
        font-size: 1.25rem;
        font-family: 'Libre Franklin';
        font-weight: 600;
        border: none;
        background-color:  #E8E2D5;
        text-align: right;
        outline: none;
        &:focus {
          outline: none;
        }
      }
      &>span {
        width: 3rem;
        margin-left: 0.25rem;
      }
    }
    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
      &-text {
        margin-left: 3rem;
        font-size: 1.25rem;
        font-family: 'Libre Franklin';
        font-weight: 600;
        padding: 1rem;
        @media (max-width: 768px) {
          margin-left: 0rem;
        }
      }
      
    }
    &.no-cursor {
      cursor: unset;
    }
    &.selected {
      background-color: rgb(132 233 233);
      border: 1px solid rgb(132 233 233);
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &-text {
      font-weight: 600;
      padding: 20px !important;
      
    }
    &.vertical {
      border: unset;
      flex-direction: column;
      padding: 0;
      align-items: unset;
      margin-right: 1.5rem;
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 1.5rem;
      }
      & .customization-option-button {
        padding: 1rem;
        &-container {
          display: flex;
          border-radius: 1rem;
          border: 1px solid grey;
          height: 100%;
          top: 0;
          left: 0;
          &.selected {
            border: 1px solid $app-turquoise;
            &:hover {
              background-color: $app-turquoise;
            }
          }
          &:hover {
            background-color: #eee9df;
          }
        }
      }
      & .customization-option-text {
        background-color: white;
        height: 2rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        display: flex;
        align-items: center;
        padding: 0 1rem;
      }
      &.selected {
        & .customization-option-text {
          background-color: rgb(132 233 233);
        }
      }
    }
  }
  &-image {
    &-container {
      position: relative;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      background-color: #e7e7e7;
      width: 100%;
      & img {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        width: 100%;
        height: auto;
        display: block;
      }
    }
    &-checkmark {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
  &-modal {
    &-content {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 2rem 4rem 2rem;
    }
  
    &-title {
      font-size: 4rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 3rem;
    }
    &-description {
      border-bottom: 1px solid #a3a3a3;
      padding-bottom: 2rem;
    }
    &-options-entry {
      display: flex;
      flex-direction: column;
      padding: 2rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid #a3a3a3;
      }
      &-img-container {
        align-self: center;
        border-radius: 50%;
        height: 30rem;
        width: 30rem;
        overflow: hidden;
        & img {
          width: inherit;
          height: inherit;
          border-radius: 50%;
        }
      }
      &-title {
        margin: 2rem 0;
        font-size: 2rem;
        font-weight: 600;
        align-self: center;
      }
    }
  }
  &-option {
    &-input {
      &-wrapper {
        display: flex;
        padding: 1.125rem 1.75rem;
        border-radius: 4rem;
        background-color: $app-white;
        align-items: center;
        gap: 0.5rem;
        &>input {
          width: 3rem;
          text-align: center;
          border-radius: 1rem;
          border: none;
          background-color:  $app-white;
          font-size: 1.25rem;
          font-family: 'Libre Franklin';
          &:focus {
            outline: none;
            background-color:  $app-white;
          }
        }
      }
      &-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        &>input {
          width: 8rem;
          padding: 1rem;
          text-align: center;
          border-radius: 1rem;
          border: 1px solid rgb(170, 170, 170);
          background-color:  #E8E2D5;
          font-size: 1.25rem;
          font-family: 'Libre Franklin';
          &:focus {
            outline: none;
            background-color:  #e7e4dd;
          }
          &:hover {
            background-color:  #e7e4dd;
          }
        }
        &>textarea {
          width: 28rem;
          height: 12rem;
          padding: 1rem;
          border-radius: 1rem;
          border: 1px solid rgb(170, 170, 170);
          background-color:  #E8E2D5;
          font-size: 1.25rem;
          font-family: 'Libre Franklin';
          &:focus {
            outline: none;
            background-color:  #e7e4dd;
          }
          &:hover {
            background-color:  #e7e4dd;
          }
        }
      }
      &-text {
        font-family: 'Libre Franklin';
        font-size: 1.25rem;
        font-weight: 600;
      }
      &-info {
        width: calc(100% - 2rem);
        padding: 1.5rem 1rem;
        background-color: $app-turquoise;
        margin-top: 0.5rem;
        border-radius: 1rem;
        text-align: center;
        font-family: 'Libre Franklin';
        font-size: 1.25rem;
        font-weight: 600;
        &-container {
          width: calc(100% - 1rem);
          display: flex;
          flex-direction: column;
          background-color: $app-turquoise;
          padding: 1.5rem 1rem;
          margin-top: 0.5rem;
          border-radius: 1rem;
          align-items: center;
          &-text {
            text-align: center;
            font-family: 'Libre Franklin';
            font-size: 1.25rem;
            font-weight: 600;
            &-small {
              text-align: center;
              font-family: 'Libre Franklin';
              font-size: 1.25rem;
              font-weight: 400;
              line-height: 1.75rem;
            }
          }
        }
      }
    }
  }
}
.control-dots {
  bottom: -50px !important;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.carousel-slider {
  border-radius: 1rem !important;
  overflow: visible !important;
  @media (max-width: 768px) {
    width: 35rem !important;
  }
}
.customArrowPrev {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: -50px;
    // color: rgb(150, 150, 150);
    &.green-stop {
      top: 0;
    }
    &.single-item {
      display: none;
    }
  }
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &>svg {
    stroke: $app-black;
    opacity: 30%;
  }
  &:hover {
    &>svg {
      opacity: 55%;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.customArrowNext {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    right: -50px;
    // color: rgb(150, 150, 150);
    &.green-stop {
      top: 0;
      right: -3.5rem;
    }
    &.single-item {
      display: none;
    }
  }
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &>svg {
    stroke: $app-black;
    opacity: 30%;
  }
  &:hover {
    &>svg {
      opacity: 55%;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.product-customization-custom-carousel-entry {
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0 1rem 0 0;
  cursor: pointer;
}

.hideBorderRadius {
  border-radius: 0 !important;
  overflow: hidden;
}