@import '../../../assets/styles/index.scss';

.custom-input {
  text-align: center;
  // padding: 0.25rem;
  font-size: 1rem;
  min-width: 120px;
  &-date {
    font-family: "DM Mono";
    font-weight: 500 !important;
    line-height: 1.3rem !important;
    font-size: 1rem !important;
    cursor: pointer;
    &.center {
      text-align: center;
    }
  }
  &-header-title {
    font-family: 'Inter Tight';
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 110%;
    margin-bottom: 1rem;
    overflow-wrap: break-word;
    hyphens: auto;
    cursor: pointer;
    &.center {
      text-align: center;
    }
    &-input {
      font-weight: 800;
      font-size: 2.5rem;
      font-family: 'Inter Tight';
      line-height: 110%;
      margin-bottom: 1rem;
      text-align: left;
      min-width: 300px;
      max-width:max-content;
      z-index: 4;
    }
  }
  &-slug {
    font-family: 'DM Mono';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3rem;
    cursor: pointer;
    margin: 1rem 0;
    min-width: 10rem;
    &.center {
      text-align: center;
    }
  }
  &-alt-img-text {
    font-family: 'Libre Franklin';
    font-size: 1rem;
    cursor: pointer;
  }
  &-caption {
    font-family: 'Libre Franklin';
    font-size: 1.0625rem;
    line-height: 1.4875rem;
    font-style: italic;
    cursor: pointer;
  }
  &-image-title {
    font-family: 'Inter Tight';
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 1.4875rem;
    cursor: pointer;
  }
  &-contact-name {
    font-size: 1.875rem;
    font-family: 'Inter Tight';
    font-weight: 800;
    line-height: 2.0625rem;
    cursor: pointer;
  }
  &-contact-title {
    font-size: 1rem;
    font-family: 'DM Mono';
    font-weight: 500;
    line-height: 1.3rem;
    color: $app-purple-strong !important;
    cursor: pointer;
  }
  &-info-box-title {
    font-family: 'Inter Tight';
    font-weight: 800;
    font-size: 3.625rem;
    line-height: 3.9875rem;
    cursor: pointer;
    margin-top: 2rem;
    overflow: hidden;
    &-input {
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 3.625rem;
      line-height: 3.9875rem;
      cursor: pointer;
      margin-top: 2rem;
      min-width: 125px;
    }
  }
  &-text {
    cursor: pointer;
    font-size: 1.0625rem;
    font-family: 'Libre Franklin';
    font-weight: 400;
    line-height: 1.4875rem;
  }
  &-text-area {
    text-align: left;
    width: 100%;
    height: 100px;
    font-family: 'Libre Franklin';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.1rem;
    margin-top: 2rem;
  }
  &-btn-text {
    display: inline-block;
    font-family: 'DM Mono';
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6275rem;
    background-color: $app-yellow;
    border-radius: 3rem;
    padding: 2rem 3rem;
    min-width: 140px;
    &-input {
      font-family: 'DM Mono';
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.6275rem;
      background-color: $app-yellow;
      border-radius: 3rem;
      padding: 2rem 3rem;
      min-width: 140px;
    }
  }
  &-btn-link {
    margin-top: 1rem;
    &-input {
      margin-top: 1rem;
      font-size: 1rem;
    }
  }

}