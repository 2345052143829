@import '../../../assets/styles/index.scss';

.custom-privacy-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-privacy-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 70%;
  padding-bottom: 3rem;
  max-height: 90dvh;
  @media (max-width: 768px) {
    width: 95%;
  }
}

.custom-privacy-modal {
  &-title {
    font-family: 'Inter Tight';
    font-size: 2rem !important;
    padding: 3rem 3rem 0;
    font-weight: 800;
    line-height: 110%;
    @media (max-width: 768px) {
      overflow-wrap: break-word;
      hyphens: auto;
    }
  }
  &-sub-title {
    font-family: 'Inter Tight';
    font-size: 1.5rem !important;
    padding: 1rem 3rem 2rem;
    font-weight: 600;

  }
  &-content {
    padding: 0rem 3rem 1rem;
    overflow: auto;
    max-height: 70vh;
    @media (max-width: 768px) {
      max-height: 40vh;
    }
  }
  &-btn-group {
    display: flex;
    align-items: center;
    padding: 1rem 3rem 0;
  }
}
.custom-privacy-modal-contact-entry {
  display: flex;
  align-items: center;
  &-label {
    margin-right: 0.5rem;
  }
  &-value {
    font-weight: 600;
  }
}
.custom-privacy-modal-section-separator {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 1rem 0;
}
.custom-privacy-modal-section-title {
  font-weight: 600;
  margin-bottom: 1rem;
}
.custom-privacy-modal-contact-section-entry {
  display: flex;
  align-items: center;
  &-label {
    margin-right: 0.5rem;
    font-weight: 600;
  }
}