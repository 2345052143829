@import '../../assets/styles/index.scss';


.meta-management {
  &-container {
    display: flex;
    width: 100%;
    padding-bottom: 2rem;
  }
  &-cell {
    &-header {
      font-weight: 800 !important;
      font-size: 0.975rem !important;
      font-family: 'Libre Franklin' !important;
    }
    &-inner-header {
      font-weight: 600 !important;
      font-size: 0.975rem !important;
      font-family: 'DM Mono' !important;
    }
    &-text {
      font-weight: 400 !important;
      font-size: 0.975rem !important;
      font-family: 'Libre Franklin' !important;
      &.description {
       vertical-align: top;
      }
    }
  }
}