@import '../../assets/styles/index.scss';

.admin-page {
  &-container {
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    // justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    overflow: auto;
    height: 100%;
    padding-top: 5rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      width: calc(100% - 4rem);
      padding: 0rem 2rem;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      max-width: calc(1600px - 4rem);
      width: calc(100%);
    }
  }
  &-tab {
    padding: 1rem 3rem;
    border-bottom: 2px solid $app-yellow;
    background: #f0f0f0;
    cursor: pointer;
    font-family: 'Libre Franklin';
    font-weight: 500;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.active {
      background: $app-purple-strong;
      color: white;
      border-bottom: 2px solid $app-purple-strong;
    }
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      width: 100%;
    }
  }
  &-content {
    width: 100%;
  }
}
