@import '../../../../assets/styles/index.scss';


.dashboard {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    @media (max-width: 1300px) {
      flex-direction: column;
    }
    &-left {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      @media (max-width: 1300px) {
        width: 100%;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 35%;
      @media (max-width: 1300px) {
        width: 100%;
      }
    }
  }
}


.pie-chart {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    @media (max-width: 1300px) {
      align-items: flex-start;
    }
  }
  &-content {
    display: flex;
  }
}