@import '../../assets/styles/index.scss';

.contact-inquiry-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 6rem;
  margin: 0rem 2rem;
  margin-bottom: 4rem;
  background-color: $app-white;
  border-radius: 1rem;
  width: calc(100% - 16rem);
  @media (max-width: 768px) {
    width: calc(100% - 5rem);
    padding: 2rem 1.25rem;
    margin: 0rem 1.25rem;
  }
}

.contact-inquiry-title {
  font-size: 2.75rem;
  line-height: 3.025rem;
  font-family: 'Inter Tight';
  font-weight: 800;
  padding-bottom: 1rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.875rem;
    line-height: 110%;
    overflow-wrap: break-word;
    hyphens: auto;
  }
  &.small {
    font-size: 2rem !important;
    line-height: 110%;
    text-align: center;
    padding-bottom: 0rem;
  }
}

.contact-inquiry-input-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0rem;
  & input {
    width: calc(100% - 1.5rem);
    border-radius: 0.35rem;
    border: 1px solid #bbbbbb;
    padding: 0.5rem 0.75rem;
    font-family: 'Libre Franklin';
    font-size: 1.0625rem;
  }
  & label {
    font-family: 'Libre Franklin';
    font-size: 1.0625rem;
    line-height: 1.4875rem;
  }
  & textarea {
    width: calc(100% - 1.5rem);
    border-radius: 0.35rem;
    border: 1px solid #bbbbbb;
    padding: 0.5rem 0.75rem;
    font-family: 'Libre Franklin';
    font-size: 1.0625rem;
    resize: none;
    height: 6rem;
    margin-bottom: 1.5rem;
  }
}
.contact-inquiry-error {
  &-container {
    margin: 0 0 1rem;
    align-self: flex-start;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}