.meta-edit-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.meta-edit-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 60%;
  max-height: 90dvh;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    width: 85%;
  }
}
.meta-edit-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &-title {
    font-family: 'Inter Tight';
    font-size: 3rem !important;
    margin-bottom: 1rem;
    font-weight: 800;
    line-height: 3rem;
    &.small {
      font-size: 2rem !important;
      line-height: 110%;
      text-align: center;
      padding-bottom: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }

  &-back-btn {
    cursor: pointer;
    font-weight: 600;
    margin-left: -4rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
}

.meta-edit-modal {
  &-saved {
    color: green;
    font-size: 1.5rem;
    font-family: 'Libre Franklin';
    font-style: italic;
    margin-bottom: 1rem;
  }
  &-content {
    padding: 0 2rem;
    overflow: auto;
  }
  &-input {
    &-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      &.row  {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 2rem;
      }
      &-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
      &-left {
        width: calc(50% - 0.5rem);
      }
      &-right {
        width: calc(50% - 0.5rem);
        &-image {
          &-wrapper {
            width: 100%;
            & > img {
              width: 100%;
              height: auto;
              object-fit: cover;
              display: block;
              cursor: pointer;
            }
          }
          &-placeholder {
            width: 100%;
            height: 256px;
            border: 2px dashed #ccc;
            color: gray;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      & > textarea {
        resize: none;
        height: 5em; /* Set a fixed height */
        min-height: 3em; /* Ensure it doesn't shrink below this */
      }
    }
    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
      font-family: 'DM Mono';
      &.row  {
        margin-bottom: 0rem;
        margin-left: 0.5rem
      }
    }
    &-field {
      border-radius: 0.5rem;
      border: 1px solid #a3a3a3;
      padding: 1rem 0.75rem;
      font-size: 1rem;
      font-family: 'Libre Franklin';
    }
  }
  &-btn-group {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 2rem;
    & .custom-button-container {
      margin-right: 1rem;
    }
  }
}


.edit-meta-information-error {
  &-container {
    margin: 0 0 1rem;
    align-self: flex-start;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}