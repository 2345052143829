@import '../../../assets/styles/index.scss';

.sidebar {
  &-menu {
    &-item {
      font-family: 'Libre Franklin';
      font-weight: 500;
    }
    &-open {
      cursor: pointer;
    }
    &-close {
      cursor: pointer;
    }
  }
}
