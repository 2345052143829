@import '../../assets/styles/index.scss';

.edit-space-modal {
  &-content {
    padding: 2rem;
    padding-top: 0;
  }
  &-title {
    font-size: 3rem !important;
    margin-bottom: 1.5rem;
    font-weight: 800;
    line-height: 3rem;
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }
  &-add-btn {
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
    width: fit-content;
  }
  &-new-space {
    &-container {
      display: flex;
      flex-direction: column;
      margin: 0 -2rem;
      height: 0;
      overflow: hidden;
      transition: all 300ms;
      opacity: 0;
      &.show {
        height: 12rem;
        opacity: 1;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
      }
    }
    &-content {
      padding: 0 2rem;
    }
    &-input {
      &-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }
      &-label {
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      &-field {
        border-radius: 0.5rem;
        border: 1px solid #a3a3a3;
        padding: 1rem 0.75rem;
        font-size: 1rem;
        &.textarea {
        }
      }
    }
    &-btn-group {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      padding: 0 2rem;
      & .custom-button-container {
        margin-right: 1rem;
        &:first-child {
          background-color: black;
          color: white;
          border: unset;
        }
        &:last-child {
          color: black;
        }
      }
    }
  }
  &-space-list {
    border-top: 1px solid #a3a3a3;
    margin: 0 -2rem;
  }
  &-space-entry {
    border-bottom: 1px solid #a3a3a3;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    &-drag-icon {
      padding-right: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a3a3a3;
      cursor: pointer;
    }
    &-name {
      flex-grow: 1;
      font-weight: 600;
    }
    &-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 15rem;
    }
    &-btn {
      cursor: pointer;
      margin-left: 1rem;
      &.rename {
        color: #a3a3a3;
      }
      &.delete {
        color: $app-purple;
      }
    }
  }
}
.rename-space-modal {
  &-content {
    padding: 0 2rem 1rem;
  }
  &-input {
    &-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &-field {
      border-radius: 0.5rem;
      border: 1px solid #a3a3a3;
      padding: 1rem 0.75rem;
      font-size: 1rem;
    }
  }
  &-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2rem 0;
    & .custom-button-container {
      margin-left: 1rem;
    }
  }
}

.delete-space-modal {
  &-content {
    padding: 0 2rem 1rem;

  }
  &-confirm-text {
    font-size: 1.2rem !important;
    margin-top: 1rem;
  }
  &-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2rem 0 0;
    & .custom-button-container {
      margin-left: 1rem;
    }
  }
}
.edit-space-error {
  &-container {
    margin: 0 0 1rem;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}