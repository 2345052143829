
.related-products {
  &-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      padding: 2rem  2rem;
      padding-bottom: 4rem;
    }
  }
  &-title {
    margin-bottom: 1rem;
    font-family: 'Inter Tight';
    font-size: 2.75rem;
    line-height: 110%;
    font-weight: 800;
    @media (max-width: 768px) {
      font-size: 1.875rem;
      line-height: 110%;
    }
  }
  &-entries {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
    & .product-container {
      margin: 0.5rem;
    }
    @media (max-width: 768px) {
      & .product-container {
        width: 100%;
        margin: 0.5rem 0;
      }
    }
  }
}
