@import '../../assets/styles/index.scss';


.user-table {
  &-container {
    display: flex;
  }
  &-action {
    &-btn {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.change-password-modal-root {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.change-password-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 40%;
  max-height: 90vh;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    width: 85%;
  }
}
.change-password-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &-title {
    font-size: 3rem !important;
    margin-bottom: 1.5rem;
    font-weight: 800;
    line-height: 3rem;
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }

  &-back-btn {
    cursor: pointer;
    font-weight: 600;
    margin-left: -4rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
}
.change-password-modal {
  &-content {
    padding: 0 2rem;
  }
  &-input {
    &-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &-field {
      border-radius: 0.5rem;
      border: 1px solid #a3a3a3;
      padding: 1rem 0.75rem;
      font-size: 1rem;
    }
  }
  &-btn-group {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem 2rem 0;
    & .custom-button-container {
      margin-right: 1rem;
      &:first-child {
        background-color: black;
        color: white;
        border: unset;
      }
      &:not(:first-child) {
        color: black;
      }
    }
  }
}
.user-table-role-dropdown {
  & .custom-dropdown-container {
    max-width: 8rem;
    max-height: 3rem;
  }
}
.change-password-modal-error {
  &-container {
    margin-bottom: 1rem;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}
