
.modal {
  position: relative;
  padding: 20px;
  max-width: calc(100vw - 100px - 10vw);
  max-height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    max-width: calc(100vw - 2.5rem);
    max-height: 85vh;
  }
  &-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  &-image {
    & img {
      display: block;
      max-width: 100%;
      transition: opacity 0.290s ease-in-out;
    }
    &.fade img {
      opacity: 0;
    }
    &-wrapper {
      display: flex;
      position: relative;
      &-prev-button {
        position: absolute;
        top: calc(50% - 25px);
        left: -60px;
        cursor: pointer;
        &.desktop {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
      &-next-button {
        position: absolute;
        top: calc(50% - 25px);
        right: -60px;
        cursor: pointer;
        &.desktop {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
  &-close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
  &-button {
    &-wrapper {
      display: flex;
      padding: 1rem 10rem;
      width: calc(100% - 20rem);
      justify-content: space-between;
      align-content: space-between;
      @media (min-width: 769px) {
        display: none;
      }
      @media (max-width: 768px) {
        padding: 1rem 15vw;
        width: calc(100% - 30vw);
      }
    }
  }
}
