@import '../../assets/styles/index.scss';


.blogs {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    padding-bottom: 2rem;
  }
  &-title {
    font-size: 4.625rem !important;
    line-height: 5.0875rem !important;
    font-family: 'Inter Tight';
    margin-bottom: 4rem;
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 2rem 0rem;
      background-color: $app-purple;
      padding-top: 6rem;
    }
    &-body {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: calc(100% - 4rem);
        max-width: calc(1600px - 4rem);
      }
    }
  }
  &-card {
    flex: 1 1 calc(30%);
    min-width: 350px;
    max-width: 31.75%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $app-white;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    &:hover {
      & .custom-card-image {
        & > img {
          transform: scale(1.05)
        }
      }
      & .custom-card-blog-text-container {
        & .custom-card-blog-text-wrapper {
          & .custom-card-blog-text-title {
            text-decoration: underline;
          }
        }
      }
    }
    @media (max-width: 1192px) {
      max-width: calc(50% - 1rem);
    }
    @media (max-width: 810px) {
      max-width: 100%;
    }
    @media (max-width: 424px) {
      min-width: 300px;
    }
    @media (max-width: 350px) {
      min-width: 250px;
    }
    &-body {
      &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 3rem;
        gap: 2rem;
        padding-bottom: 2rem;
        width: calc(100%);
        max-width: calc(1600px);
        &:has(.blogs-card:nth-last-child(-n+3)) {
          justify-content: flex-start;
        }
      }
      &-no-blogs {
        font-size: 1.5rem;
        font-style: italic;
        text-align: center;
        width: 100%;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0rem 2rem;
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);

      @media (max-width: 768px) {
        padding: 0rem 1.5rem;
        width: calc(100% - 3rem);
      }
    }
    &-edit-icon {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $app-white;
      padding: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-top-right-radius: 0.7rem;
      z-index: 99;
    }
  }
  &-filter {
    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;
      }
    }
    &-button {
      &-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        @media (max-width: 1038.9px) {
          flex-direction: column;
        }
        & .blogs-filter-btn-clear {
          max-width: 110px;
        }
      }
    }
    &-type {
      &-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }
    &-text {
      font-size: 0.875rem !important;
      font-family: 'DM Mono';
      padding-right: 1rem;
      @media (max-width: 1038.9px) {
        padding-right: 1rem;
      }
    }
  }
  &-pagination {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 4rem);
      max-width: calc(1600px - 4rem);
      margin-top: 1rem;
      @media (max-width: 500px) {
        flex-direction: column;
        gap: 2rem;
      }
    }
    &-btn {
      font-size: 1.375rem;
      line-height: 1.5125rem;
      font-family: 'Inter Tight';
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      cursor: pointer;
      &-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
      &-divider {
        border-left: 1px solid $app-black;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
        color: #A7A7A7;
      }
    }
    &-page {
      font-family: 'DM Mono';
      font-size: 1rem;
      line-height: 1.3rem;
      font-weight: 500;
    }
  }
}