@import '../../assets/styles/index.scss';


.app {
  &-navigation {
    &-container {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      justify-content: center;
      box-sizing: border-box;
      height: 4rem;
      min-height: 4rem;
      max-height: 4rem;
      position: fixed;
      top: 0;
      transition: top 0.3s;
      z-index: 1001;
      background-color: #f2f0e4;
      &.show {
        top: 0;
      }
      &.hide {
        top: -5rem;
      }
    }
    &-body-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: calc(1600px - 4rem);
      width: calc(100% - 4rem);
      padding: 0rem 2rem;
      position: relative;
      z-index: 10;
      @media (max-width: 768px) {
        padding: 0rem 1.25rem;
        width: calc(100% - 2.5rem);
      }
    }
    &-links {
      display: flex;
      align-items: center;
      &-user-btn {
        display: flex;
        align-items: center;
      }
      @media (max-width: 962px) {
        display: none;
      }
    }
    &-link {
      cursor: pointer;
      color: black;
      text-decoration: none;
      font-size: 1.0625rem !important;
      font-weight: 500 !important;
      font-family: 'Libre Franklin';
      white-space: nowrap;
      &:hover {
        color: $app-purple-strong;
      }
      &:not(:last-child) {
        margin-right: 2.5rem;
      }
      &.purple {
        color: $app-purple-strong;
      }
    }
    &-user-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 4.5rem;
      height: 0.9rem;
      border-radius: 2rem;
      padding: 0.5rem 1rem;
      background-color: $app-yellow;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 500;
      font-family: 'DM Mono';
      margin-right: 0.75rem;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &-menu {
      display: none;
      position: absolute;
      background-color: white;
      right: -2rem;
      top: 0;
      z-index: 1;
      width: calc(100% + 12.4rem);
      padding: 3rem 1rem;
      padding-top: 4.5rem;
      @media (max-width: 768px) {
        right: -20px;
        width: 100vw;
        padding: 3rem 0;
        padding-top: 4.5rem;
      }
      &.show {
        display: block;
        z-index: 2;
      }
      &-btn {
        &-account {
          background-color: $app-yellow;
          // width: 90px;
          max-width: 120px;
          text-align: center;
          padding: 1rem 2rem;
          border-radius: 2rem;
          margin-top: 2rem;
          margin-left: 1rem;
          font-family: 'DM Mono';
          font-size: 1.125rem;
          &:hover {
            cursor: pointer;
          }
        }
        &-sign-in {
          background-color: $app-white;
          border: 1px solid black;
          width: 170px;
          text-align: center;
          padding: 1rem 2rem;
          border-radius: 2rem;
          margin-top: 2rem;
          margin-left: 1rem;
          font-family: 'DM Mono';
          font-size: 1.125rem;
          &:hover {
            cursor: pointer;

          }
        }
      }
      &-divider {
        height: 1px;
        width: calc(100% - 2rem);
        margin: 0rem 1rem;
        background-color: rgba(0, 0, 0, 0.4);
      }
      &-icon {
        &-container {
          margin-top: 0.25rem;
          position: relative;
          z-index: 10;
        }
      }
      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        & .fa-bars {
          margin-left: 0.5rem;
        }
        & svg {
          
          cursor: pointer;
        }
        @media (min-width: 963px) {
          display: none;
        }
      }
      &-link {
        padding: 1rem;
        display: flex;
        cursor: pointer;
        color: black;
        text-decoration: none;
        font-size: 1.25rem;
        line-height: 1.25rem;
        &:hover {
          color: $app-purple-strong;
        }
      }
      &-sub {
        &-container {
          padding: 1rem;
          cursor: pointer;
          color: black;
          text-decoration: none;
          &:hover {
            color: $app-purple-strong;
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          max-height: 0px;
          overflow: hidden;
          max-height: 0;
          gap: 1rem;
          transition: max-height 0.2s ease-out, padding-top 0.15s ease-in-out 0.01s;
          &.show {
            max-height: 300px;
            padding-top: 1rem;
            height: auto;
          }
        }
        &-link {
          cursor: pointer;
          color: black;
          text-decoration: none;
          font-size: 1.0625rem;
          line-height: 1.0625rem;
          &:hover {
            color: $app-purple-strong;
          }
        }
        &-name {
          &-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 1.25rem;
            line-height: 1.25rem;
          }
        }
      }
    }
    &-sign-in {
      margin-right: 1rem;
      cursor: pointer;
      font-size: 0.9375rem !important;
      font-family: "DM Mono" !important;
      line-height: 2rem;
    }
  }
  &-logo {
    &-container {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 10;
      min-width: 150px;
      padding-top: 0.5rem;
      padding-right: 1rem;
      cursor: pointer;
      touch-action: manipulation;
      -webkit-tap-highlight-color: transparent; /* Removes tap highlight */
    }
  }
  &-user-links {
    display: flex;
    align-items: center;
    font-size: 1.0625rem !important;
    font-weight: 500 !important;
    font-family: 'Libre Franklin';
    padding-left: 1rem;
    @media (max-width: 962px) {
      display: none;
    }
    & > .custom-button-container {
      & > .custom-button-content {
        white-space: nowrap;
      }
    }
  }
  &-language {
    &-dropdown {
      margin-left: 0.5rem;
      position: relative;
      z-index: 10;
      @media (max-width: 962px) {
        margin-left: 0;
      }
      & > .custom-dropdown-container {
        & > .custom-dropdown-selected-container {
          padding-right: 0rem;
          @media (max-width: 962px) {
            padding-right: 1.5rem;
            padding-left: 0.75rem;
          }
        }
      }
    }
    &-user-separator {
      font-size: 1.0625rem !important;
      font-weight: 500 !important;
      font-family: 'Libre Franklin';
      @media (max-width: 962px) {
        display: none;
      }
    }
  }
  &-search-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // & svg {
    //   pointer-events: none; // Prevent pointer events on the icon itself
    // }
  }
}