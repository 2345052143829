.inquiries-row-state-button {
  white-space: nowrap;
}

.inquiries {
  &-row {
   &>td {
      border-bottom: unset;
    }
    &-inner-header {
      font-weight: 600 !important;
      font-size: 0.875rem !important;
      font-family: 'Libre Franklin' !important;
      // display: flex;
      // flex-direction: row;
      // gap: 1rem;
      // align-items: center;
    }
  }
  &-cell {
    &-text {
      font-weight: 400 !important;
      font-size: 0.975rem !important;
      font-family: 'Libre Franklin' !important;
      &.message {
       vertical-align: top;
      }
    }
  }
}