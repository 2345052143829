@import '../../assets/styles/index.scss';

.green-furniture {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 5rem);
    padding-bottom: 2rem;
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1600px;
    }
  }
  &-text {
    &-card {
      width: calc(100% - 4rem);
      text-align: center;
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 2.75rem;
      line-height: 3.025rem;
      padding: 2rem 0rem;
      @media (max-width: 768px) {
        width: calc(100% - 3rem);
      }
    }
  }
}
