@import '../../assets/styles/index.scss';

.contact-card-title {
  font-size: 2.75rem;
  line-height: 3.025rem;
  font-family: 'Inter Tight';
  font-weight: 800;
  margin-bottom: 1.5rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.875rem;
    line-height: 110%;
  }
}

.contact-card-phone {
 font-weight: 600;
 font-size: 1.5rem;
 line-height: 2.1rem;
 font-family: 'Libre Franklin';
 @media (max-width: 768px) {
  font-size: 1.25rem;
  line-height: 140%;
}
}

.contact-card-email {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-family: 'Libre Franklin';
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 140%;
  }
  &>a {
    color: $app-black;
    text-decoration: none;
  }
}

.contact-card-btn {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.465rem;
  font-family: 'DM Mono';
  background-color: $app-white;
  padding: 1.25rem 2.5rem;
  border-radius: 3rem;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: normal;
  }
  @media (hover: hover) {
    &:hover::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f9f8f5;
      z-index: 1;
      border-radius: 2rem;
    }
    &:hover {
      border-color: #f9f8f5;
    }
    // Ensure text and content remain above the pseudo-element
    & > * {
      position: relative;
      z-index: 1;
    }
  }
}

.contact-card-address {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 140%;
  }
}

.contact-card-identity-code {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
  margin-bottom: 1.25rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 140%;
  }
}

.contact-card-invoice-text {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 140%;
  }
}

.contact-card-invoice-address {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 140%;
    text-align: center;
  }
}

.contact-card-operator {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 140%;
    text-align: center;
  }
}

.contact-card-pdf-invoice {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 140%;
  }
}

.contact-card-pdf {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: 'Libre Franklin';
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 140%;
    text-align: center;
  }
  &>a {
    color: $app-purple-strong;
    font-family: "Libre Franklin";
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 1.125rem;
      line-height: 140%;
    }
  }
}