@import '../../assets/styles/index.scss';

.product-list-product {
  &-entry {
    border-bottom: 1px solid rgba(0, 0, 0, 0.40);
    display: flex;
    padding: 2rem 0rem;
    padding-top: 1rem;
    gap: 2rem;
    @media (max-width: 1100px) {
      flex-direction: column;
    }
    &.inquiry {
      @media (max-width: 1350px) {
        flex-direction: column;
      }
    }
    &-left-content {
      padding: 2rem 0;
      display: flex;
      width: 75%;
      @media (max-width: 1100px) {
        width: 100%;
      }
      &.inquiry {
        @media (max-width: 1350px) {
          width: 100%;
        }
      }
      &-adjust-button {
        padding: 6px 12px;
        border-radius: 4rem;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.40);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 6.625rem;
        min-width: 6.625rem;
        @media (max-width: 768px) {
          width: 5.5rem;
          min-width: 5.5rem;
        }
        &-value {
          font-size: 1.125rem;
          font-family: "DM Mono";
          font-weight: 500;
        }
        &-icon {
          display: flex;
          justify-content: center;
          cursor: pointer;
          &.disabled {
            cursor: default;
            color: grey;
          }
        }
      }
      &-card {
        border-radius: 2rem;
        background-color: white;
        display: flex;
        flex-direction: row;
        padding: 2rem;
        width: calc(100% - 4rem);
        @media (max-width: 1100px) {
          flex-direction: column;
          width: calc(100% - 4rem);
        }
        &.inquiry {
          background-color: $app-beige;
        }
        &-edit-btn {
          &-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            height: 100%;
            @media (max-width: 1100px) {
              align-items: flex-start;
              justify-content: flex-start;
            }
            & .custom-button-container {
              padding: 7px 22px;
              margin-bottom: 8px;
            }
          }
        }
        // flex-wrap: nowrap;
        &-overview {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-right: 2rem;
          @media (max-width: 1100px) {
            margin-right: 0rem
          }
          &-img {
            border-radius: 1rem;
            width: 310px;
            height: 211px;
            min-height: 5rem;
            object-fit: cover;
            @media (max-width: 1100px) {
             width: 100%;
             height: auto;
            }
          }
          &-button {
            margin-top: 3rem;
            @media (max-width: 1100px) {
              margin-top: 1rem;
            }
            @media (max-width: 500px) {
              margin-top: 30px;
            }
            & .custom-button-container {
              padding: 0.9375rem 1.875rem;
              margin: 0.5rem;
              font-size: 0.9rem;
              @media (max-width: 500px) {
                padding: 4px 14px;
                margin: 0;
                margin-bottom: 14px;
              }
            }
          }
        }
        &-details {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;
            
            width: 100%;
            @media (max-width: 1100px) {
              display: none;
            }
            &.mobile {
              display: none;
              @media (max-width: 1100px) {
                display: flex;
              }
              @media (max-width: 500px) {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: flex-start;
              }
            }
            &-title {
              font-family: 'Inter Tight';
              margin-right: 1rem;
              display: flex;
              flex-wrap: wrap;
              font-size: 1.875rem;
              line-height: 110%;
              font-weight: 800;
            }
            &-dropdown {
              & .custom-dropdown-container {
                border: none !important;
                background-color: $app-yellow !important;
                &.disabled {
                  background-color: #e1e1e1 !important;
                }
              }
              & .custom-dropdown-selected-container {
                padding: 6px 18px;
                @media (max-width: 500px) {
                  padding: 0px 14px;
                }
              }
              & .custom-dropdown-selected-content  {
                font-size: 0.875rem !important;
                white-space: nowrap;
              }
              & .custom-dropdown-menu {
                &-container {
                  width: auto;
                  left: 50%;
                  transform: translate(-50%, 100%);
                }
                &-entry {
                  padding: 0.75rem 1.5rem;
                  &-text {
                    white-space: nowrap;
                  }
              }

              }
            }
          }
          &-attributes {
            &-label {
              color: rgba(0, 0, 0, 0.60);
              margin: 0.5rem 0;
              font-size: 0.875rem;
              font-weight: 500;
              font-family: 'DM Mono';
              @media (max-width: 500px) {
               display: none;
              }
            }
            &-entry {
              padding: 1rem 0;
              display: flex;
              align-items: flex-start;
              @media (max-width: 1370px) {
                flex-direction: column;
                & .product-list-product-entry-left-content-card-details-attributes-entry-label {
                  width: calc(100% - 1rem);
                }
                & .product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper  {
                  width: calc(100% - 1rem);
                  margin-top: 6px;
                  font-family: "DM Mono";
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }
              &.inquiry {
                @media (max-width: 1660px) {
                  flex-direction: column;
                  & .product-list-product-entry-left-content-card-details-attributes-entry-label {
                    width: calc(100% - 1rem);
                  }
                  & .product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper  {
                    width: calc(100% - 1rem);
                    margin-top: 6px;
                    font-family: "DM Mono";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                }
              }
              @media (max-width: 1100px) {
                flex-direction: row;
              }
              @media (max-width: 768px) {
                flex-direction: column;
                & .product-list-product-entry-left-content-card-details-attributes-entry-label {
                  width: calc(100% - 1rem);
                  font-family: "DM Mono";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.60);
                }
                & .product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper  {
                  width: calc(100% - 1rem);
                }
              }
              &.viherpysakki {
                @media (max-width: 550px) {
                  flex-direction: column;
                  & .product-list-product-entry-left-content-card-details-attributes-entry-label {
                    width: calc(100% - 1rem);
                    font-family: "DM Mono";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.60);
                  }
                  & .product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper  {
                    width: calc(100% - 1rem);
                    font-family: "DM Mono";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 6px;
                  }
                }
              }
              &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.40);
              }
              &-label {
                @media (max-width: 1370px) {
                  width: calc(100% - 1rem);
                }
                .inquiry {
                  @media (max-width: 1660px) {
                    width: calc(100% - 1rem);
                  }
                }
                @media (max-width: 1100px) {
                  width: calc(50% - 1rem);
                }
                width: calc(40% - 1rem);
                padding-right: 1rem;
                font-size: 1.125rem;
                font-weight: 500;
                font-family: 'DM Mono';
              }
              &-value {
                font-size: 1.125rem;
                font-weight: 500;
                font-family: 'DM Mono';
                &.wrap {
                white-space: wrap;
                }
                &-wrapper {
                  @media (max-width: 1370px) {
                    width: 100%;
                    margin-top: 0.75rem;
                  }
                  .inquiry {
                    @media (max-width: 1660px) {
                      width: 100%;
                      margin-top: 0.75rem;
                    }
                  }
                  @media (max-width: 1100px) {
                    width: 50%;
                    margin-top: 0rem;
                  }
                  display: inline flex;
                  flex-direction: column;
                  width: 60%;
                  &.gap {
                    gap: 0.5rem;
                  }
                  &-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;
                    &>textarea {
                      width: 100%;
                      border-radius: 1rem;
                      padding: 1rem;
                      border: 1px solid rgba(0, 0, 0, 0.40);
                      background-color:  #E8E2D5;
                      font-size: 1.125rem;
                      font-family: 'DM Mono';
                      &:focus {
                        outline: none;
                        background-color:  #e7e4dd;
                      }
                      &:hover {
                        background-color:  #e7e4dd;
                      }
                    }
                  }
                }
                &-calculated {
                  width: calc(100% - 24px);
                  padding: 8px 12px;
                  margin-top: 0.25rem;
                  background: #E8E2D5;
                  text-align: center;
                  font-family: "DM Mono";
                  font-size: 1.125rem;
                  font-weight: 500;
                  border-radius: 7px;
                }
              }
            }
          }
        }
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
    }
    &-right-content {
      width: 25%;
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &.inquiry {
        @media (max-width: 1350px) {
          width: calc(100% - 2rem);
          padding: 1rem 1rem;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      @media (max-width: 1100px) {
        width: calc(100% - 2rem);
        padding: 1rem 1rem;
        flex-direction: row;
        justify-content: space-between;
      }
      // @media (max-width: 590px) {
      //   padding: 1rem 1rem;
      //   width: calc(100% - 4rem);
      // }
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 0rem;
        width: calc(100%);
      }
      &-title {
        display: none;
      }
      &-adjust-button {
        padding: 1.125rem 1.75rem;
        border-radius: 4rem;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 10rem;
        margin-bottom: 1rem;
        &.disabled {
          cursor: default;
          background-color: #e1e1e1 !important;
          color: #a3a3a3 !important;
          pointer-events: none;
        }
        @media (max-width: 500px) {
          width: calc(100% - 3.5rem);
        }
        &.inquiry {
          background-color: $app-beige;
        }
        &-value {
          font-size: 1.875rem;
          font-family: "Inter Tight";
          font-style: normal;
          font-weight: 800;
          line-height: 110%; 
        }
        &-icon {
          display: flex;
          justify-content: center;
          cursor: pointer;
          &.disabled {
            cursor: default;
            color: grey;
          }
        }
      }
      &-action {
        display: flex;
        flex-direction: column;
        &-link {
          font-family: 'Libre Franklin';
          font-weight: 600;
          font-size: 0.9375rem;
          cursor: pointer;
          text-decoration: underline;
          width: fit-content;
          margin-bottom: 0.5rem;
          &.copy {
            color: $app-purple-strong;
          }
          &.disabled {
            cursor: default;
            // background-color: #e1e1e1 !important;
            color: #a3a3a3 !important;
            pointer-events: none;
          }
        }
      }
    }
  }
}