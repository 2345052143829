@import '../../assets/styles/index.scss';

.login-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  &.hide {
    background-color: rgba(0, 0, 0, 0);
  }
}
.login-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 70%;
  max-width: 800px;
  max-height: 90dvh;
  @media (max-width: 768px) {
    width: 85%;
  }
}
.login-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &-title {
    font-family: 'Inter Tight';
    font-size: 3rem !important;
    margin-bottom: 1.5rem;
    font-weight: 800;
    line-height: 3rem;
    &.small {
      font-size: 2rem !important;
      line-height: 110%;
      text-align: center;
      padding-bottom: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 1.875rem !important;
      line-height: 2rem;
    }
  }

  &-back-btn {
    cursor: pointer;
    font-weight: 600;
    margin-left: -4rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
}

.login-modal {
  &-divider {
    width: calc(100% - 4rem);
    height: 1px;
    margin: 0 2rem;
    background-color: rgba(0, 0, 0, 0.3);
    @media (max-width: 768px) {
      width: calc(100% - 2.5rem);
      height: 1px;
      margin: 0 1.25rem;
    }
  }
  &-content {
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &-login {
    &-container {
      display: flex;
      flex-direction: column;
      width: calc(100% - 4rem);
      background-color: $app-white;
      border-radius: 1rem;
      padding: 2rem;
      padding-top: 0.5rem;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
        padding: 2rem 1.25rem;
      }
    }
    &-title {
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 3.625rem;
      line-height: 3.9875rem;
      margin-bottom: 2rem;
      @media (max-width: 768px) {
        font-size: 1.875rem !important;
        line-height: 2rem;
      }
    }
    &-input {
      &-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        & label {
          font-family: 'Libre Franklin';
          font-size: 1.0625rem;
          line-height: 1.4875rem;
        }
        & input {
          outline: none;
          border: 1px solid rgba(#000000, 0.3);
          padding: 0.5rem 0.75rem;
          border-radius: 0.3rem;
          font-size: 1.0625rem;
          font-family: 'Libre Franklin';
          line-height: 1.4875rem;
          &:focus {
            border-color: $app-purple !important;
            outline: 1px solid $app-purple;
          }
        }
      }
    }
    &-btn {
      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin: 2rem 0rem 1rem;
      }
      &-link {
        color: $app-purple-strong;
        font-size: 1.0625rem;
        font-family: 'Libre Franklin';
        line-height: 1.4875rem;
        font-weight: 600;
      }
    }
  }
  &-signup {
    &-container {
      display: flex;
      flex-direction: column;
      width: calc(100% - 4rem);
      padding: 2rem;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
        padding: 2rem 1.25rem;
      }
    }
    &-title {
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 3.625rem;
      line-height: 3.9875rem;
      margin-top: 0.75rem;
      margin-bottom: 2rem;
      @media (max-width: 768px) {
        font-size: 1.875rem !important;
        line-height: 2rem;
        margin-bottom: 1.5rem;
      }
      // @media (max-width: 500px) {
      //   font-size: calc(2.4rem + 4vw);
      //   line-height: calc(2.75rem + 4vw);
      // }

    }
    &-text {
      font-family: 'Libre Franklin';
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.75rem;
      @media (max-width: 768px) {
        font-size: 1.0625rem !important;
        line-height: 140%;
      }
    }
    &-button {
      &-wrapper {
        margin-top: 2rem;
      }
    }
  }
  &-loading {
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 2rem;
    }
  }
}

.login-modal-login-cookies-warning {
  font-style: italic;
  font-size: 0.9rem;
  &-link {
    margin-left: 0.2rem;
    color: $app-purple-strong;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.login-modal-error {
  &-container {
    margin: 0 0 1rem;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}