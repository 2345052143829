@import '../../assets/styles/index.scss';


.meta-management {
  &-row {
   &>td {
      border-bottom: unset;
    }
    cursor: pointer;
    &-cell {
      &-image {
        &-wrapper {
          width: 100%;
          & > img {
            width: 100%;
            height: auto;
            object-fit: cover;
            display: block;
          }
        }
      }
    }
    &-typography {
      &-text {
        font-size: 1.5rem !important;
        font-family: 'Inter Tight' !important;
        font-weight: 800 !important;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
      }
    }
  }
}