@import '../../assets/styles/index.scss';

.blog {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 5rem);
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1600px;
      width: 100%;
    }
  }
  &-header {
    &-link {
      font-size: 1rem !important;
      font-weight: 500 !important;
      color: rgb(107, 107, 107);
      font-family: 'DM Mono';
      width: calc(100% - 4rem);
      padding: 1rem 2rem;
      @media (max-width: 768px) {
        padding: 1rem 1.5rem;
        width: calc(100% - 3rem);
      }
      &>div {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &-text {
    &-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-bottom: 2rem;
      &.card {
        margin-bottom: unset;
        margin-top: 2rem;
      }
      & .ql-editor {
        padding: 12px 1.25rem;
        min-height: unset !important;
        &>ol {
          padding-left: unset;
          &>li {
            margin-bottom: 1rem;
            &>a {
              color: $app-purple-strong;
              text-decoration: none;
              &>span {
                font-weight: 600 !important;

              }
            }
          }
        }
        &>p {
          &>a {
            color: $app-purple-strong;
            text-decoration: none;
            &>span {
              font-weight: 600 !important;
            }
          }
        }
        &>p {
          &>em {
            &>a {
              color: $app-purple-strong;
              text-decoration: none;
              &>span {
                color: $app-purple-strong;
                text-decoration: none;
                font-weight: 600 !important;
              }
            }
          }
        }
        &>blockquote {
          border-left: 4px solid #ccc;
          margin: 0 0 1rem 0;
          padding-left: 1rem;
        }
        & .ql-quickSelection-heading {
          font-size: 2.75rem;
          font-family: 'Inter Tight' !important;
          &.ql-font-inter-tight {
            @media (max-width: 768px) {
              font-size: 1.875rem !important;
              line-height: 110% !important;
             }
          }
          &.ql-font-libre-franklin {
            @media (max-width: 768px) {
              font-size: 1.875rem !important;
              line-height: 110% !important;
             }
          }
        }
        & .ql-font-inter-tight {
          @media (max-width: 768px) {
            font-size: 1.875rem !important;
            line-height: 110% !important;
           }
        }
        & .ql-font-libre-franklin {
          & .ql-quickSelection-text {
            @media (max-width: 768px) {
              font-size: 1.125rem !important;
              line-height: 140% !important;
            }
          }
          @media (max-width: 768px) {
            font-size: 1.125rem !important;
            line-height: 140% !important;
          }
        }
        & .ql-quickSelection-text {
          @media (max-width: 768px) {
            font-size: 1.125rem !important;
            line-height: 140% !important;
          }
        }
        & .ql-quickSelection-card {
          @media (max-width: 768px) {
            font-size: 1.25rem !important;
            line-height: 140% !important;
          }
        }
      }
      @media (max-width: 1200px) {
        width: 75%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  &-image {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 40vw);
      &>img {
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 1rem;
      }
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
      }
    }
  }
  &-video {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 40vw);
      margin-bottom: 2rem;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
      }
    }
    &-wrapper {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
      width: 100%;
    }
    &-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &-contact {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 40vw);
      // padding: 0rem 40vw;
      // background-color: #edefee;
      @media (max-width: 768px) {
        width: calc(100% - 2.5rem);
        // padding: 0rem 25vw;
      }
    }
  }
}
