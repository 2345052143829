@import '../../../assets/styles/index.scss';



.custom-card {
  &-reference {
    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &-image {
      width: 100%;
      height: 100%;
      max-height: 350px;
      min-height: 350px;
      overflow: hidden;
      border-radius: 0.8rem;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      & >img {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 350px;
        object-fit: cover;

        transition: transform 0.5s ease;
      }
    }
    &-text {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem 1rem;
        padding-bottom: 2rem;
        width: calc(100% - 2rem);
        height: 100% !important;
      }
      &-type {
        font-family: 'DM Mono';
        margin-bottom: 0.5rem;
        font-size: 0.75rem !important;
      }
      &-title {
        font-family: 'Inter Tight';
        font-size: 2.75rem;
        line-height: 140%;
        font-weight: 800;
        overflow-wrap: break-word;
        hyphens: auto;
        @media (max-width: 768px) {
          font-size: 1.875rem;
          line-height: 110%;
        }
      }
    }
  }
  &-blog {
    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &-text {
      font-size: 1.0625rem !important;
      line-height: 1.4875rem !important;
      font-family: 'Libre Franklin' !important;
      font-weight: 400 !important;
      padding: unset;
      text-overflow: ellipsis;
      overflow: hidden;
      &> p {
        &>a {
          color: $app-purple-strong !important;
          text-decoration: none !important;
          &>span {
            font-weight: 600 !important;
          }
        }
      }
      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0.5rem 1.5rem;
        padding-bottom: 2rem;
        width: calc(100% - 3rem);
        height: 100% !important;
        width: calc(100% - 3rem);
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      &-title {
        display: inline-block;
        font-size: 1.875rem !important;
        line-height: 2.0625rem !important;
        font-family: 'Inter Tight';
        font-weight: 800;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
        @media (max-width: 768px) {
          overflow-wrap: break-word;
          hyphens: auto;
        }
      }
      &-date {
        font-family: 'DM Mono';
        margin-bottom: 0.5rem;
        font-size: 0.875rem !important;
        line-height: 1.139rem !important;
        font-weight: 500;
        margin-top: 1rem;
      }
    }
    &-link {
      display: flex;
      align-items: center;
      font-size: 1.0625rem !important;
      line-height: 1.4875rem !important;
      font-family: 'Inter Tight';
      font-weight: 500;
      padding: 0.5rem 0rem;
      padding-top: 2rem;
      text-decoration: none;
      color: $app-black;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-image {
    width: 100%;
    height: 100%;
    max-height: 350px;
    min-height: 350px;
    overflow: hidden;
    border-radius: 0.8rem;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    & img {
      display: block;
      width: 100%;
      height: 350px;
      object-fit: cover;
      transition: transform 0.5s ease;
    }
  }
}