.delete-assigned-product-modal-root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.delete-assigned-product-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 45%;
  max-height: 90dvh;
  padding-bottom: 1rem;
  @media (max-width: 1024px) {
    width: 55%;
  }
  @media (max-width: 768px) {
    width: 85%;
  }
}
.delete-assigned-product-modal-header {
  padding: 1.5rem 2rem 1rem;
  display: flex;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &-title {
    font-family: 'Inter Tight';
    font-size: 2rem !important;
    margin-bottom: 1.5rem;
    font-weight: 800;
    line-height: 3rem;
  }

  &-back-btn {
    cursor: pointer;
    font-weight: 600;
    margin-left: -4rem;
  }

  &-exit-btn {
    &-container {
      width: 6rem;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    cursor: pointer;
    font-size: 1.5rem;
  }
}

.delete-assigned-product-modal {
  &-content {
    padding: 0 2rem;
  }
  &-text {
    margin: 2rem 0;
  }
  &-input {
    &-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &-field {
      border-radius: 0.5rem;
      border: 1px solid #a3a3a3;
      padding: 1rem 0.75rem;
      font-size: 1rem;
    }
  }
  &-btn-group {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 2rem;
    & .custom-button-container {
      margin-right: 1rem;
      &:first-child {
        background-color: black;
        color: white;
        border: unset;
      }
      &:last-child {
        color: black;
      }
    }
  }
}
.delete-assigned-product-modal-error {
  &-container {
    margin: 0 0 1rem;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}