.inquiries {
  &-filter {
    &-main {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-left: 1rem;
      gap: 1rem;
      flex-wrap: wrap;
    }
    &-sub {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-left: 1rem;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }
}