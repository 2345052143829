@import '../../assets/styles/index.scss';

.info-box-input {
  &-container {
    display: flex;
    flex-direction: row;
    width: calc(100% - 4rem);
    border-radius: 1rem;
    background-color: #edefee;
    padding: 2rem;
  }
  &-left {
    &-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(50% - 2rem);
      padding-right: 1rem;
      padding-left: 1rem;
      padding-bottom: 2rem;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      &.purple {
        background-color: $app-purple;
      }
      &.purple-strong {
        background-color: $app-purple-strong;
      }
      &.turquoise {
        background-color: $app-turquoise;
      }
      & > .en {
        display: none;
      }
    }
    &-select {
      position: absolute;
      top: -22px;
      left: 0px;
    }
  }
  &-right {
    &-container {
      width: 50%;
    }
  }
  &-image {
    &-input {
      display: none;
    }
    &-placeholder {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      background-color: #f0f0f0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #aaa;
      border: 2px solid #ccc;
      cursor: pointer;
      text-align: center;
    }
    &-preview {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      object-fit: cover;
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 0rem;
      cursor: pointer;
    }
  }
  // &-btn-container {
  //   background-color: $app-yellow;
  //   border-radius: 3rem;
  //   padding: 2rem;
  //   width: 200px;
  // }
}
