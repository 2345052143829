@import '../../assets/styles/index.scss';

.login-page {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 5rem);
    margin-bottom: 2rem;
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      width: calc(100% - 4rem);
      padding: 2rem;
      height: 100%;
      max-width: calc(1600px - 4rem);
      gap: 2rem;
      @media (max-width: 880px) {
        flex-direction: column-reverse;
      }
      @media (max-width: 768px) {
        width: calc(100% - 3rem);
        padding: 2rem 1.5rem;
        max-width: calc(1600px - 3rem);
      }
    }
  }
  &-left {
    &-container {
      width: 50%;
      min-height: 100%;
      & > img {
        width: 100%;
        height: 100%;
        min-height: 1000px;
        display: block;
        object-fit: cover;
        border-radius: 1rem;
        @media (max-width: 880px) {
          min-height: unset;
          max-height: 800px;
        }
      }
      @media (max-width: 880px) {
        width: 100%;
      }
    }
  }
  &-right {
    &-container {
      width: 50%;
      @media (max-width: 880px) {
        width: 100%;
      }
    }
    &-login {
      &-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 4rem);
        background-color: $app-white;
        border-radius: 1rem;
        padding: 2rem;
      }
      &-title {
        font-family: 'Inter Tight';
        font-weight: 800;
        font-size: 3.625rem;
        line-height: 3.9875rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
        @media (max-width: 500px) {
          font-size: calc(2.4rem + 4vw);
          line-height: calc(2.75rem + 4vw);
        }
      }
      &-input {
        &-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          & label {
            font-family: 'Libre Franklin';
            font-size: 1.0625rem;
            line-height: 1.4875rem;
          }
          & input {
            outline: none;
            border: 1px solid rgba(#000000, 0.3);
            padding: 0.5rem 0.75rem;
            border-radius: 0.3rem;
            font-size: 1.0625rem;
            font-family: 'Libre Franklin';
            line-height: 1.4875rem;
            &:focus {
              border-color: $app-purple !important;
              outline: 1px solid $app-purple;
            }
          }
        }
      }
      &-btn {
        &-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          margin: 2rem 0rem 1rem;
        }
        &-link {
          color: $app-purple-strong;
          font-size: 1.0625rem;
          font-family: 'Libre Franklin';
          line-height: 1.4875rem;
          font-weight: 600;
        }
      }
    }
    &-signup {
      &-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 4rem);
        padding: 2rem;
        @media (max-width: 500px) {
          padding: 2rem 1rem;
        }
      }
      &-title {
        font-family: 'Inter Tight';
        font-weight: 800;
        font-size: 3.625rem;
        line-height: 3.9875rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
        @media (max-width: 500px) {
          font-size: calc(2.4rem + 4vw);
          line-height: calc(2.75rem + 4vw);
        }
      }
      &-text {
        font-family: 'Libre Franklin';
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      &-button {
        &-wrapper {
          margin-top: 2rem;
          margin-bottom: 1rem;
          // max-width: 220px;
        }
      }
    }
  }
}
.login-page-right-login-cookies-warning {
  font-style: italic;
  font-size: 0.9rem;
  &-link {
    margin-left: 0.2rem;
    color: $app-purple-strong;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.login-page-right-error {
  &-container {
    margin: 0 0 1rem;
  }
  &-entry {
    font-style: italic;
    color: red;
  }
}