@import '../../assets/styles/index.scss';

.company {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 5rem);
    padding-bottom: 2rem;
    padding-top: 4rem;
  }
  &-body {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1600px;
      width: 100%;
    }
  }
  &-card-title {
    font-family: 'Inter Tight';
    font-weight: 800;
    font-size: 3.625rem;
    line-height: 3.9875rem;
    padding: 2rem;
    padding-bottom: 1rem;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1.875rem;
      line-height: 110%;
      padding: 2rem 1.25rem;
    }
  }
  &-text {
    &-container {
      display: flex;
      padding: 0rem 2rem;
      flex-direction: column;
      width: 80%;
      @media (max-width: 768px) {
        padding: 2rem 1.25rem;
        width: calc(100% - 2.5rem);
      }
    }
    &-title {
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 2.75rem;
      line-height: 3.025rem;
      padding: 2rem;
      text-align: center;
      @media (max-width: 768px) {
        padding: 2rem 1.25rem;
        font-size: 2.25rem;
        line-height: 110%;
      }
    }
    &-intro {
      padding-top: 1rem;
      font-family: 'Libre Franklin';
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.75rem;
      @media (max-width: 768px) {
        font-size: 1.125rem;
        line-height: 140%;
      }
    }
    &-regards {
      font-family: 'Libre Franklin';
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.75rem;
      padding-bottom: 2rem;
      font-style: italic;
      @media (max-width: 768px) {
        font-size: 1.125rem;
        line-height: 140%;
      }
    }
  }
}
