.custom-dropdown {
  &-container {
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #999999;
    position: relative;
    &.disabled {
      pointer-events: none;
      background-color: #e1e1e1 !important;
      color: #a3a3a3 !important;
    }
    &.no-border {
      border: unset;
    }

  }
  &-selected {
    &-content {
      display: flex;
      align-items: center;
      font-size: 0.9375rem !important;
      font-family: 'DM Mono' !important;
      line-height: 2rem;
      &-icon {
        margin-left: 0.5rem;
      }
      &-wrapper {
        cursor: pointer;
        display: flex;
        flex-direction: row;
      }
    }
    &-container {
      padding: 1rem 2rem;
      border-radius: 2rem;
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding: 1rem 1rem;
      }
      
      &.project-dropdown {
        padding: 7px 22px;
        width: 15rem;
        position: relative;
        @media (max-width: 768px) {
          width: 10rem;
        }
        @media (max-width: 350px) {
          width: 9rem;
        }
        & .custom-dropdown-selected-content {
          font-size: 0.875rem !important;
          line-height: normal !important;
        }
        & .custom-dropdown-selected-content-wrapper {
          & .custom-dropdown-expand-icon {
            position: absolute;
            top: 6px;
            right: 8px;
          }
        }
      }
      &.space-dropdown {
        padding: 6px 18px;
      }
      &.product-edit-dropdown {
        padding: 6px 18px;
        width: 100%;
        & .custom-dropdown-selected-content-wrapper {
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }
  &-expand-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    cursor: pointer;
    & svg {
      pointer-events: none;
    }
  }
  &-menu {
    &-container {
      position: absolute;
      bottom: -0.25rem;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      z-index: 10;
      overflow: hidden;
      background-color: white;
      border: 1px solid #c6c6c6;
      box-shadow: 0px 0.375rem 1.25rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
    }
    &-entry {
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 0.9rem;
      &:not(:last-child) {
        border-bottom: 0.5px solid #999999;
      }
      &:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
      &-text {
        font-size: 0.9375rem !important;
        font-family: 'DM Mono' !important;
        line-height: 2rem;
        color: black !important;
        text-align: center;
      }
      &-icon {
        margin-left: 0.5rem;
      }
    }
    &-scrollable {
      overflow: auto;
      max-height: 15rem;
    }
  }
}
