@import '../../assets/styles/index.scss';
.product-summary-page-product-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.40);
  margin: 0 -2rem;
  padding-bottom: 2rem;
  width: 100%;
  &-header {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.40);
    font-size: 2rem;
    font-weight: 600;
    padding: 1rem 0rem;
    margin-bottom: 3rem;
    &-cell {
      // padding: 1rem 0;
      &:first-child {
        flex-grow: 1;
        width: 80%;
        line-height: 110%;
      }
      &:last-child {
        line-height: 110%;
        width: 20%;
        min-width: 10rem;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  &-product {
    &-entry {
      background-color: white;
      border-radius: 2rem;
      display: flex;
      padding: 1.5rem;
      margin: 1.5rem 0rem;
      overflow: hidden;
      &-left-content {
        display: flex;
        width: calc(85% - 1rem);
        padding-right: 1rem;
        &-img {
          height: 10rem;
          max-width: 14rem;
          border-radius: 0.5rem;
          object-fit: cover;
          @media (max-width: 880px) {
            height: 100%;
            max-width: 100%;
          }
        }
        &-product-info {
          padding-left: 2rem;
          &-title {
            font-weight: 800;
            font-size: 2rem;
            margin-bottom: 1rem;
            font-family: 'Inter Tight';
            line-height: 110%;
          //   &.desktop {
          //     @media (max-width: 1600px) {
          //       display: none;
          //     }
          //   }
          //   &.mobile {
          //     display: none;
          //     @media (max-width: 1600px) {
          //       display: block;
          //       text-align: center;
          //     }
          //   }
          }
          &-customization-list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 10px;
            &.grid-2 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              row-gap: 10px;
              @media (max-width: 1250px) {
                grid-template-columns: repeat(1, 1fr);
              }
            }
          }
          &-customization-entry {
            display: flex;
            align-items: flex-start;
            // @media (max-width: 425px) {
            //   white-space: wrap;
            // }
            &:not(:last-child) {
              margin-right: 2rem;
            }
            &-img {
              border-radius: 4px;
              border: 1px solid black;
              min-width: 2.5rem;
              min-height: 2.5rem;
              width: 2.5rem;
              height: 2.5rem;
              margin-right: 1rem;
            }
            &-description {
              display: flex;
              flex-direction: column;
              // height: 3rem;
              font-size: 0.8rem;
              line-height: normal;
              &-value {
                &.viherseina {
                  white-space: nowrap;
                }
              }
              &-key {
                width: 100%;
                color: $app-purple-strong;
                font-weight: 500;
                line-height: normal;
                font-family: 'DM Mono';
                white-space: nowrap;
                @media (max-width: 425px) {
                  white-space: wrap;
                }
              }
            }
          }
          @media (max-width: 1300px) {
            &-customization-list {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              row-gap: 10px;
            }
          }
          @media (max-width: 1000px) {
            &-customization-list {
              display: grid;
              grid-template-columns: 1fr;
              row-gap: 10px;
            }
          }
        }
        @media (max-width: 768px) {
          padding-right: 0;
        }
      }
      &-right-content {
        width: calc(15% - 1rem);
        min-width: 140px;
        font-size: 1.5rem;
        font-weight: 600;
        font-family: 'Inter Tight';
        padding-left: 1rem;
        white-space: nowrap;
        @media (max-width: 880px) {
          width: calc(100% - 1rem);
        }
        &-title {
          display: none;
        }
        &-space-quantity {
          font-size: 1rem;
          color: #a3a3a3;
          font-weight: 400;
          &-list {
            margin-top: 1rem;
            @media (max-width: 880px) {
              margin-top: 0rem;
            }
          }
        }
      }
      @media (max-width: 880px) {
        flex-direction: column;
        &-left-content {
          width: 100%;
          flex-direction: column;
          &-img {
            width: 100%;
            margin-bottom: 2rem;
          }
          &-product-info {
            display: flex;
            padding-left: 0;
            flex-direction: column;

            &-customization-list {
              grid-template-columns: 1fr 1fr;
              row-gap: 10px;
              @media (max-width: 450px) {
                grid-template-columns: 1fr;
                row-gap: 10px;
              }
            }
          }
        }
        &-right-content {
          margin-top: 2rem;
          display: flex;
          align-items: center;
          padding-left: 0;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 0.5rem;
          &-wrapper {
            display: flex;
            gap: 0.25rem;
          }
          &-title {
            display: block;
            margin-right: 0.5rem;
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }

    }
  }
  &-no-entry {
    padding: 2rem;
    font-size: 1.2rem;
    font-style: italic;
  }
  &-sales {
    &-contact {
      &-container {
        display: none;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        margin-top: 5rem;
      }
    }
    &-green-decor {
      display: flex;
      flex-direction: column;
    }
    &-wrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    &-outdoor {
      display: flex;
      flex-direction: column;
    }
    &-title {
      font-family: 'Inter Tight';
      font-weight: 800;
      font-size: 1.5rem;
      line-height: 110%;
      text-align: center;
      margin: 1rem 0rem;
    }
    &-text {
      font-family: 'Libre Franklin';
      font-size: 1.15rem;
    }
  }
}

@page {
  size: auto;
  margin: 20mm;
}

@media print {
  .product-summary-page-product-list {
    &-header{
      margin-bottom: unset;
    }
    &-product {
      &-entry {
        display: flex;
        // flex-direction: row;
        margin: unset;
        padding: 1rem;
        border-bottom: 1px solid black;
        &-left-content {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          &-img {
            height: 10rem;
            max-width: 15rem;
            margin-left: auto;
            margin-right: auto;
          }
          &-product-info {
            &-title {
              font-size: 1.5rem;
            }
            &-customization-list {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
            &-customization-entry {
              &-img {
                width: 2rem;
                height: 2rem;
              }
              &-description {
                font-size: 0.7rem;

              }
            }
          }
        }
        &-right-content {
          display: flex;
          margin-left: 5rem;
          padding: unset;
          // flex-direction: column;
          align-items: flex-end;
          &-total {
            white-space: nowrap;
          }
          &-space-quantity {
            margin-left: 2rem;
            font-size: 24px;
            &-list {
              white-space: nowrap;
              
            }
          }
        }
      }
    }
  }
  .page-break {
    page-break-after: always;
  }
}