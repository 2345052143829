@import '../../../assets/styles/index.scss';

.custom-image-input {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.55);
    border-radius: 1rem;
  }
  &-has-image {
    width: calc(100% - 4rem);
  }
  &-icon {
    &-circle {
      color: white;
    }
  }
  &-image {
    &-placeholder {
      width: 300px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed #ccc;
      border-radius: 8px;
      color: gray;
      cursor: pointer;
    }
    &-uploaded {
      display: block;
      object-fit: cover;
      border-radius: 1rem;
      width: 100%;
      height: calc(100% - 4rem);
      &.blog {
        height: calc(100% - 100px);
      }
    }
    &-remove-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.5rem;
      &.blog {
        top: 55px;
      }
    }
  }
  &-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  &-text-wrapper {
    padding: 0 1rem;
    width: calc(100% - 2rem);
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-title-wrapper {
    padding: 0 1rem;
    width: calc(100% - 2rem);
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-alt-text-wrapper {
    padding: 0 2rem;
    min-height: 40px;
    &.blog {
      padding-top: unset;
    }
  }
}